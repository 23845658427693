function uploadImage(base64ImageData, imageName) {
  const bodyData = {
    filename: imageName,
    imagedata: base64ImageData,
  };
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/upload`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default uploadImage;
