import React, { useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { useAuth0 } from '../react-auth0-spa';
import fetchClientKey from '../utils/fetchClientKey';
import fetchProgrammeData from '../utils/fetchProgrammeData';
import fetchBinData from '../utils/fetchBinData';
import Loading from '../components/Loading';
import updateCardProgrammeStatus from 'utils/updateCardProgrammeStatus';

export default function CardProgrammes() {
  const { user } = useAuth0();
  const [cardProgrammes, setCardProgrammeData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [binData, setBinData] = useState({});
  const [programmeModal, setProgrammeModal] = useState(false);
  const [programmeState, setProgrammeState] = useState(null);

  if (!dataLoaded) {
    fetchClientKey(user.email).then((res) => {
      fetchProgrammeData(res.client_access_key).then((results) => {
        fetchBinData(res.client_access_key).then((binResults) => {
          setBinData([binResults]);
        });
        setCardProgrammeData([results]);
        setProgrammeState(results.programme_state);
      });
    });
    setDataLoaded(true);
  }

  const updateProgrammeStatus = async (programme_id, status_id) => {
    await updateCardProgrammeStatus(programme_id, status_id);
    setProgrammeModal(false);
    setDataLoaded(false);
  };

  function renderCardProgrammeData() {
    if (cardProgrammes.length > 0) {
      return cardProgrammes.map((programme, index) => {
        return (
          <tr key={index}>
            <td>{programme.id}</td>
            <td>{programme.client_name}</td>
            <td>{programme.programme_identifier}</td>
            <td
              className={
                programme.programme_state === 'Active'
                  ? 'text-success'
                  : 'text-danger'
              }
            >
              {programme.programme_state}
            </td>
            <td>{programme.active_design_categories}</td>
            <td style={{ textAlign: 'center' }}>
              <button
                type='submit'
                className='btn btn-block'
                style={{ backgroundColor: '#ffffff' }}
                onClick={() => setProgrammeModal(true)}
              >
                <i
                  className={
                    programme.programme_state === 'Active'
                      ? 'fa fa-pause text-danger'
                      : 'fa fa-play text-success'
                  }
                />
              </button>
            </td>
          </tr>
        );
      });
    } else {
      return <Loading />;
    }
  }

  function renderBinData() {
    if (binData.length > 0) {
      return binData.map((bin, index) => {
        return (
          <tr key={index}>
            <td>{bin.bin_id}</td>
            <td>{bin.bin_number}</td>
            <td>{bin.programme_identifier}</td>
            <td className='text-success'>{bin.status_description}</td>
            <td>{bin.active_cards}</td>
            {/* <td style={{ textAlign: 'center' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#ffffff' }} onClick={() => console.log('do something')}><i className={bin.status_description === 'Active' ? "fa fa-pause text-danger" : "fa fa-play text-success"} /></button></td> */}
          </tr>
        );
      });
    } else {
      return <Loading />;
    }
  }

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Your Card Programmes</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className='text-primary'>
                    <tr>
                      <th>ID</th>
                      <th>Client Name</th>
                      <th>Programme ID</th>
                      <th>Programme State</th>
                      <th>Design Categories</th>
                      <th style={{ textAlign: 'center' }}>
                        Activate/De-Activate
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderCardProgrammeData()}</tbody>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag='h4'>Your Bin Data</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className='text-primary'>
                    <tr>
                      <th>Bin ID</th>
                      <th>Bin Number</th>
                      <th>Programme ID</th>
                      <th>Bin State</th>
                      <th>Active Cards</th>
                      {/* <th style={{ textAlign: 'center' }}>Activate/De-Activate</th> */}
                    </tr>
                  </thead>
                  <tbody>{renderBinData()}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={programmeModal} centered={true}>
          <ModalHeader>
            {programmeState === 'Active'
              ? 'Disable Card Programme'
              : 'Enable Card Programme'}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>
                  This action will{' '}
                  {programmeState === 'Active' ? 'disable' : 'enable'} the card
                  programme. This will{' '}
                  {programmeState === 'Active' ? 'remove' : 'grant'} access in
                  production to all content that is currently{' '}
                  {programmeState === 'Active' ? 'live' : 'not published'}. Are
                  you sure?
                </Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color={programmeState === 'Active' ? 'danger' : 'success'}
              onClick={() =>
                updateProgrammeStatus(
                  cardProgrammes[0].id,
                  programmeState === 'Active' ? '2' : '1'
                )
              }
            >
              {programmeState === 'Active' ? 'Disable' : 'Enable'} Programme
            </Button>
            <Button color='secondary' onClick={() => setProgrammeModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
