import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Progress } from 'reactstrap';

const ProgressBarModal = ({ uploadModal, uploadProgress, fileLength }) => {
  const { t } = useTranslation('card_designs');
  return (
    <Modal isOpen={uploadModal} centered={true}>
      <ModalHeader style={{ justifyContent: 'center' }}>
        {fileLength > 1
          ? t('cdsgn_uploading_images_label')
          : t('cdsgn_uploading_image_label')}
      </ModalHeader>
      <ModalBody>
        <Progress animated color='success' value={uploadProgress} />
      </ModalBody>
    </Modal>
  );
};

export default ProgressBarModal;
