import React, { useState } from "react";
// reactstrap components
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";

export default function Logout() {
    const [redirect, setRedirect] = useState(false);
    const { logout } = useAuth0();

    if (redirect) {
        return (<Redirect to={'/admin/card-designs'} />)
    }
    else {
        return (
            <>
                <div className="content">
                    <Modal isOpen={true} centered={true}>
                        <ModalHeader style={{ justifyContent: 'center' }}>Are you sure you wish to Logout?</ModalHeader>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <Button color="danger" onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>{' '}
                            <Button color="success" onClick={() => setRedirect(true)}>Cancel</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}