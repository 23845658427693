function updateCategoryOrder(category_id, display_order, programme_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/category-changes`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      category_id: `${category_id}`,
      display_order: `${display_order}`,
      programme_id: `${programme_id}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateCategoryOrder;
