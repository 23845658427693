import React from "react";
import { Spinner } from 'reactstrap';

const Loading = (props) => {
  if (props.type === 'AIDA') {
    return (<div>
      <Spinner type="grow" size="sm" color="danger" />
      <Spinner type="grow" size="sm" color="success" />
      <Spinner type="grow" size="sm" color="primary" />
    </div>)
  }
  else {
    return (<div>
      <Spinner color="info">{" "}</Spinner>
    </div>)
  }
};

export default Loading;
