function addProgrammeDesign(design_category_id, programme_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/programme-changes`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_category_id: design_category_id,
      programme_id: programme_id,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addProgrammeDesign;
