import { useState } from 'react';
import Img from 'react-cool-img';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
  Form,
  InputGroup,
  InputGroupText,
  Input,
  FormGroup,
} from 'reactstrap';

const SetDesignPriceModal = ({ priceModal, setPriceModal, selectedDesign }) => {
  const { t } = useTranslation(['new_dnd', 'buttons']);
  const [itemPrice, setItemPrice] = useState(null);

  return (
    <Modal isOpen={priceModal} centered={true}>
      <ModalHeader>{t('newdnd_set_design_price_header_text')}</ModalHeader>
      <ModalBody>
        <Form style={{ textAlign: 'left' }}>
          {selectedDesign ? (
            <>
              <div style={{ textAlign: 'center' }}>
                <Img
                  src={selectedDesign.url}
                  cache={true}
                  alt='Card Image'
                  style={{ height: 150, width: 250 }}
                />
              </div>
              <br></br>
              <br></br>
              <Label for='CategoryCode'>{t('newdnd_card_design_price')}</Label>
              <InputGroup>
                <InputGroupText>£</InputGroupText>
                <Input
                  placeholder=''
                  name='itemPrice'
                  id='itemPrice'
                  onChange={(e) => setItemPrice(e.target.value)}
                  value={'1.99'}
                />
              </InputGroup>
              <FormGroup tag='fieldset'>
                <Label>
                  {<strong>{t('newdnd_set_price_for_label')}</strong>}
                </Label>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' name='radio1' defaultChecked />{' '}
                    {t('newdnd_this_item_only_input_label')}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' name='radio1' />{' '}
                    {t('newdnd_all_items_in_this_category_input_label')}
                  </Label>
                </FormGroup>
              </FormGroup>
            </>
          ) : null}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='success' onClick={() => setPriceModal(false)}>
          {t('buttons:btn_set_price')}
        </Button>
        <Button color='secondary' onClick={() => setPriceModal(false)}>
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetDesignPriceModal;
