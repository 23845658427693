function addContentQueue(
  content_title,
  module_type,
  module_hex,
  module_image,
  headline_item,
  headline_destination,
  session_key
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/content-queue`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      content_title: content_title,
      module_type: module_type,
      module_hex: module_hex,
      module_image: module_image,
      headline_item: headline_item,
      headline_destination: headline_destination,
      session_key: session_key,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addContentQueue;
