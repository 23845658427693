function publishBrandConfiguration(
  programme_id,
  brand_name,
  brand_logo,
  logo_position,
  network_logo,
  network
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/brand-configuration`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      programme_id: `${programme_id}`,
      brand_name: `${brand_name}`,
      brand_logo: `${brand_logo}`,
      logo_position: `${logo_position}`,
      network_logo: `${network_logo}`,
      network: `${network}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default publishBrandConfiguration;
