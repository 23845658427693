import React, { useState } from 'react';
import { FormGroup, Label, Input, Button, FormText, Table } from 'reactstrap';
import FileBase64 from 'react-file-base64';
import uploadImage from "utils/uploadImage";


const ContentHubForm = (props) => {
    const [itemTitle, setItemTitle] = useState(null);
    const [itemImage, setItemImage] = useState(null);
    const [itemDestination, setItemDestination] = useState(null);
    const [selectedNavItem, setSelectedNavItem] = useState(null);
    const [previewItems, setPreviewItems] = useState(null);
    const [imageType, setImageType] = useState('upload');
    const [previewImage, setPreviewImage] = useState();
    const [navItems, setNavItems] = useState(null);

    const getFiles = async (type, files) => {
        if (type === 'Headline') {
            let imageURL = await uploadNewImage(files.base64);
            props.headlineItem(imageURL);
        }
        else {
            setPreviewImage(files.base64);
        }
    }

    const addNavItem = async () => {
        let newArray;
        let newItem;
        if (navItems) {
            newArray = navItems;
        }
        else {
            newArray = [];
        }
        const [contentItem] = props.availableContent.filter(item => item.title === selectedNavItem);
        newItem = {
            title: contentItem.title,
            item_order: contentItem.content_data.item_order,
            image: contentItem.module_image,
            destination: contentItem.content_data.content_destination,
        };
        newArray.push(newItem);
        setNavItems(...[newArray]);
        props.navItems(...[newArray]);
    }

    const addNewItem = async () => {
        let newArray;
        let newItem;
        if (previewItems) {
            newArray = previewItems.slice();
        }
        else {
            newArray = [];
        }
        if (imageType === 'upload') {
            let newUUID = uuid();
            let newFileData = previewImage.split(',');
            let newImage = await uploadImage(newFileData[1], newUUID + '.jpg');
            newItem = {
                title: itemTitle,
                image: newImage.Location,
                itemDestination: itemDestination
            };
        }
        else {
            newItem = {
                title: itemTitle,
                image: itemImage,
                itemDestination: itemDestination
            };
        }
        newArray.push(newItem);
        setPreviewItems(newArray);
        props.previewItems(newArray);
        setItemTitle('');
        setItemImage('');
        setItemDestination('');
    }

    const uploadNewImage = async (base64) => {
        let newUUID = uuid();
        let newFileData = base64.split(',');
        let newImage = await uploadImage(newFileData[1], newUUID + '.jpg');
        return newImage.Location;
    }

    const uuid = () => {
        const url = URL.createObjectURL(new Blob())
        const [id] = url.toString().split('/').reverse()
        URL.revokeObjectURL(url)
        return id
    }

    const renderNavItems = () => {
        if (navItems) {
            return navItems.map((item, index) => {
                return (
                    <tr key={item.item_order}>
                        <td>{item.item_order}</td>
                        <td>{item.title}</td>
                        <td><button className="btn btn-block" style={{ backgroundColor: '#ffffff' }} onPress={() => removeNavItem(item)}><i className="fa fa-trash text-danger" /></button></td>
                    </tr>);
            });
        }
    }

    const removeNavItem = (item) => {
        // let newArray = props.availableContent;
        props.availableContent.filter(i => i.title !== item.title);
        setNavItems(...[props.availableContent]);
        props.navItems(...[props.availableContent]);
    }

    if (props.availableContent.length > 0) {
        if (!selectedNavItem) {
            props.availableContent.sort((a, b) => a.title.localeCompare(b.title))
            setSelectedNavItem(props.availableContent[0].title);
        }
    }

    return (
        <>
            {props.brandType === 'masterbrand' ?
                <>
                    <FormGroup>
                        <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Top Level Navigation</Label>
                        <br></br>
                        <Input id="exampleSelect" name="select" type="select" style={{ width: '100%', borderRadius: 4, border: 'solid', borderWidth: 1, borderColor: '#dddddd' }} onChange={(e) => setSelectedNavItem(e.target.value)}>
                            {props.availableContent.error ? null :
                                props.availableContent
                                    .sort((a, b) => a.title.localeCompare(b.title))
                                    .map((item, index) => {
                                        return (<option value={item.title}>{item.title}</option>)
                                    })}
                        </Input>
                        <div class="d-flex justify-content-center" style={{ marginTop: 10 }}>
                            <Button color="success" onClick={() => addNavItem()}>Add to Navigation</Button>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Table>
                            <thead className="text-primary">
                                <tr>
                                    <th>ID</th>
                                    <th>Linked Item</th>
                                    <th style={{ textAlign: 'center' }}>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderNavItems()}
                            </tbody>
                        </Table>
                    </FormGroup>
                    <FormGroup>
                        <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Headline Item</Label>
                    </FormGroup>
                    <FormGroup>
                        <FileBase64
                            multiple={false}
                            onDone={getFiles.bind(this, 'Headline')} />
                        <FormText>
                            Select an Image to upload to Yimba's Content Repository. This image will be used as the thumbnail for the content item
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Headline Item Destination</Label>
                        <Input name="title" id="title" onChange={(e) => props.headlineDestination(e.target.value)} />
                    </FormGroup>
                </>
                : null}
            <FormGroup>
                <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Item Title</Label>
                <Input name="title" id="title" onChange={(e) => setItemTitle(e.target.value)} style={{ marginBottom: 20 }} value={itemTitle} />
                <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Item Image</Label>
                <FormGroup tag="fieldset" style={{ marginTop: 10 }}>
                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Image Type:-</Label>
                    <FormGroup check>
                        <Label check>
                            {imageType === 'upload' ? <Input type="radio" name="radio1" defaultChecked onClick={() => setImageType('upload')} />
                                :
                                <Input type="radio" name="radio1" onClick={() => setImageType('upload')} />}
                            Upload an Image
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            {imageType === 'url' ? <Input type="radio" name="radio1" defaultChecked onClick={() => setImageType('url')} />
                                :
                                <Input type="radio" name="radio1" onClick={() => setImageType('url')} />}
                            Image URL
                        </Label>
                    </FormGroup>
                    {imageType === 'upload' ? <FormGroup>
                        <FileBase64
                            multiple={false}
                            onDone={getFiles.bind(this, 'Standard')} />
                        <FormText>
                            Select an Image to upload to Yimba's Content Repository. This image will be used as the thumbnail for the content item
                        </FormText>
                        {/* <Button color="info" onClick={(e) => uploadNewImage()}>Upload Image</Button> */}
                    </FormGroup> :
                        <FormGroup>
                            <Input name="image" id="image" onChange={(e) => setItemImage(e.target.value)} value={itemImage} />
                            <FormText>
                                Paste the URL of the image that you want to use as the thumbnail for the content item
                            </FormText>
                        </FormGroup>}
                </FormGroup>
                <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Item Destination</Label>
                <Input name="title" id="title" onChange={(e) => setItemDestination(e.target.value)} value={itemDestination} />
            </FormGroup>
            <div class="d-flex justify-content-center" style={{ marginTop: 10 }}>
                <Button color="success" onClick={() => addNewItem()}>Add New Item</Button>
            </div>
        </>
    )
}

export default ContentHubForm;