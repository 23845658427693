import Dashboard from "views/Dashboard.jsx"
import CardProgrammes from "views/CardProgrammes.jsx"
import Logout from "views/Logout"
import PromotedContent from "views/PromotedContent"
import CustomMessages from "views/CustomMessages"
import PushNotifications from "views/PushNotifcations"
import BrandApplication from "views/BrandApplication"
import ImageAnalysis from "views/ImageAnalysis"
import ContentHub from "views/ContentHub"
import CardDesigns from "views/CardDesigns/CardDesigns"
import ReviewChanges from "views/ReviewChanges/ReviewChanges"

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/card-programmes",
    name: "Card Programmes",
    icon: "nc-icon nc-credit-card",
    component: CardProgrammes,
    layout: "/admin"
  },
  {
    path: "/card-designs",
    name: "Card Designs",
    icon: "nc-icon nc-palette",
    component: CardDesigns,
    layout: "/admin"
  },
  {
    path: "/content-hub",
    name: "Content Hub",
    icon: "nc-icon nc-box-2",
    component: ContentHub,
    layout: "/admin"
  },
  {
    path: "/aida",
    name: "Image Analysis Rules",
    icon: "nc-icon nc-album-2",
    component: ImageAnalysis,
    layout: "/admin"
  },
  {
    path: "/brand-application",
    name: "Brand Configuration",
    icon: "nc-icon nc-ruler-pencil",
    component: BrandApplication,
    layout: "/admin"
  },
  {
    path: "/promoted-content",
    name: "Promoted Content",
    icon: "nc-icon nc-layout-11",
    component: PromotedContent,
    layout: "/admin"
  },
  {
    path: "/custom-messages",
    name: "Custom Messages",
    icon: "nc-icon nc-chat-33",
    component: CustomMessages,
    layout: "/admin"
  },
  {
    path: "/push-notifications",
    name: "Push Notifications",
    icon: "nc-icon nc-bell-55",
    component: PushNotifications,
    layout: "/admin"
  },
  {
    path: "/review-changes",
    name: "Review Changes",
    icon: "nc-icon nc-zoom-split",
    component: ReviewChanges,
    layout: "/admin"
  },
  // {
  //   path: "/user-management",
  //   name: "User Management",
  //   icon: "nc-icon nc-single-02",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    pro: true,
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-button-power",
    component: Logout,
    layout: "/admin"
  },
  // {
  //   pro: true,
  //   path: "/support",
  //   name: "I Need Help!",
  //   icon: "nc-icon nc-support-17",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
];
export default routes;
