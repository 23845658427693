import Dashboard from 'views/Dashboard.jsx';
import CardProgrammes from 'views/CardProgrammes.jsx';
import Logout from 'views/Logout';
import ReviewChanges from 'views/ReviewChanges/ReviewChanges';

var ReviewRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: 'nc-icon nc-bank',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/card-programmes',
    name: 'Card Programmes',
    icon: 'nc-icon nc-credit-card',
    component: CardProgrammes,
    layout: '/admin',
  },
  {
    path: '/review-changes',
    name: 'Review Changes',
    icon: 'nc-icon nc-zoom-split',
    component: ReviewChanges,
    layout: '/admin',
  },
  // {
  //   path: "/user-management",
  //   name: "User Management",
  //   icon: "nc-icon nc-single-02",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/admin',
  },
  // {
  //   pro: true,
  //   path: "/support",
  //   name: "I Need Help!",
  //   icon: "nc-icon nc-support-17",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
];
export default ReviewRoutes;
