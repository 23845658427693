import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from 'reactstrap';

const SwitchCategoryModal = ({
  switchModal,
  setSwitchModal,
  newMainData,
  promotedCategory,
  setPromotedCategory,
  promotedSelected,
  switchDesignCategory,
  tempCategories,
}) => {
  const { t } = useTranslation(['new_dnd', 'buttons']);

  const getCategory = () => {
    let selectedCategory = promotedSelected?.catagory;
    if (!selectedCategory) {
      const categoryData = tempCategories?.filter(
        (it) => it?.id === promotedSelected?.category_id
      )[0];
      if (categoryData) {
        selectedCategory = categoryData?.code;
      }
    }
    return selectedCategory;
  };

  const selectedCategory = getCategory();

  return (
    <Modal isOpen={switchModal} centered={true}>
      <ModalHeader>{t('newdnd_switch_category_header_text')}</ModalHeader>
      <ModalBody>
        <div style={{ textAlign: 'center' }}>
          <Label for='CategoryCode'>{t('newdnd_which_carousel_label')}</Label>
          <br></br>
          <Label>
            {t('newdnd_current_category')} : <strong>{selectedCategory}</strong>
          </Label>
          <br></br>
          <select
            name={'CategorySelect'}
            onChange={(choice) => setPromotedCategory(choice.target.value)}
            style={{ width: 150, height: 30 }}
          >
            <option value={''}>Select...</option>
            {newMainData
              ?.filter((item) => item.code !== selectedCategory)
              .map((item, index) => (
                <option key={index} value={item.code}>
                  {item.title}
                </option>
              ))}
          </select>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color='success'
          onClick={() => switchDesignCategory()}
          disabled={promotedCategory === ''}
        >
          {t('buttons:btn_switch_category')}
        </Button>
        <Button color='secondary' onClick={() => setSwitchModal(false)}>
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SwitchCategoryModal;
