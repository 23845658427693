function deleteProgrammeChange(item_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/programme-changes`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      item_id: `${item_id}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default deleteProgrammeChange;
