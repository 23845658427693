import React, { useState, useReducer } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, Input, FormGroup, Button } from "reactstrap";
import fetchClientKey from 'utils/fetchClientKey';
import fetchProgrammeData from 'utils/fetchProgrammeData';
import Loading from '../components/Loading';
import { useAuth0 } from "../react-auth0-spa";
import getImageAnalysisQueue from "utils/getImageAnalysisQueue";
import ContentModerator from "components/ContentModerator";
import addImageAnalysisRule from "utils/addImageAnalysisRule";
import addImageAnalysisTag from "utils/addImageAnalysisTag";
import fetchCardDesigns from "utils/fetchCardDesigns";
import deleteImageAnalysisRule from "utils/deleteImageAnalysisRule";

export default function ImageAnalysis() {
    const [showModal, setShowModal] = useState(false);
    const [categoryData, setCategoryData] = useState(null);
    const { user } = useAuth0();
    const [pageState, setPageState] = useReducer((pageState, newPageState) =>
        ({ ...pageState, ...newPageState }),
        {
            cardProgramme: null, userSessionKey: null, loading: true,
            deleteImageRule: false, savingContent: false, selectedItem: null,
            imageTags: null, selectedTags: 'All', selectedRuleName: 'All', freeTextTags: null
        }
    );

    function saveImageTags() {

        const addNewTagRule = async (image_rule_id, tag) => {
            await addImageAnalysisTag(image_rule_id, tag, pageState.userSessionKey)
        }

        const saveTagData = async () => {
            await addImageAnalysisRule(pageState.selectedRuleName, pageState.userSessionKey).then((rule) => {
                if (pageState.selectedTags === 'All') {
                    addNewTagRule(rule.image_rule_id, pageState.selectedTags)
                }
                else {
                    pageState.selectedTags.forEach(tag => {
                        addNewTagRule(rule.image_rule_id, tag)
                    });
                }
                if (pageState.freeTextTags) {
                    let freeTags = pageState.freeTextTags.split(",");
                    freeTags.forEach(tag => {
                        addNewTagRule(rule.image_rule_id, tag)
                    });
                }
            })
            window.location.reload();
        }

        saveTagData();
    }

    function loadData() {
        fetchClientKey(user.email).then((keyinfo) => {
            fetchProgrammeData(keyinfo.client_access_key).then((results) => {
                fetchCardDesigns(keyinfo.client_access_key).then((designs) => {
                    getGroupedResults(designs.categories);
                    getImageAnalysisQueue(results.programme_identifier).then((imageTags) => {
                        setPageState({ cardProgramme: results.id, userSessionKey: results.programme_identifier, loading: false, imageTags: imageTags })
                    });
                });
            });
        });
    }

    function getGroupedResults(cardDesigns) {
        let parentCategories = [];
        let parentGroups = [];
        let parentCodes = [];
        cardDesigns.forEach(design => {
            if (design.parent_catagory) {
                parentCategories.push(design)
            }
            else {
                if (design.design_count > 0) {
                    parentCategories.push(design)
                }
                else {
                    let firstDesign = cardDesigns.find((result) => {
                        return result.parent_catagory === design.code;
                    })
                    let subDesigns = cardDesigns.filter(({ parent_catagory }) => parent_catagory === design.code);
                    design.AllSubCategories = subDesigns;
                    if (firstDesign) {
                        design.designs = firstDesign.designs;
                    }
                    parentGroups.push(design);
                    parentCodes.push(design.title);
                }
            }
        });
        setCategoryData(parentCategories);
    }

    function deleteItem(itemID) {
        deleteImageAnalysisRule(itemID).then((res) => {
            window.location.reload();
        })
    }

    function renderImageAnalysisRules() {
        if (pageState.imageTags.length > 0) {
            return pageState.imageTags.map((rules, index) => {
                let ruleDate = new Date(rules.date_created).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                })
                let tags;
                rules.tags.forEach(tag => {
                    if (tags) {
                        tags = tags + ', ' + tag.tag;
                    }
                    else {
                        tags = tag.tag;
                    }
                });
                return (
                    <tr key={rules.id}>
                        <td>{rules.id}</td>
                        <td>{rules.rule_name}</td>
                        <td>{tags}</td>
                        <td>{ruleDate}</td>
                        <td className="text-success">{rules.rule_status}</td>
                        <td style={{ textAlign: 'center' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#ffffff' }} onClick={() => setPageState({ selectedItem: rules.id, deleteImageRule: true })}><i className="fa fa-trash text-danger" /></button></td>
                    </tr>
                )
            })
        }
        else {
            return (null);
        }
    }

    if (pageState.loading) {
        loadData();
        return (
            <div className="content">
                <Loading />
            </div>)
    }
    else {
        return (
            <>
                <div className="content">
                    <Modal isOpen={showModal} centered={true} >
                        <ModalHeader>Image Filtering Rules</ModalHeader>
                        <ModalBody>
                            <Form style={{ textAlign: 'left' }}>
                                <FormGroup>
                                    <p className="card-category">
                                        Select an Image Category from your card programme to apply filtered rules to
                                    </p>
                                    <Label for="CategoryCode">Image Design Category</Label>
                                    <Input type="select" name="selectMulti" id="exampleSelectMulti2" multiple style={{ height: 125 }} onClick={(e) => { setPageState({ selectedRuleName: e.target.value }); console.log(e.target.value) }}>
                                        <option selected value={'All'}>All</option>
                                        {categoryData.map((category, index) => {
                                            return (<option key={index} value={category.title}>{category.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>
                                <hr></hr>
                                <FormGroup>
                                    <p className="card-category">
                                        Select one or more content filtering tags for this rule
                                    </p>
                                    <Label for="CategoryCode">Image Filtering Tags</Label>
                                    <ContentModerator selectedTags={tags => setPageState({ selectedTags: tags })} />
                                    {/* <Button color="primary" onClick={() => setShowTags(true)}>Add</Button> */}
                                    <hr></hr>
                                    <FormGroup>
                                        <Label for="CategoryCode">Additional Free Text Tags</Label>
                                        <p className="card-category">
                                            You can add your own free text tags to the filter rule by typing them in the box below. Use a ',' in between each tag to seperate values.
                                        </p>
                                        <Input name="text" id="text" placeholder={'e.g Profanity, Nudity, Explcit Content'} onChange={(e) => { setPageState({ freeTextTags: e.target.value }); console.log(e.target.value) }} />
                                        {/* <Button color="primary">Add</Button> */}
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {pageState.savingContent ? <Button color="secondary">Saving...</Button> : <Button color="success" onClick={() => saveImageTags()}>Save</Button>}
                            <Button color="secondary" onClick={() => { window.location.reload(); }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={pageState.deleteImageRule} centered={true} >
                        <ModalHeader>Remove Image Filtering Rule</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label>Are you sure you wish to remove this Image Filtering Rule?</Label>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => deleteItem(pageState.selectedItem)}>Yes</Button>
                            <Button color="secondary" onClick={() => setPageState({ deleteImageRule: false })}>No</Button>
                        </ModalFooter>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    {/* <CardTitle tag="h4">Image Analysis Rules</CardTitle> */}
                                    <button type="submit" className="btn btn-primary" onClick={() => setShowModal(true)}>{'New Image Filtering Rule'}</button>
                                    <p className="card-category">
                                        Yimba's Automated Image Detection and Analysis (AIDA) utilises industry leading machine learning algorithms to analyse and detect the content of images uploaded to the Content Management System. This section allows you to specify rules for automatic image rejection based on your requirements.
                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Image Category</th>
                                                <th>Content Filter Tags</th>
                                                <th>Created</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderImageAnalysisRules()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}