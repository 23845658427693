function createZipFile(request) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/zip-files`, {
    method: 'POST',
    headers: {},
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON.body.body;
    });
}

export default createZipFile;
