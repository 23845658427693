function addSubDesignAction(
  design_identifier,
  url,
  session_key,
  design_category
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/subdesign-actions`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_identifier: design_identifier,
      url: url,
      session_key: session_key,
      design_category: design_category,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addSubDesignAction;
