import React, { useState } from "react";
import { Col, Row, Card, CardHeader, CardBody, Table, Modal, ModalBody, ModalFooter, Button, ModalHeader, Label, Form, FormGroup, Input, FormText } from "reactstrap";
import MobilePreview from "components/MobilePreview";
import getContentQueue from "utils/getContentQueue";
import fetchClientKey from "utils/fetchClientKey";
import { useAuth0 } from "../react-auth0-spa";
import Loading from "components/Loading";
import { SketchPicker } from 'react-color';
import ContentHubForm from "components/ContentHubForm";
import addContentQueue from "utils/addContentQueue";
import addContentQueueData from "utils/addContentQueueData";
import fetchProgrammeData from "utils/fetchProgrammeData";
import getContent from "utils/getContent";
import FileBase64 from 'react-file-base64';
import uploadImage from "utils/uploadImage";

export default function ContentHub() {
    const [showPreview, setShowPreview] = useState(false);
    const [newItem, setShowNewItem] = useState(false);
    const [previewType, setPreviewType] = useState(null);
    const [contentQueue, setContentQueue] = useState(null);
    const [sessionKey, setSessionKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chosenColour, setChosenColour] = useState('#999999');
    const { user } = useAuth0();
    const [colourPickerShow, setColourPickerShow] = useState(false);
    const [pageTitle, setPageTitle] = useState(null);
    const [brandType, setBrandType] = useState('subbrand');
    const [previewItems, setPreviewItems] = useState(null);
    const [headlineItem, setHeadlineItem] = useState(null);
    const [headlineDestination, setHeadlineDestination] = useState(null);
    const [navItems, setNavItems] = useState(null);
    const [previewImage, setPreviewImage] = useState();

    const renderContentHubData = () => {
        if (!contentQueue.error) {
            return contentQueue?.map((content, index) => {
                return (
                    <tr key={content.id}>
                        <td>{content.id}</td>
                        <td>{content.title}</td>
                        <td>{content.module_type}</td>
                        <td className={content.status === 'In Review' ? 'text-warning' : 'text-success'}><strong>{content.status}</strong></td>
                        <td><Button color="link" onClick={() => { setShowPreview(true); setPreviewType(content.title) }}>SDK Preview</Button></td>
                    </tr>);
            });
        }
    }

    const getFiles = (files) => {
        setPreviewImage(files.base64);
    }

    const uuid = () => {
        const url = URL.createObjectURL(new Blob())
        const [id] = url.toString()?.split('/').reverse()
        URL.revokeObjectURL(url)
        return id
    }

    const loadData = async () => {
        let clientKey = await fetchClientKey(user.email);
        let programmeData = await fetchProgrammeData(clientKey.client_access_key);
        setSessionKey(programmeData.programme_identifier);
        let contentData = await getContentQueue(programmeData.programme_identifier);
        let liveContent = await getContent(programmeData.id);
        if (liveContent.error) {
            liveContent = [];
        }
        if (!contentData.error) {
            contentData?.forEach(record => {
                liveContent.push(record);
            });
        }
        setContentQueue(liveContent);
        setLoading(false);
    }

    const saveItem = async () => {
        let newUUID = uuid();
        let newFileData = previewImage?.split(',');
        let newImage = await uploadImage(newFileData[1], newUUID + '.jpg');
        let contentResult = await addContentQueue(pageTitle, brandType === 'subbrand' ? 'Sub-Brand' : 'Master Brand', chosenColour, newImage.Location, headlineItem, headlineDestination, sessionKey);
        for (let i = 0; i < previewItems?.length; i++) {
            await addContentQueueData(contentResult.inserted_ID, previewItems[i].image, previewItems[i].title, previewItems[i].itemDestination, i, sessionKey);
        }
        setLoading(true);
        setShowNewItem(false);
        loadData();
    }

    if (loading) {
        loadData();
        return (
            <>
                <div className="content">
                    <Loading />
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    {/* <CardTitle tag="h4">Push Notifications</CardTitle> */}
                                    <button type="submit" className="btn btn-primary" onClick={() => setShowNewItem(true)}>{'New Content Module'}</button>
                                </CardHeader>
                                <CardBody>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Content Module Name</th>
                                                <th>Module Type</th>
                                                <th>Status</th>
                                                <th>Preview Content</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderContentHubData()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={showPreview} centered={true} >
                        <ModalHeader style={{ justifyContent: 'flex-end', paddingTop: 0, paddingBottom: 0 }}>
                            <Button color="danger" size="sm" onClick={() => setShowPreview(false)}>X</Button>
                        </ModalHeader>
                        <ModalBody>
                            <MobilePreview type={previewType} />
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={newItem} centered={true} size="lg" style={{ maxWidth: 5000, width: '50%' }}>
                        <ModalHeader>New Content Item</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col xs="6">
                                    <Form style={{ textAlign: 'left' }}>
                                        <FormGroup>
                                            <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Content Module Name</Label>
                                            <Input name="title" id="title" onChange={(e) => setPageTitle(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="ContentTitle" style={{ fontWeight: 'bold' }}>Content Module Image</Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <FileBase64
                                                multiple={false}
                                                onDone={getFiles.bind(this)} />
                                            <FormText>
                                                Select an Image to upload to Yimba's Content Repository. This image will be used as the thumbnail for the content item
                                            </FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Module Type</Label>
                                            <br></br>
                                            <Input id="exampleSelect" name="select" type="select" style={{ width: '100%', borderRadius: 4, border: 'solid', borderWidth: 1, borderColor: '#dddddd' }} onChange={(e) => setBrandType(e.target.value)}>
                                                <option value={'subbrand'}>Sub-brand</option>
                                                <option value={'masterbrand'}>Master Brand</option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="brandColour" style={{ fontWeight: 'bold' }}>Content Primary Brand Colour</Label>
                                            <br></br>
                                            <Row style={{ alignItems: 'center' }}>
                                                <Col>
                                                    <Button style={{ backgroundColor: chosenColour, height: 35, marginRight: 10 }} />
                                                    <Label for="brandChosenColour" style={{ marginRight: 10 }}>{chosenColour}</Label>
                                                    <Button color="info" onClick={() => setColourPickerShow(true)}>Set Colour</Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <ContentHubForm brandType={brandType} previewItems={(items) => setPreviewItems(items)} availableContent={contentQueue} headlineItem={(item) => setHeadlineItem(item)} headlineDestination={(destination) => setHeadlineDestination(destination)} navItems={(items) => setNavItems(...[items])} />
                                    </Form>
                                </Col>
                                <Col xs="6" style={{ justifyContent: 'flex-end' }}>
                                    <MobilePreview brandType={brandType} title={pageTitle} backgroundColour={chosenColour} contentItems={previewItems} headlineItem={headlineItem} navItems={navItems} />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {loading ? <Loading /> : <Button color="success" onClick={() => saveItem()}>Save</Button>}
                            <Button color="secondary" onClick={() => window.location.reload()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={colourPickerShow} centered={true} size='sm'>
                        <ModalBody>
                            <SketchPicker color={chosenColour} onChangeComplete={(e) => setChosenColour(e.hex)} width={250} />
                            <div class="d-flex justify-content-center" style={{ marginTop: 10 }}>
                                <Button color="success" onClick={() => setColourPickerShow(false)}>Select Colour</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </>
        );
    }
}