import { Fragment, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import FileBase64 from 'react-file-base64';
import uploadImage from '../../utils/uploadImage';
import addDesign from 'utils/addDesign';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import Img from 'react-cool-img';
import updateDesign from 'utils/updateDesign';
import { useTranslation } from 'react-i18next';
import ProgressBarModal from './Modal/ProgressBarModal';
import UploadImagesModal from './Modal/UploadImagesModal';
import AddCategoryModal from './Modal/AddCategoryModal';
import addDesignCategory from 'utils/addDesignCategory';
import { successToast } from 'components/Toast/SuccessToast';

const UploadNewDesigns = ({
  sessionKey,
  cardDesigns,
  tempCategories,
  categoryData,
  loadData,
}) => {
  const { t } = useTranslation('card_designs', 'button');
  const [uploadFiles, setUploadFiles] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [selectedDropDown, setSelectedDropDown] = useState(null);
  const [addedCategory, setAddedCategory] = useState(null);

  const [addCategoryLoader, setAddCategoryLoader] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadImagesModal, setUploadImagesModal] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);

  const getFiles = (files) => {
    setUploadFiles(files);
  };

  const uuid = () => {
    const url = URL.createObjectURL(new Blob());
    const [id] = url?.toString().split('/').reverse();
    URL.revokeObjectURL(url);
    return id;
  };

  const checkFileSize = (file) => {
    if (file.type === 'image/gif') {
      if (file.file.size > 5120000) {
        return { backgroundColor: '#f7d577', width: 180 };
      } else {
        return { width: 180 };
      }
    } else {
      return { width: 180 };
    }
  };

  const checkFileSizeforFont = (file) => {
    if (file.type === 'image/gif') {
      if (file.file.size > 5120000) {
        return { fontWeight: 'bold', color: 'red' };
      }
    }
  };

  const checkFileSizeSkip = (file) => {
    if (file.type === 'image/gif') {
      if (file.file.size > 5120000) {
        return (
          <Row style={{ justifyContent: 'center' }}>
            <div style={{ marginLeft: 10, marginRight: 10, color: '#ff0000' }}>
              {t('cdsgn_upload_will_be_skipped_label')}
            </div>
          </Row>
        );
      }
    }
  };

  const checkFileType = (file, index) => {
    let acceptedTypes = [
      {
        imageType: 'image/jpeg',
      },
      {
        imageType: 'image/gif',
      },
      {
        imageType: 'image/png',
      },
    ];

    let acceptedType = acceptedTypes?.filter(
      (item) => item.imageType === file.type
    );
    if (acceptedType?.length > 0) {
      return (
        <Col sm='2' key={index}>
          <Card className='card-stats' style={checkFileSize(file)}>
            <CardBody>
              <Row style={{ justifyContent: 'center' }}>
                <Img
                  src={file.base64}
                  cache={true}
                  alt='Card Image'
                  style={{ height: 90, width: 150 }}
                />
              </Row>
              <Row style={{ textAlign: 'center' }}>
                <Col>{`${t('cdsgn_file_name_label')} ${file.name}`}</Col>
              </Row>
              <Row style={{ textAlign: 'center' }}>
                <Col style={checkFileSizeforFont(file)}>
                  {`${t('cdsgn_image_size_label')} ${file.size}`}
                </Col>
              </Row>
              {checkFileSizeSkip(file)}
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      );
    } else {
      return null;
    }
  };

  //Add Category Operation
  const addCategory = async (categoryName, categoryDescription) => {
    setAddCategoryModal(false);
    setAddCategoryLoader(true);
    let newCatCode = categoryName?.replace(' ', '');
    newCatCode = newCatCode.toLowerCase();
    await addDesignCategory(
      sessionKey,
      newCatCode,
      categoryName,
      categoryDescription,
      0,
      'NONE'
    );
    loadData();
    setAddedCategory(categoryName);
    setSelectedDropDown(categoryName);
    setAddCategoryLoader(false);
  };

  const addToCarousel = async (item) => {
    let newExisting = checkNewExisting(item);
    if (newExisting) {
      //If existing design category
      let filteredData = cardDesigns?.[0]?.filter(
        (item) => item?.title === selectedDropDown
      );
      await updateDesign(item.image_id, filteredData[0]?.design_category_id);
      setSelectedDropDown(null);
    } else {
      //If a new Temp Category has been created
      let filteredData = tempCategories?.filter(
        (item) => item?.title === selectedDropDown
      );
      filteredData = filteredData[0]?.id;
      await updateDesign(item.image_id, filteredData);
      setSelectedDropDown(null);
    }
  };

  const sleep = (milliseconds) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  const checkNewExisting = (item) => {
    let allDesigns = cardDesigns?.[0];
    let filteredData = allDesigns?.filter(
      (item) => item?.title === selectedDropDown
    );
    if (filteredData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const beginUpload = async () => {
    setUploadImagesModal(false);
    setUploadModal(true);
    setUploadProgress(10);
    for (let index = 0; index < uploadFiles?.length; index++) {
      const file = uploadFiles[index];
      let newUUID = uuid();
      if (file.type === 'image/gif') {
        let newFileData = file.base64.split(',');
        const res = await uploadImage(newFileData[1], newUUID + '.gif');
        const designRes = await addDesign(res.Location, sessionKey);
        designRes.title = selectedDropDown;
        addToCarousel(designRes);
      }
      if (file.type === 'image/jpeg') {
        Resizer.imageFileResizer(
          file.file,
          1024,
          1024,
          'JPEG',
          90,
          0,
          async (uri) => {
            let newFileData = uri.split(',');
            const res = await uploadImage(newFileData[1], newUUID + '.jpg');
            const designRes = await addDesign(res.Location, sessionKey);
            designRes.title = selectedDropDown;
            await addToCarousel(designRes);
          },
          'base64'
        );
      }
      if (file.type === 'image/png') {
        Resizer.imageFileResizer(
          file.file,
          1024,
          1024,
          'JPEG',
          90,
          0,
          async (uri) => {
            let newFileData = uri.split(',');
            const res = await uploadImage(newFileData[1], newUUID + '.jpg');
            const designRes = await addDesign(res.Location, sessionKey);
            designRes.title = selectedDropDown;
            await addToCarousel(designRes);
          },
          'base64'
        );
      }
    }
    setTimeout(async () => {
      await loadData();
      let value = 0;
      for (let i = 1; i < 10; i++) {
        value = value + 10;
        setUploadProgress(value);
        await sleep(500);
      }
      setUploadFiles(null);
      setUploadModal(false);
      loadData(false);
      successToast(t('cdsgn_design_new_design_upload'));
    }, [2000]);
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'> {t('cdsgn_upload_new_designs_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <p>{t('cdsgn_you_can_upload_new_designs_label')}</p>
          <p>{t('cdsgn_jpg_and_png_format_images_label')}</p>
          <p>{t('cdsgn_gif_animations_cannot_label')}</p>
          <strong>{t('cdsgn_other_file_formats_processed_label')}</strong>
          <div
            className='row'
            style={{ marginLeft: 5, marginBottom: 10, marginTop: 10 }}
          >
            <FileBase64
              type='file'
              multiple={true}
              onDone={getFiles.bind(this)}
            />
          </div>
          <div className='row' style={{ marginTop: 10 }}>
            {uploadFiles !== null &&
              uploadFiles?.map((file, index) => checkFileType(file, index))}
          </div>
        </CardBody>
        {uploadFiles !== null && (
          <CardFooter>
            <div className='d-flex justify-content-center'>
              <Button
                type='submit'
                onClick={() => setUploadImagesModal(true)}
                className='btn btn-success btn-block'
                style={{ width: 160 }}
              >
                {t('buttons:btn_upload_images')}
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
      <ProgressBarModal
        uploadModal={uploadModal}
        uploadProgress={uploadProgress}
        fileLength={uploadFiles?.length}
      />
      <UploadImagesModal
        uploadImagesModal={uploadImagesModal}
        setUploadImagesModal={setUploadImagesModal}
        selectedDropDown={selectedDropDown}
        setSelectedDropDown={setSelectedDropDown}
        addedCategory={addedCategory}
        categoryData={categoryData}
        tempCategories={tempCategories}
        beginUpload={beginUpload}
        addCategoryLoader={addCategoryLoader}
        setAddCategoryModal={setAddCategoryModal}
      />
      <AddCategoryModal
        modalShow={addCategoryModal}
        setModalShow={setAddCategoryModal}
        addCategory={addCategory}
      />
    </Fragment>
  );
};

export default UploadNewDesigns;
