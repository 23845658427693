import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Button,
} from 'reactstrap';

const CategoryChangesModal = ({
  cancelAllCategoryChangeModal,
  completeCategoryCancellation,
  setCancelAllCategoryChangeModal,
  checkedCategories,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons']);

  return (
    <Modal isOpen={cancelAllCategoryChangeModal} centered={true}>
      <ModalHeader>{t('revchn_category_changes_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              {checkedCategories?.length > 1
                ? t('revchn_cancel_all_changes')
                : t('revchn_cancel_changes')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => completeCategoryCancellation()}>
          {t('buttons:btn_yes')}
        </Button>
        <Button
          color='success'
          onClick={() => setCancelAllCategoryChangeModal(false)}
        >
          {t('buttons:btn_no')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategoryChangesModal;
