function getContent(programme_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/content`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      programme_id: programme_id,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default getContent;
