function getProgrammeCards(client_access_key, bearerToken) {
  return fetch(
    `${process.env.REACT_APP_API_2_ENDPOINT}/card-identifiers`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + bearerToken,
        'client_access_key': `${client_access_key}`,
      },
    }
  )
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}
export default getProgrammeCards;
