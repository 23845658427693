import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';

const RemoveItemModal = ({
  removeItemModal,
  setRemoveItemModal,
  removeItem,
  showRemoveItemLoader,
}) => {
  const { t } = useTranslation(['new_dnd', 'buttons']);

  return (
    <Modal isOpen={removeItemModal} centered={true}>
      <ModalHeader>{t('newdnd_remove_item_header_text')}</ModalHeader>
      <ModalBody>
        <Label>
          {t('newdnd_are_you_sure_you_wish_to_remove_this_design_label')}
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button
          color='danger'
          onClick={() => removeItem()}
          disabled={showRemoveItemLoader}
        >
          {t('buttons:btn_delete')}
          {showRemoveItemLoader && (
            <Spinner size='sm' style={{ marginLeft: '8px' }} />
          )}
        </Button>
        <Button
          color='secondary'
          onClick={() => setRemoveItemModal(false)}
          disabled={showRemoveItemLoader}
        >
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveItemModal;
