import React, { useState } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, Input, FormText, FormGroup, Button } from "reactstrap";
import Img from 'react-cool-img';
import DateTimePicker from 'react-datetime-picker';

export default function PushNotifications() {
    const [pushNotifications] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [value, onChange] = useState(new Date());

    function renderPushNotificationData() {
        if (pushNotifications.length > 0) {
            return pushNotifications.map((notifications, index) => {
                return (
                    <tr key={notifications.id}>
                        <td>{notifications.id}</td>
                        <td>{notifications.client_name}</td>
                        <td>{notifications.programme_identifier}</td>
                        <td className="text-success">{notifications.programme_state}</td>
                        <td>{notifications.active_design_categories}</td>
                    </tr>
                )
            })
        }
        else {
            // return (<Loading />)
            return (
                <>
                    <Modal isOpen={showModal} centered={true} >
                        <ModalHeader>Create Push Notification</ModalHeader>
                        <ModalBody>
                            <Form style={{ textAlign: 'left' }}>
                                <FormGroup>
                                    <Label for="exampleFile">File</Label>
                                    <Input type="file" name="file" id="exampleFile" />
                                    <FormText color="muted">
                                        This is some placeholder block-level help text for the above input.
                                        It's a bit lighter and easily wraps to a new line.
                                    </FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode">Push Notification Title</Label>
                                    <Input placeholder="e.g Get 10% off BrandX" name="title" id="title" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode">Push Notification Text</Label>
                                    <Input placeholder="e.g Browse new content from BrandX and get 10% off " name="text" id="text" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode">Send Schedule</Label>
                                    <div style={{ borderRadius: 5 }}>
                                        <DateTimePicker
                                            onChange={onChange}
                                            value={value}
                                            style={{ borderRadius: 5 }}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup tag="fieldset">
                                    <Label for="CategoryCode">{<strong>Distribution Channel:-</strong>}</Label>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>All Users</option>
                                        <option>Premium Content Subscribers</option>
                                        <option>All Non Subscribers</option>
                                        <option>Channel Specific Example</option>
                                    </Input>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => setShowModal(false)}>Save</Button>
                            <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <tr key={1}>
                        <td>{'1'}</td>
                        <td><Img
                            src='https://yimba.com/wp-content/uploads/2020/03/social-logo.jpg'
                            cache={true}
                            alt="Card Image"
                            style={{ height: 75, width: 125 }}
                        /></td>
                        <td><Button color="link" onClick={() => setShowModal(true)}>{'Customise Your Card'}</Button></td>
                        <td>{'All Users'}</td>
                        <td className="text-success"><strong>{'Sent'}</strong></td>
                    </tr>
                    <tr key={2}>
                        <td>{'2'}</td>
                        <td><Img
                            src='https://i1.wp.com/rematch.net/wp-content/uploads/2020/10/Nike-Extra-20-off-Banner-October-2020-1200-700.jpg?fit=1200%2C700&ssl=1'
                            cache={true}
                            alt="Card Image"
                            style={{ height: 75, width: 125 }}
                        /></td>
                        <td><Button color="link" onClick={() => setShowModal(true)}>{'Get 20% off Nike Merchandise with our exclusive promo'}</Button></td>
                        <td>{'Premium Content Subscribers Only'}</td>
                        <td className="text-warning"><strong>{'Scheduled'}</strong></td>
                    </tr>
                </>
            )
        }
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                {/* <CardTitle tag="h4">Push Notifications</CardTitle> */}
                                <button type="submit" className="btn btn-primary" onClick={() => setShowModal(true)}>{'New Push Notification'}</button>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Notification Image</th>
                                            <th>Notification Title</th>
                                            <th>Notification Channel</th>
                                            <th>Notification Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderPushNotificationData()}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}