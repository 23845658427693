import ImageTools from 'components/ImageTools';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

const CardBrandingModal = ({
  cardBrandingModal,
  setCardBrandingModal,
  handleSubmit,
  selectedDesign,
  globalBrandMode,
  setBrandConfig,
  downloadMode,
  selectedProgramme,
  handleImageProps,
  imageProps,
  setSubmitRequest,
  imageTools,
  selectedBrandConfig,
}) => {
  const { t } = useTranslation(['review_changes', 'img_tools', 'buttons']);

  return (
    <Modal
      isOpen={cardBrandingModal}
      centered={true}
      size='lg'
      style={{ maxWidth: '95%', width: '95%' }}
    >
      <ModalBody style={{ backgroundColor: '#f4f3ef' }}>
        <ImageTools
          t={t}
          item={selectedDesign}
          globalMode={globalBrandMode}
          selectedConfig={setBrandConfig}
          downloadMode={downloadMode}
          programme_id={selectedProgramme}
          imageProps={handleImageProps}
          imageDefaults={imageProps}
          setSubmitRequest={setSubmitRequest}
          handleSubmit={handleSubmit}
          setModal={setCardBrandingModal}
          imageTools={imageTools}
          selectedBrandConfig={selectedBrandConfig}
        />
      </ModalBody>
    </Modal>
  );
};

export default CardBrandingModal;
