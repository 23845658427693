import React, { Suspense, useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import { useAuth0 } from '../../react-auth0-spa';
import fetchClientKey from '../../utils/fetchClientKey';
import fetchCardDesigns from '../../utils/fetchCardDesigns';
import addDesignCategory from '../../utils/addDesignCategory';
import fetchDesignCategories from '../../utils/fetchDesignCategories';
import fetchTempCardDesigns from '../../utils/fetchTempCardDesigns';
import fetchDesignDeleteQueue from '../../utils/fetchDesignDeleteQueue';
import fetchSubDesignDeleteQueue from '../../utils/fetchSubDesignDeleteQueue';
import fetchProgrammeData from '../../utils/fetchProgrammeData';
import NewDND from '../../components/NewDND/NewDND';
import updateLiveDesignStatus from '../../utils/updateLiveDesignStatus';
import deleteTempDesign from '../../utils/deleteTempDesign';
import setDesignSubmitted from '../../utils/setDesignSubmitted';
import setCategorySubmitted from '../../utils/setCategorySubmitted';
import setProgrammeDesignSubmitted from '../../utils/setProgrammeDesignSubmitted';
import getDesignSwitchQueue from '../../utils/getDesignSwitchQueue';
import publishDesignSwitch from '../../utils/publishDesignSwitch';
import deleteCategoryChange from '../../utils/deleteCategoryChange';
import deleteProgrammeDesign from '../../utils/deleteProgrammeDesign';
import getAvailableBins from '../../utils/getAvailableBins';
import { Redirect } from 'react-router-dom';
import updateLiveBrandConfig from '../../utils/updateLiveBrandConfig';
import createZipFile from '../../utils/getZippedImages';
import createCompositeImage from '../../utils/createCompositeImage';
import ReviewSummary from './ReviewSummary';
import ReviewCardDesigns from './ReviewCardDesigns';
import ReviewCategoryChanges from './ReviewCategoryChanges';
import CarouselCategories from './CarouselCategories';
import ApplyCardBrandingModal from './Modal/ApplyCardBrandingModal';
import UploadNewDesigns from './UploadNewDesigns';
import { useTranslation } from 'react-i18next';
import AddCategoryModal from './Modal/AddCategoryModal';
import { successToast } from 'components/Toast/SuccessToast';
import CardDesignPublishModal from './Modal/CardDesignPublishModal';
import PublishCompletedModal from './Modal/PublishCompletedModal';
import Loading from 'components/Loading';
import { pendingToast } from 'components/Toast/SuccessToast';
import { updateToast } from 'components/Toast/SuccessToast';
import updateImagePropsQueue from 'utils/updateImagePropsQueue';

const CardDesigns = () => {
  const { t } = useTranslation(['card_designs', 'table', 'buttons', 'new_dnd']);

  const { user } = useAuth0();
  const [userSessionKey, setSessionKey] = useState(null);

  // States of API response
  const [cardDesigns, setCardDesigns] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [tempCategories, setTempCategories] = useState(null);
  const [tempDesigns, setTempDesigns] = useState(null);
  const [queuedDeletions, setQueuedDeletions] = useState(null);
  const [queuedSubDeletions, setQueuedSubDeletions] = useState(null);
  const [cardProgramme, setCardProgramme] = useState(null);
  const [designSwitchQueue, setDesignSwitchQueue] = useState(null);

  // Show task templates on button click
  const [showCarousels, setShowCarousels] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showChangesSummary, setChangesSummary] = useState(false);

  const [publishLoader, setPublishLoader] = useState(false);

  const [addParentCategoryModal, setAddParentCategoryModal] = useState(false);
  const [cardDesignPublishModal, setCardDesignPublishModal] = useState(false);
  const [cardDesignPublishModalComplete, setCardDesignPublishModalComplete] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [newMainData, setNewMainData] = useState(null);
  const [seed, setSeed] = useState(1);

  const [clientBins, setClientBins] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [selectedBrandConfig, setSelectedBrandConfig] = useState(null);
  const [imageTools, setImageTools] = useState({
    contentExpiry: null,
    hasHapticResponse: false,
    associatedSoundURL: null,
  });

  let designsCount =
    ~~tempDesigns?.length +
    ~~queuedSubDeletions?.length +
    ~~designSwitchQueue?.length;

  // API calls start
  const callClientKeyAPI = async () => {
    const res = await fetchClientKey(user.email);
    return res?.client_access_key;
  };

  const validateClientAccessKey = async (clientAccessKey) => {
    let key = clientAccessKey;
    if (!key) {
      key = await callClientKeyAPI();
    }
    return key;
  };

  const callProgrammeDataAPI = async (clientAccessKey) => {
    const key = await validateClientAccessKey(clientAccessKey);
    const results = await fetchProgrammeData(key);
    setCardProgramme(results?.id);
    setSessionKey(results?.programme_identifier);
    return results?.programme_identifier;
  };

  const validateSessionKey = async (sessionKey) => {
    let key = sessionKey;
    if (!key) {
      key = await callProgrammeDataAPI();
    }
    return key;
  };

  const callCardDesignsAPI = async (clientAccessKey) => {
    const key = await validateClientAccessKey(clientAccessKey);
    const designs = await fetchCardDesigns(key);
    setCardDesigns([designs?.categories]);
    getGroupedResults(designs?.categories);
  };

  const callDesignCategoriesAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const rs = await fetchDesignCategories(key);
    if (rs?.length > 0) {
      setTempCategories(rs);
    } else {
      setTempCategories([]);
    }
  };

  const callTempCardDesignsAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const tempDesigns = await fetchTempCardDesigns(key);
    if (!tempDesigns.error) {
      setTempDesigns(tempDesigns);
    } else {
      setTempDesigns([]);
    }
  };

  const callDesignDeleteQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const deletedDesigns = await fetchDesignDeleteQueue(key);
    if (!deletedDesigns.error) {
      setQueuedDeletions(deletedDesigns);
    } else {
      setQueuedDeletions([]);
    }
  };

  const callSubDesignDeleteQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const deletedSubDesigns = await fetchSubDesignDeleteQueue(key);
    if (!deletedSubDesigns.error) {
      setQueuedSubDeletions(deletedSubDesigns);
    } else {
      setQueuedSubDeletions([]);
    }
  };

  const callDesignSwitchQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const switchQueue = await getDesignSwitchQueue(key);
    if (!switchQueue.error) {
      setDesignSwitchQueue(switchQueue);
    } else {
      setDesignSwitchQueue([]);
    }
  };

  const callAvailableBinsAPI = async (clientAccessKey) => {
    const key = await validateClientAccessKey(clientAccessKey);
    const bins = await getAvailableBins(key);
    if (!bins.error) {
      setClientBins(bins);
    } else {
      setClientBins(null);
    }
  };
  // API calls end

  const loadData = async (showLoader = true) => {
    if (showLoader) {
      setLoader(true);
    }
    const clientAccessKey = await callClientKeyAPI();

    const sessionKey = await callProgrammeDataAPI(clientAccessKey);

    callCardDesignsAPI(clientAccessKey);

    callDesignCategoriesAPI(sessionKey);

    callTempCardDesignsAPI(sessionKey);

    callDesignDeleteQueueAPI(sessionKey);

    callSubDesignDeleteQueueAPI(sessionKey);

    callDesignSwitchQueueAPI(sessionKey);

    callAvailableBinsAPI(clientAccessKey);

    setLoader(false);
  };

  // Fetch all the data
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (cardDesigns?.length > 0 && tempCategories && tempDesigns) {
      mergeAdditionalDesigns(tempDesigns);
    }
  }, [cardDesigns, tempDesigns, tempCategories]);

  useEffect(() => {
    if (designSwitchQueue) {
      mergeDesignSwitchQueue();
    }
  }, [designSwitchQueue]);

  const mergeAdditionalDesigns = (designs) => {
    const data = cardDesigns?.[0]?.filter(
      (item) => item.parent_catagory != null
    );
    if (tempCategories) {
      const ids = data?.map((item) => item?.design_category_id);
      tempCategories?.forEach((record) => {
        record.design_category_id = record.id;
        record.isTemp = true;
        if (!ids?.includes(record.id)) {
          data?.push(record);
        }
      });
      if (designs) {
        designs?.forEach((design) => {
          data?.forEach((category, index) => {
            if (category?.design_category_id === design.category_id) {
              design.order_id = index;
              design.mergedDesign = true;
              if (!category?.designs) {
                category.designs = [];
              }
              const ids = category?.designs?.map((item) => item?.id);
              if (!ids?.includes(design?.id)) {
                category?.designs?.push(design);
                setSeed(Math.random());
              }
            }
          });
        });
      }
      const newData = data?.filter((dit) => dit?.isTemp);
      if (newData?.length > 0) {
        newData?.forEach((cat) => {
          cat?.designs?.forEach((des, index) => {
            des.order_id = index;
          });
        });
        setSeed(Math.random());
      }
      designs = null;
    } else {
      if (designs) {
        designs?.forEach((design) => {
          data?.forEach((category, index) => {
            if (category?.design_category_id === design.category_id) {
              design.order_id = index;
              design.mergedDesign = true;
              const ids = category?.designs?.map((item) => item?.id);
              if (!ids?.includes(design?.id)) {
                category?.designs?.push(design);
                setSeed(Math.random());
              }
            }
          });
        });
      }
      const newData = data?.filter((dit) => dit?.isTemp);
      if (newData?.length > 0) {
        newData?.forEach((cat) => {
          cat?.designs?.forEach((des, index) => {
            des.order_id = index;
          });
        });
        setSeed(Math.random());
      }
      designs = null;
    }

    // Adding
    const filtered = data?.filter((item) => item?.designs);
    filtered?.sort(function (a, b) {
      return a?.display_order - b?.display_order;
    });
    setNewMainData(filtered);
  };

  const mergeDesignSwitchQueue = () => {
    const data = cardDesigns?.[0]?.filter(
      (item) => item?.parent_catagory != null
    );
    designSwitchQueue?.forEach((design) => {
      let newLocation = data?.filter(
        (item) => item?.design_category_id === design?.category_id
      );
      if (newLocation?.length > 0) {
        newLocation?.[0].designs?.push(design);
      }
    });
  };

  // Toggle Tasks button start
  const displayCarousels = () => {
    setShowCarousels(true);
    setShowUpload(false);
    setShowUpload(false);
    setShowCategories(false);
    setChangesSummary(false);
  };

  const displayCategories = () => {
    setShowCarousels(false);
    setShowUpload(false);
    setShowCategories(true);
    setChangesSummary(false);
  };

  const displayUpload = () => {
    setShowCarousels(false);
    setShowCategories(false);
    setShowUpload(true);
    setChangesSummary(false);
  };

  const displayChanges = () => {
    setChangesSummary(true);
    setShowCarousels(false);
    setShowUpload(false);
    setShowCategories(false);
  };
  // Toggle Tasks button end

  // Add Parent Category
  const addParentCategory = async (categoryName, categoryDescription) => {
    setAddParentCategoryModal(false);
    let newCatCode = categoryName?.replace(' ', '');
    newCatCode = newCatCode.toLowerCase();
    addDesignCategory(
      userSessionKey,
      newCatCode,
      categoryName,
      categoryDescription,
      'parent',
      'NONE'
    );
    await callCardDesignsAPI();
    await callDesignCategoriesAPI();
    successToast(t('cdsgn_category_has_been_added_label'));
  };

  const getGroupedResults = (cardDesigns) => {
    let parentCategories = [];
    let parentGroups = [];
    let parentCodes = [];
    cardDesigns?.forEach((design) => {
      if (design.parent_catagory) {
        parentCategories?.push(design);
      } else {
        if (design.design_count > 0) {
          parentCategories?.push(design);
        } else {
          let firstDesign = cardDesigns?.find((result) => {
            return result.parent_catagory === design.code;
          });
          let subDesigns = cardDesigns?.filter(
            ({ parent_catagory }) => parent_catagory === design.code
          );
          design.AllSubCategories = subDesigns;
          if (firstDesign) {
            design.designs = firstDesign.designs;
          }
          parentGroups?.push(design);
          parentCodes?.push(design?.title);
        }
      }
    });
    prepDesignData(cardDesigns);
    // setColumns(parentGroups);
    setCategoryData(parentCategories);
  };

  const prepDesignData = (dataSet) => {
    dataSet?.forEach((item, index) => {
      item.order_id = index;
      item.designs?.map((design, idx) => (design.order_id = idx));
    });
  };

  // Publish changes start
  const publishCategoryChanges = async () => {
    if (tempCategories) {
      for (let i = 0; i < tempCategories?.length; i++) {
        const category = tempCategories[i];
        if (category?.action) {
          if (category?.action === 'DELETE') {
            if (cardDesigns?.[0]) {
              let categoryReference = cardDesigns?.[0]?.filter(
                (item) => item.code === category?.code
              );
              await deleteProgrammeDesign(
                categoryReference[0]?.design_category_id,
                categoryReference[0]?.programme_id
              );
            }
            await deleteCategoryChange(category?.id);
          } else {
            if (category?.designs?.length > 0) {
              await setCategorySubmitted(category?.id);
            }
          }
        } else {
          await setProgrammeDesignSubmitted(category?.id);
        }
      }
    }
  };

  // Publish Design
  const publishDesignChanges = async () => {
    setPublishLoader(true);
    if (tempDesigns) {
      for (let i = 0; i < tempDesigns?.length; i++) {
        const design = tempDesigns[i];
        await setDesignSubmitted(design?.id);
      }
    }
    if (queuedSubDeletions) {
      for (let i = 0; i < queuedSubDeletions?.length; i++) {
        const design = queuedSubDeletions[i];
        await updateLiveDesignStatus('0', design.design_identifier);
        await deleteTempDesign(design?.id);
      }
    }
    if (designSwitchQueue) {
      for (let i = 0; i < designSwitchQueue?.length; i++) {
        const design = designSwitchQueue[i];
        await publishDesignSwitch(design.category_id, design.design_identifier);
        await deleteTempDesign(design?.id);
      }
    }
    await publishCategoryChanges();
    loadData();
    setCardDesignPublishModal(false);
    setPublishLoader(false);
    setCardDesignPublishModalComplete(true);
  };
  // Publish changes end

  const filterBinData = (selectedBin) => {
    if (selectedBin === 'All Bins') {
      //need to create a data reset to display all bins again
      fetchClientKey(user.email).then((keyinfo) => {
        fetchProgrammeData(keyinfo.client_access_key).then((results) => {
          setCardProgramme(results?.id);
        });
      });
    } else {
      let associatedCardProgramme = clientBins?.filter(
        (item) => item.bin_number === parseInt(selectedBin)
      );
      setCardProgramme(associatedCardProgramme[0]?.programme_id);
    }
  };

  const saveGlobalBranding = async () => {
    const id = pendingToast();
    let rootData = cardDesigns?.[0];
    const promiseAll = [];
    for (let index = 0; index < rootData?.length; index++) {
      const category = rootData[index];
      for (let i = 0; i < category?.designs?.length; i++) {
        const design = category?.designs[i];
        design.brand_configuration = selectedBrandConfig?.id;
        const promise1 = updateLiveBrandConfig(
          design.design_identifier,
          selectedBrandConfig?.id
        ).then((res) => ({ res: res, promise: 'promise1' }));
        promiseAll.push(promise1);
        const promise2 = createImageComposite(design).then((res) => ({
          res: res,
          promise: 'promise2',
        }));
        promiseAll.push(promise2);
      }
    }

    for (let index = 0; index < tempCategories?.length; index++) {
      var category = tempCategories[index];
      for (let idx = 0; idx < category?.designs?.length; idx++) {
        let design = category?.designs?.[idx];
        const promise1 = updateImagePropsQueue(
          design.id,
          '0,0',
          '1',
          selectedBrandConfig.id
        ).then((res) => ({ res: res, promise: 'promise1' }));
        promiseAll.push(promise1);

        design.design_queue = true;
        const promise2 = createImageComposite(design).then((res) => ({
          res: res,
          promise: 'promise2',
        }));
        promiseAll.push(promise2);
      }
    }
    Promise.all(promiseAll).then((values) => {
      updateToast(id, t('new_dnd:newdnd_operation_completed'));
      loadData();
    });
  };

  const createImageComposite = async (item) => {
    const brandConfig = selectedBrandConfig;
    let categoryName = item.catagory;
    if (!categoryName) {
      const category = tempCategories?.filter(
        (it) => it?.id === item?.category_id
      )?.[0];
      if (category) {
        categoryName = category?.code;
      }
    }
    await createCompositeImage(
      item?.id,
      item?.url,
      brandConfig?.brand_logo,
      brandConfig?.brand_network_logo,
      categoryName,
      item.order_id,
      cardProgramme
    );
  };

  const downloadAllImages = (allDesigns) => {
    const urls = [];
    const fileNames = [];
    allDesigns[0]?.forEach((category, idx) => {
      category?.designs?.forEach((design) => {
        if (design.design_data) {
          urls?.push(design.design_data);
          fileNames?.push(design.catagory + design.order_id + '.jpg');
        } else {
          urls?.push(design?.url);
          fileNames?.push(design.catagory + design.order_id + '.jpg');
        }
      });
    });
    const bodyRequest = {
      body: {
        urls: urls,
        fileNames: fileNames,
      },
    };
    createZipFile(bodyRequest).then((res) => {
      const fileStream = JSON.parse(res);
      let a = document.createElement('a');
      a.href = fileStream.fileStream;
      a.download = 'images.zip';
      a.click();
    });
  };

  const setSubmitRequest = (key, value) => {
    setImageTools({ ...imageTools, [key]: value });
  };

  if (!user) {
    return <Redirect to={'/admin/card-designs'} />;
  }

  //START OF RENDER
  return (
    <Suspense fallback={<Spinner size='sm' />}>
      <div className='content'>
        <Row>
          <Col md='3'>
            <Card style={{ height: 125 }}>
              <CardHeader>
                <CardTitle tag='h6'>{t('cdsgn_select_bin_number')}</CardTitle>
              </CardHeader>
              <CardBody>
                <select
                  onChange={(choice) => filterBinData(choice.target.value)}
                  className='form-control'
                >
                  <option>{t('cdsgn_all_bins')}</option>
                  {clientBins?.map((bin, index) => (
                    <option key={index} value={bin.bin_number}>
                      {bin.bin_number}
                    </option>
                  ))}
                </select>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/** Apply Card Branding Modal */}
        <ApplyCardBrandingModal
          saveGlobalBranding={saveGlobalBranding}
          setEditModal={setEditModal}
          editModal={editModal}
          cardProgramme={cardProgramme}
          setSelectedBrandConfig={setSelectedBrandConfig}
          selectedBrandConfig={selectedBrandConfig}
          setSubmitRequest={setSubmitRequest}
          imageTools={imageTools}
        />

        {/** Tasks */}
        <div>
          <h5>{t('cdsgn_tasks')}</h5>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-row'>
              <div className='mr-4'>
                <Button
                  type='submit'
                  className='btn btn-primary btn-block'
                  onClick={() => displayCarousels()}
                >
                  {t('buttons:btn_view_carousels')}
                </Button>
              </div>
              <div className='mr-4'>
                <Button
                  type='submit'
                  className='btn btn-primary btn-block'
                  onClick={() => displayUpload()}
                >
                  {t('buttons:btn_upload_images')}
                </Button>
              </div>
              <div className='mr-4'>
                <Button
                  type='submit'
                  className='btn btn-primary btn-block'
                  onClick={() => displayCategories()}
                >
                  {t('buttons:btn_carousel_categories')}
                </Button>
              </div>
            </div>
            <div>
              <Button
                type='submit'
                className='btn btn-success btn-block'
                onClick={() => displayChanges()}
                disabled={loader}
              >
                {t('buttons:btn_review_changes')}
              </Button>
            </div>
          </div>
          <div className='d-flex'>
            <div className='mr-4'>
              <Button
                type='submit'
                className='btn btn-primary btn-block'
                onClick={() => {
                  setImageTools({
                    contentExpiry: null,
                    hasHapticResponse: false,
                    associatedSoundURL: null,
                  });
                  setSelectedBrandConfig(null);
                  setEditModal(true);
                }}
              >
                {t('buttons:btn_apply_card_branding')}
              </Button>
            </div>
            <div>
              <Button
                type='submit'
                className='btn btn-primary btn-block'
                onClick={() => downloadAllImages(cardDesigns)}
              >
                {t('buttons:btn_download_all_images')}
              </Button>
            </div>
          </div>
        </div>

        {/** Show Upload */}
        {showUpload && (
          <UploadNewDesigns
            sessionKey={userSessionKey}
            cardDesigns={cardDesigns}
            tempCategories={tempCategories}
            categoryData={categoryData}
            loadData={loadData}
          />
        )}

        {/** Carousel Configuration */}
        {loader && cardDesigns === null && <Loading />}
        {showCarousels && cardDesigns !== null && (
          <div className='mt-2'>
            <Card>
              <CardHeader>
                <h5>{t('cdsgn_carousel_configuration')}</h5>
                <p>{t('cdsgn_carousel_configuration_desc')}</p>
                <strong>{t('cdsgn_general')}</strong>
              </CardHeader>
              <CardBody>
                <NewDND
                  data={cardDesigns?.[0]?.filter(
                    (item) => item.parent_catagory != null
                  )}
                  parentData={cardDesigns?.[0]?.filter(
                    (item) => item.parent_catagory === null
                  )}
                  sessionKey={userSessionKey}
                  designQueue={queuedSubDeletions}
                  newMainData={newMainData}
                  cardProgramme={cardProgramme}
                  tempCategories={tempCategories}
                  designSwitchQueue={designSwitchQueue}
                  loadData={loadData}
                />
              </CardBody>
            </Card>
          </div>
        )}

        {/** Carousel Categories */}
        {showCategories && (
          <Suspense fallback={<Spinner size='sm' />}>
            <CarouselCategories
              loader={loader}
              cardDesigns={cardDesigns}
              tempCategories={tempCategories}
              sessionKey={userSessionKey}
              callCardDesignsAPI={callCardDesignsAPI}
              callDesignCategoriesAPI={callDesignCategoriesAPI}
            />
            <AddCategoryModal
              modalShow={addParentCategoryModal}
              setModalShow={setAddParentCategoryModal}
              addCategory={addParentCategory}
            />
          </Suspense>
        )}

        {/** Review Changes */}
        {showChangesSummary && (
          <Suspense fallback={<Spinner size='sm' />}>
            <div className='content'>
              <ReviewSummary />

              {/** Review Card Design Table */}
              <ReviewCardDesigns
                tempDesigns={tempDesigns}
                cardDesigns={cardDesigns}
                tempCategories={tempCategories}
                queuedSubDeletions={queuedSubDeletions}
                designSwitchQueue={designSwitchQueue}
                callTempCardDesignsAPI={callTempCardDesignsAPI}
                callCardDesignsAPI={callCardDesignsAPI}
                callDesignCategoriesAPI={callDesignCategoriesAPI}
                callSubDesignDeleteQueueAPI={callSubDesignDeleteQueueAPI}
                callDesignSwitchQueueAPI={callDesignSwitchQueueAPI}
              />

              <CardDesignPublishModal
                publishLoader={publishLoader}
                publishDesignChanges={publishDesignChanges}
                cardDesignPublishModal={cardDesignPublishModal}
                setCardDesignPublishModal={setCardDesignPublishModal}
              />

              <PublishCompletedModal
                publishComplete={() => setCardDesignPublishModalComplete(false)}
                cardDesignPublishModalComplete={cardDesignPublishModalComplete}
              />
            </div>
            <br></br>
            <div className='content'>
              <ReviewCategoryChanges
                tempCategories={tempCategories}
                tempDesigns={tempDesigns}
                queuedDeletions={queuedDeletions}
                cardDesigns={cardDesigns}
                callDesignCategoriesAPI={callDesignCategoriesAPI}
                callTempCardDesignsAPI={callTempCardDesignsAPI}
                callDesignDeleteQueueAPI={callDesignDeleteQueueAPI}
                callCardDesignsAPI={callCardDesignsAPI}
              />
              <div className='d-flex justify-content-end'>
                <div>
                  <Button
                    type='submit'
                    className='btn btn-success btn-block'
                    onClick={() => setCardDesignPublishModal(true)}
                    disabled={
                      tempCategories?.length === 0 && designsCount === 0
                    }
                  >
                    {t('buttons:btn_submit_publish')}
                  </Button>
                </div>
              </div>
            </div>
          </Suspense>
        )}
      </div>
    </Suspense>
  );
};

export default CardDesigns;
