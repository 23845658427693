import { Fragment, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import ContentHubModal from './Modal/ContentHubModal';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { checkNull } from 'utils/validationUtils';

const ContentHubChanges = ({
  loading,
  contentQueue,
  callContentQueueAPI,
  setShowPreview,
  setPreviewType,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons', 'table']);
  const [showHubLoader, setShowHubLoader] = useState(false);
  const [checkedContentHub, setCheckedContentHub] = useState([]);
  const [hubCancelModal, setHubCancelModal] = useState(false);

  // Content Hub Content Changes select start
  const selectAllHub = (e) => {
    if (e.target.checked) {
      const allHub = contentQueue?.map((category) => category.id);
      setCheckedContentHub(allHub);
    } else {
      setCheckedContentHub([]);
    }
  };

  const toggleCheckHub = (id, isCheck) => {
    if (isCheck) {
      if (!checkedContentHub?.includes(id)) {
        setCheckedContentHub([...checkedContentHub, id]);
      }
    } else {
      setCheckedContentHub(checkedContentHub?.filter((item) => item !== id));
    }
  };

  const completeHubCancellation = async () => {
    setHubCancelModal(false);
  };
  // Content Hub Content Changes select end

  const renderContentHubData = () => {
    if (contentQueue) {
      return contentQueue?.map((content, index) => {
        return (
          <tr key={content.id}>
            <td>
              <input
                type='checkbox'
                id={content.id}
                checked={checkedContentHub?.includes(content.id)}
                onChange={(e) => toggleCheckHub(content.id, e.target.checked)}
                disabled
              />
            </td>
            <td>{checkNull(content.id)}</td>
            <td>{checkNull(content?.title)}</td>
            <td>{checkNull(content.module_type)}</td>
            <td
              className={
                content.status === 'In Review' ? 'text-warning' : 'text-success'
              }
            >
              <strong>{checkNull(content.status)}</strong>
            </td>
            <td>
              <Button
                color='link'
                onClick={() => {
                  setShowPreview(true);
                  setPreviewType(content?.title);
                }}
              >
                {t('buttons:btn_sdk_preview')}
              </Button>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('revchn_content_hub_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllHub'
                    checked={
                      checkedContentHub?.length === contentQueue?.length &&
                      contentQueue?.length > 0
                    }
                    onChange={selectAllHub}
                    // disabled={contentQueue?.length === 0}
                    disabled
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_content_module_name')}</th>
                <th>{t('table:tb_module_type')}</th>
                <th>{t('table:tb_status')}</th>
                <th>{t('table:tb_preview_content')}</th>
              </tr>
            </thead>
            {loading && !contentQueue ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{renderContentHubData()}</tbody>
            )}
          </Table>
          {/* <div className='d-flex flex-row-reverse mr-1'>
            <button
              type='submit'
              className='btn btn-danger btn-block d-flex justify-content-center'
              onClick={() => setHubCancelModal(true)}
              disabled={checkedContentHub?.length === 0 || showHubLoader}
              style={{ width: 150 }}
            >
              {t('buttons:btn_cancel')}
              {checkedContentHub?.length > 1 && ' ALL'}
              {showHubLoader && (
                <Spinner size='sm' style={{ marginLeft: '8px' }} />
              )}
            </button>
          </div> */}
        </CardBody>
      </Card>
      <ContentHubModal
        hubCancelModal={hubCancelModal}
        setHubCancelModal={setHubCancelModal}
        completeHubCancellation={completeHubCancellation}
        checkedContentHub={checkedContentHub}
      />
    </Fragment>
  );
};

export default ContentHubChanges;
