function setCustomMessage(message_title, message_content, session_key) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/custom-messages`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      message_title: `${message_title}`,
      message_content: `${message_content}`,
      session_key: `${session_key}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default setCustomMessage;
