import React, { Suspense, useEffect, useState } from 'react';

// reactstrap components
import { useAuth0 } from '../../react-auth0-spa';
import fetchClientKey from '../../utils/fetchClientKey';
import fetchCardDesigns from '../../utils/fetchCardDesigns';
import fetchDesignCategories from '../../utils/fetchDesignCategories';
import fetchTempCardDesigns from '../../utils/fetchTempCardDesigns';
import fetchDesignDeleteQueue from '../../utils/fetchDesignDeleteQueue';
import fetchProgrammeData from '../../utils/fetchProgrammeData';
import cancelDesignChange from '../../utils/cancelDesignChange';
import publishDesign from '../../utils/publishDesign';
import deleteCategoryChange from '../../utils/deleteCategoryChange';
import deleteProgrammeChange from '../../utils/deleteProgrammeChange';
import updateLiveDesignCategory from '../../utils/updateLiveDesignCategory';
import publishNewCategory from '../../utils/publishNewCategory';
import updateDesign from '../../utils/updateDesign';
import addProgrammeDesign from '../../utils/addProgrammeDesign';
import getPromotedContentQueue from '../../utils/getPromotedContentQueue';
import deleteTempPromoContent from 'utils/deleteTempPromoContent';
import publishPromoContent from 'utils/publishPromoContent';
import getBrandConfigQueue from 'utils/getBrandConfigQueue';
import deleteBrandConfigQueue from 'utils/deleteBrandConfigQueue';
import publishBrandConfiguration from 'utils/publishBrandConfiguration';
import getContentQueue from 'utils/getContentQueue';
import addContent from 'utils/addContent';
import addContentData from 'utils/addContentData';
import ReviewSummary from './ReviewSummary';
import PromotedContent from './PromotedContent';
import ContentHubChanges from './ContentHubChanges';
import CardBrandConfigurations from './CardBrandConfigurations';
import CardDesigns from './CardDesigns';
import CategoryChanges from './CategoryChanges';
import ShowPreviewModal from './Modal/ShowPreviewModal';
import PublishCategoryChangesModal from './Modal/PublishCategoryChangesModal.jsx';
import PublishingCompleteModal from './Modal/PublishingCompleteModal';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

const ReviewChanges = () => {
  const { t } = useTranslation('buttons');
  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);

  // States of API response
  const [brandConfigurations, setBrandConfigurations] = useState(null);
  const [cardProgramme, setCardProgramme] = useState(null);
  const [cardDesigns, setCardDesigns] = useState(null);
  const [tempCategories, setTempCategories] = useState(null);
  const [tempDesigns, setTempDesigns] = useState(null);
  const [tempPromoContent, setTempPromoContent] = useState(null);
  const [queuedDeletions, setQueuedDeletions] = useState(null);

  const [publishingInProgress, setPublishingInProgress] = useState(false);
  const [publishingComplete, setPublishingComplete] = useState(false);
  const [contentQueue, setContentQueue] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewType, setPreviewType] = useState(null);

  // Modal
  const [publishCategoryChangesModal, setPublishCategoryChangesModal] =
    useState(false);

  // API calls start
  const callClientKeyAPI = async () => {
    const res = await fetchClientKey(user.email);
    return res?.client_access_key;
  };

  const validateClientAccessKey = async (clientAccessKey) => {
    let key = clientAccessKey;
    if (!key) {
      key = await callClientKeyAPI();
    }
    return key;
  };

  const callProgrammeDataAPI = async (clientAccessKey) => {
    const key = await validateClientAccessKey(clientAccessKey);
    const results = await fetchProgrammeData(key);
    setCardProgramme(results?.id);
    return results?.programme_identifier;
  };

  const validateSessionKey = async (sessionKey) => {
    let key = sessionKey;
    if (!key) {
      key = await callProgrammeDataAPI();
    }
    return key;
  };

  const callCardDesignsAPI = async (clientAccessKey) => {
    const key = await validateClientAccessKey(clientAccessKey);
    const designs = await fetchCardDesigns(key);
    setCardDesigns([designs?.categories]);
    getGroupedResults(designs?.categories);
  };

  const callDesignCategoriesAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const rs = await fetchDesignCategories(key);
    let categories = [];
    if (rs?.length > 0) {
      rs?.forEach((category) => {
        if (category?.submitted) {
          categories.push(category);
        }
      });
      if (categories?.length > 0) {
        setTempCategories(categories);
      }
    } else {
      setTempCategories([]);
    }
  };

  const callTempCardDesignsAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const tempDesigns = await fetchTempCardDesigns(key);
    if (!tempDesigns.error) {
      setTempDesigns(tempDesigns);
    } else {
      setTempDesigns([]);
    }
  };

  const callDesignDeleteQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const deletedDesigns = await fetchDesignDeleteQueue(key);
    if (!deletedDesigns.error) {
      setQueuedDeletions(deletedDesigns);
    } else {
      setQueuedDeletions([]);
    }
  };

  const callPromotedContentQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const promotedContent = await getPromotedContentQueue(key);
    if (!promotedContent.error) {
      setTempPromoContent(promotedContent);
    } else {
      setTempPromoContent([]);
    }
  };

  const callBrandConfigQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const brandConfigQueue = await getBrandConfigQueue(key);
    if (!brandConfigQueue.error) {
      setBrandConfigurations(brandConfigQueue);
    } else {
      setBrandConfigurations([]);
    }
  };

  const callContentQueueAPI = async (sessionKey) => {
    const key = await validateSessionKey(sessionKey);
    const contentConfigQueue = await getContentQueue(key);
    if (!contentConfigQueue.error) {
      setContentQueue(contentConfigQueue);
    } else {
      setContentQueue([]);
    }
  };

  const loadData = async () => {
    setLoading(true);

    const clientAccessKey = await callClientKeyAPI();

    const sessionKey = await callProgrammeDataAPI(clientAccessKey);

    await callCardDesignsAPI(clientAccessKey);

    await callDesignCategoriesAPI(sessionKey);

    await callTempCardDesignsAPI(sessionKey);

    await callDesignDeleteQueueAPI(sessionKey);

    await callPromotedContentQueueAPI(sessionKey);

    await callBrandConfigQueueAPI(sessionKey);

    await callContentQueueAPI(sessionKey);

    setLoading(false);
  };
  // API calls end

  // Fetch all the data
  useEffect(() => {
    loadData();
  }, []);

  const getGroupedResults = (cardDesigns) => {
    let parentCategories = [];
    let parentGroups = [];
    let parentCodes = [];
    cardDesigns?.forEach((design) => {
      if (design?.parent_catagory) {
        parentCategories.push(design);
      } else {
        if (design?.design_count > 0) {
          parentCategories.push(design);
        } else {
          let firstDesign = cardDesigns.find((result) => {
            return result.parent_catagory === design?.code;
          });
          let subDesigns = cardDesigns?.filter(
            ({ parent_catagory }) => parent_catagory === design?.code
          );
          design.AllSubCategories = subDesigns;
          if (firstDesign) {
            design.designs = firstDesign.designs;
          }
          parentGroups.push(design);
          parentCodes.push(design?.title);
        }
      }
    });
    prepDesignData(cardDesigns);
    // setColumns(parentGroups);
    // getAvailableCategories(parentGroups);
  };

  const prepDesignData = (dataSet) => {
    dataSet?.map(
      (item, index) => (
        (item.order_id = index),
        item.designs?.map((design, idx) => (design.order_id = idx))
      )
    );
  };

  const publishBrandConfigurations = async () => {
    if (brandConfigurations) {
      for (let index = 0; index < brandConfigurations?.length; index++) {
        const config = brandConfigurations[index];
        await publishBrandConfiguration(
          config.programme_id,
          config.brand_configuration_name,
          config?.brand_logo,
          config?.brand_logo_position,
          config.brand_network_logo,
          config.brand_network
        );
        await deleteBrandConfigQueue(config.id);
      }
    }
    return 'Resolving Brand Configs';
  };

  const publishCategoryChanges = async () => {
    if (tempCategories) {
      for (let index = 0; index < tempCategories?.length; index++) {
        const category = tempCategories[index];
        if (category?.submitted) {
          if (category?.action) {
            if (category?.action === 'UPDATE') {
              let matchingData = cardDesigns[0]?.filter(
                (item) => item.code === category?.code
              );
              await updateLiveDesignCategory(
                matchingData[0].design_category_id,
                category?.parent_category
              );
              await deleteCategoryChange(category?.id);
            }
            if (category?.action === 'NONE') {
              let newParent = '0';
              if (category?.parent_category === 0) {
                newParent = '19';
              }
              let res = await publishNewCategory(
                newParent,
                category?.code,
                category?.title,
                category?.description
              );
              await deleteCategoryChange(category?.id);
              await addProgrammeDesign(res.createdID, cardProgramme);
              // let updated = await updateDesignCategories(category?.id, res.createdID)
              if (tempDesigns) {
                for (let index = 0; index < tempDesigns?.length; index++) {
                  const design = tempDesigns[index];
                  if (design?.category_id === category?.id) {
                    await updateDesign(design?.id, res.createdID);
                  }
                }
              }
            }
          } else {
            if (category?.publishAction) {
              if (
                category?.publishAction.includes(
                  'Remove from Promoted Category'
                )
              ) {
                await updateLiveDesignCategory(category?.design_category_id, 0);
                await deleteProgrammeChange(category?.id);
                await deleteCategoryChange(category?.id);
              }
            }
          }
        }
      }
    }
    return 'Resolving Category Changes';
  };

  const publishPromotContentChanges = async () => {
    if (tempPromoContent) {
      const userSessionKey = await callProgrammeDataAPI();
      for (let index = 0; index < tempPromoContent?.length; index++) {
        const contentRecord = tempPromoContent[index];
        await publishPromoContent(
          contentRecord?.content_identifier,
          contentRecord?.promo_title,
          userSessionKey,
          contentRecord?.promo_image,
          contentRecord?.promo_content,
          contentRecord?.cta_text,
          contentRecord?.cta_destination,
          contentRecord?.display_type
        );
        await deleteTempPromoContent(contentRecord?.id);
      }
    }
    return 'Resolving Promoted Content';
  };

  const publishNewDesignChanges = async () => {
    const userSessionKey = await callProgrammeDataAPI();
    let results = await fetchTempCardDesigns(userSessionKey);
    if (!results.error) {
      for (let index = 0; index < results?.length; index++) {
        const design = results[index];
        let designOffset =
          design?.default_offsetX + ',' + design?.default_offsetY;
        await publishDesign(
          design?.category_id,
          design?.design_identifier,
          design?.url,
          designOffset,
          design?.default_zoom,
          design?.brand_configuration,
          design?.design_data
        );
        await cancelDesignChange(design?.id);
      }
    }
    return 'Resolving Design Changes';
  };

  const publishContentHubChanges = async () => {
    if (contentQueue) {
      for (let i = 0; i < contentQueue?.length; i++) {
        await addContent(
          contentQueue[i]?.title,
          contentQueue[i].module_type,
          contentQueue[i].module_hex,
          contentQueue[i].headline_image,
          contentQueue[i].module_location
        );
        let contentData = contentQueue[i].content_data;
        for (let index = 0; index < contentData?.length; index++) {
          await addContentData(
            contentData[index].content_id,
            contentData[index].content_image,
            contentData[index].content_title,
            contentData[index].content_destination,
            contentData[index].item_order
          );
          //Add record to
          //erase temp content
        }
      }
    }
  };

  const publishDataHandler = async () => {
    setPublishingInProgress(true);
    await publishBrandConfigurations();
    await publishCategoryChanges();
    await publishPromotContentChanges();
    await publishNewDesignChanges();
    await publishContentHubChanges();

    setPublishingInProgress(false);
    setPublishCategoryChangesModal(false);
    loadData();
    setPublishingComplete(true);
  };

  const publishComplete = () => {
    setPublishingComplete(false);
  };

  //RENDER START
  return (
    <Suspense fallback={<Spinner size='sm' />}>
      <div className='content'>
        {/* Review Summary Header */}
        <ReviewSummary />

        {/* Content Hub Content Changes */}
        <ContentHubChanges
          loading={loading}
          contentQueue={contentQueue}
          callContentQueueAPI={callContentQueueAPI}
          setShowPreview={setShowPreview}
          setPreviewType={setPreviewType}
        />

        {/* Promoted Content */}
        <PromotedContent
          loading={loading}
          tempPromoContent={tempPromoContent}
          callPromotedContentQueueAPI={callPromotedContentQueueAPI}
        />

        {/* Card Brand Configurations */}
        <CardBrandConfigurations
          loading={loading}
          brandConfigurations={brandConfigurations}
          callBrandConfigQueueAPI={callBrandConfigQueueAPI}
        />

        {/* Card Designs */}
        <CardDesigns
          loading={loading}
          tempDesigns={tempDesigns}
          callTempCardDesignsAPI={callTempCardDesignsAPI}
          cardDesigns={cardDesigns}
          tempCategories={tempCategories}
        />

        {/* Category Changes */}
        <CategoryChanges
          loading={loading}
          tempCategories={tempCategories}
          queuedDeletions={queuedDeletions}
          cardDesigns={cardDesigns}
          setTempCategories={setTempCategories}
        />

        {/* Show Content Hub Preview Modal*/}
        <ShowPreviewModal
          showPreview={showPreview}
          setShowPreview={setShowPreview}
        />

        {/* Publish Category Changes Modal*/}
        <PublishCategoryChangesModal
          publishCategoryChangesModal={publishCategoryChangesModal}
          publishingInProgress={publishingInProgress}
          publishDataHandler={publishDataHandler}
          setPublishCategoryChangesModal={setPublishCategoryChangesModal}
        />

        {/* Publishing Complete Modal*/}
        <PublishingCompleteModal
          publishingComplete={publishingComplete}
          publishComplete={publishComplete}
        />

        <div className='row d-flex justify-content-end mr-2'>
          <button
            type='submit'
            className='btn btn-success btn-block'
            onClick={() => setPublishCategoryChangesModal(true)}
            style={{ width: 180 }}
          >
            {t('buttons:btn_publish_changes')}
          </button>
        </div>
      </div>
    </Suspense>
  );
};

export default ReviewChanges;
