function getuserRoleData(user_email) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/user-role`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      user_email: `${user_email}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default getuserRoleData;
