import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, Row, Col, CardHeader, CardBody, Table } from 'reactstrap';
import classnames from 'classnames';
import Loading from '../../components/Loading';

const BottomDesigns = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [reportData] = [props.reportData];
  const [startDate] = [props.startDate];
  const [endDate] = [props.endDate];

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  function renderCardBottomDesigns(BottomIndex) {
    let newData = [];

    if (reportData.length > 0) {
      reportData.forEach(element => {
        element.design_applied = new Date(element.design_applied);
      });
      const dateFiltered = reportData.filter(item => item.design_applied >= startDate && item.design_applied <= endDate);

      const grouped = groupBy(dateFiltered, data => data.design);
      grouped.forEach(element => {
        let newRow = {};
        newRow.design = element[0].design;
        newRow.design_title = element[0].design_title;
        newRow.design_name = element[0].design_name;
        newRow.count = element.length;
        newData.push(newRow);
      });
    }

    newData.sort(function (a, b) { return a.count - b.count });
    if (newData.length > 0) {
      return newData.map((design, index) => {
        if (index < BottomIndex) {
          return (
            <tr key={design.design}>
              <td>{design.design_name}</td>
              <td>{design.design_title}</td>
              <td style={{ textAlign: 'center' }}><strong>{design.count}</strong></td>
              <td style={{ textAlign: 'center' }}><img src={design.design} height={75} alt="card design"/></td>
            </tr>
          )
        }
        else{
          return null;
        }
      })
    }
    else {
      return (<Loading />)
    }
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Bottom 5
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Bottom 10
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Bottom 25
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col md="12">
              {/* <Card> */}
              <CardHeader>
                <CardTitle tag="h4">Bottom 5 Designs</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th>Design Name</th>
                      <th>Design Category</th>
                      <th style={{ textAlign: 'center' }}>Design Usage</th>
                      <th style={{ textAlign: 'center' }}>Design</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCardBottomDesigns(5)}
                  </tbody>
                </Table>
              </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col md="12">
              {/* <Card> */}
              <CardHeader>
                <CardTitle tag="h4">Bottom 10 Designs</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th>Design Name</th>
                      <th>Design Category</th>
                      <th style={{ textAlign: 'center' }}>Design Usage</th>
                      <th style={{ textAlign: 'center' }}>Design</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCardBottomDesigns(10)}
                  </tbody>
                </Table>
              </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col md="12">
              {/* <Card> */}
              <CardHeader>
                <CardTitle tag="h4">Bottom 25 Designs</CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th>Design Name</th>
                      <th>Design Category</th>
                      <th style={{ textAlign: 'center' }}>Design Usage</th>
                      <th style={{ textAlign: 'center' }}>Design</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCardBottomDesigns(25)}
                  </tbody>
                </Table>
              </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default BottomDesigns;