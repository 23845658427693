function updateDesign(design_id, category_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/subdesign-actions`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_id: `${design_id}`,
      category_id: `${category_id}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateDesign;
