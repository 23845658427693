import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Button,
} from 'reactstrap';

const RejectCardDesignModal = ({
  designRejectModal,
  completeDesignRejection,
  setDesignRejectModal,
  checkedDesigns,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons']);

  return (
    <Modal isOpen={designRejectModal} centered={true}>
      <ModalHeader>{t('revchn_reject_card_designs_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              {checkedDesigns?.length > 1
                ? t('revchn_reject_all_changes')
                : t('revchn_reject_changes')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => completeDesignRejection()}>
          {t('buttons:btn_reject')}
        </Button>
        <Button color='success' onClick={() => setDesignRejectModal(false)}>
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RejectCardDesignModal;
