function addImageAnalysisTag(rule_id, rule_tag, session_key) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/image-analysis`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      rule_id: `${rule_id}`,
      rule_tag: `${rule_tag}`,
      session_key: `${session_key}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addImageAnalysisTag;
