import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from 'reactstrap';

const PromoteModal = ({
  promoteModal,
  setPromoteModal,
  newParentData,
  setPromotedCategory,
  promoteCarousel,
}) => {
  const { t } = useTranslation(['new_dnd', 'buttons']);

  return (
    <Modal isOpen={promoteModal} centered={true}>
      <ModalHeader>{t('newdnd_promote_this_carousel_label')}</ModalHeader>
      <ModalBody>
        <div style={{ textAlign: 'center' }}>
          <Label for='CategoryCode'>
            {t('newdnd_which_top_level_category_label')}
          </Label>
          <br></br>
          <select
            name={'parentCategories'}
            id={'parentCategories'}
            onChange={(choice) => setPromotedCategory(choice.target.value)}
          >
            <option value={'select'}>
              {t('newdnd_select_category_label')}
            </option>
            {newParentData?.map((category, idx) => (
              <option value={category.title} key={idx}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='success' onClick={() => promoteCarousel()}>
          {t('buttons:btn_promote_content_up')}
        </Button>
        <Button color='secondary' onClick={() => setPromoteModal(false)}>
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PromoteModal;
