import React from "react";
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactDevicePreview from 'react-device-preview'

const styles = {
    cardStyle: {
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        textAlign: 'center',
        height: 220,
        marginBottom: 5
    },
    imageStyle: {
        height: 150,
        width: 150,
        borderRadius: 4,
        objectFit: 'cover',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        marginBottom: 10
    },
    navImageStyle: {
        height: 100,
        width: 100,
        borderRadius: 4,
        objectFit: 'cover',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 20
    },
    headlineImageStyle: {
        height: 150,
        width: '100%',
        borderRadius: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: -10,
        marginBottom: 10,
        objectFit: 'cover'
    },
    mainContent: {
        marginLeft: 15,
        marginRight: 15
    },
    columnStyle: {
        padding: 5,
        paddingBottom: 0,
        paddingTop: 0
    },
    iconStyle: {
        paddingRight: 15,
        paddingBottom: 10,
        fontWeight: 'bold',
        fontSize: 20
    },
    backIcon: {
        paddingLeft: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        fontSize: 20
    }
}

const renderItems = (items) => {

    if (items) {
        let jsxTree = [];
        const jsxConstructor = (firstItem, secondItem, itemLength) => {
            return (
                <Row>
                    <Col style={styles.columnStyle}>
                        <Card style={styles.cardStyle}>
                            <img src={firstItem.image} style={styles.imageStyle} alt={firstItem.title} />
                            <CardBody>
                                <p>{firstItem.title}</p>
                            </CardBody>
                        </Card>
                    </Col>
                    {secondItem && itemLength > 1 ? <Col style={styles.columnStyle}>
                        <Card style={styles.cardStyle}>
                            <img src={secondItem.image} style={styles.imageStyle} alt={secondItem.title} />
                            <CardBody>
                                <p>{secondItem.title}</p>
                            </CardBody>
                        </Card>
                    </Col> : <Col></Col>}
                </Row>
            );
        }
        for (let index = 0; index < items.length; index = index + 2) {
            jsxTree.push(jsxConstructor(items[index], items[index + 1], items.length));
        }
        return jsxTree;
    }
    else {
        return null;
    }
}

const renderPreview = (props) => {
    if (props.brandType === 'subbrand') {
        return (
            <div style={styles.mainContent}>
                {renderItems(props.contentItems)}
            </div>
        )
    }
    else {
        return (
            <div style={styles.mainContent}>
                <Row>
                    {props.navItems ? props.navItems.map((item, index) => {
                        return (
                            <Col style={styles.columnStyle}>
                                <div>
                                    <img src={item.image} style={styles.navImageStyle} alt={item.title} />
                                    <p style={{ textAlign: 'center' }}>{item.title}</p>
                                </div>
                            </Col>
                        )
                    }) : null}
                </Row>
                <div>
                    <img src={props.headlineItem} style={styles.headlineImageStyle} alt="Headline" />
                </div>
                {renderItems(props.contentItems)}
            </div>
        );
    }
}

const MobilePreview = (props) => {
    return (
        <>
            <div class="d-flex justify-content-end">
                <ReactDevicePreview device="iphonex" scale="1">
                    {/* PREVIEW HEADER */}
                    <div style={{ backgroundColor: props.backgroundColour, height: 85 }}>
                        <Row style={{ paddingTop: 40, color: '#ffffff' }}>
                            <Col>
                                <div id="icons-wrapper" class="d-flex align-items-center">
                                    <i class="nc-icon nc-minimal-left" style={styles.backIcon} />
                                </div>
                            </Col>
                            <Col style={{ textAlign: 'center', fontWeight: 'bold' }}>{props.title}</Col>
                            <Col style={{ textAlign: 'end' }}><i class="nc-icon nc-bell-55" style={styles.iconStyle} /></Col>
                        </Row>
                    </div>
                    {/* PREVIEW CONTENT */}
                    {renderPreview(props)}
                </ReactDevicePreview>
            </div>
        </>
    );
};

export default MobilePreview;
