import { Fragment, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import deleteCategoryChange from 'utils/deleteCategoryChange';
import deleteProgrammeChange from 'utils/deleteProgrammeChange';
import CategoryChangesModal from './Modal/CategoryChangesModal';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { checkNull } from 'utils/validationUtils';

const CategoryChanges = ({
  loading,
  tempCategories,
  queuedDeletions,
  cardDesigns,
  setTempCategories,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons', 'table']);
  const [renderCategoryMerge, setRenderCategoryMerge] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [cancelAllCategoryChangeModal, setCancelAllCategoryChangeModal] =
    useState(false);

  // Category Changes select start
  const selectAllCategories = (e) => {
    if (e.target?.checked) {
      const allCategories = tempCategories
        ?.filter((category) => category?.action !== 'NONE')
        ?.map((category) => category?.id);
      setCheckedCategories(allCategories);
    } else {
      setCheckedCategories([]);
    }
  };

  const toggleCheckCategories = (id, isCheck) => {
    if (isCheck) {
      if (!checkedCategories?.includes(id)) {
        setCheckedCategories([...checkedCategories, id]);
      }
    } else {
      setCheckedCategories(checkedCategories?.filter((item) => item !== id));
    }
  };

  const completeCategoryCancellation = async () => {
    setCancelAllCategoryChangeModal(false);
    setShowCategoryLoader(true);
    for (let i = 0; i < tempCategories?.length; i++) {
      const categoryCancelItem = tempCategories[i];
      if (categoryCancelItem?.submitted) {
        if (categoryCancelItem?.action) {
          if (categoryCancelItem?.action === 'UPDATE') {
            await deleteCategoryChange(categoryCancelItem?.id);
          }
        } else {
          if (categoryCancelItem?.publishAction) {
            if (
              categoryCancelItem?.publishAction?.includes(
                'Remove from Promoted Category'
              )
            ) {
              await deleteProgrammeChange(categoryCancelItem?.id);
            }
          }
        }
      }
    }
    setShowCategoryLoader(false);
  };
  // Category Changes select end

  const renderCategoryChangeData = () => {
    let action = null;
    let newCatData = [];

    if (tempCategories) {
      if (queuedDeletions) {
        if (!renderCategoryMerge) {
          queuedDeletions?.forEach((category) => {
            let newDeletion = cardDesigns[0]?.filter(
              (item) =>
                item?.design_category_id === category?.design_category_id
            );
            if (newDeletion?.length > 0) {
              newDeletion[0].submitted = category?.submitted;
              newDeletion[0].publishAction =
                'Remove from Promoted Category ' +
                newDeletion[0]?.parent_catagory;
              newDeletion[0].id = category.id;
              tempCategories?.push(newDeletion[0]);
            }
          });
          setRenderCategoryMerge(true);
        }
      }
    } else {
      if (queuedDeletions) {
        if (!renderCategoryMerge) {
          queuedDeletions?.forEach((category) => {
            let newDeletion = cardDesigns[0]?.filter(
              (item) =>
                item?.design_category_id === category?.design_category_id
            );
            if (newDeletion?.length > 0) {
              newDeletion[0].submitted = category.submitted;
              newDeletion[0].publishAction =
                'Remove from Promoted Category ' +
                newDeletion[0]?.parent_catagory;
              newDeletion[0].id = category.id;
              newCatData.push(newDeletion[0]);
            }
          });
          setRenderCategoryMerge(true);
          setTempCategories(newCatData);
        }
      }
    }

    if (tempCategories) {
      return tempCategories?.map((category, index) => {
        let catReference = cardDesigns[0]?.filter(
          (item) => item?.design_category_id === category?.parent_category
        );
        if (catReference?.length > 0) {
          catReference = catReference[0]?.title;
        } else {
          catReference = null;
        }
        if (category?.submitted) {
          if (category?.parent_category === 0) {
            action = 'Add New Category';
          } else {
            action = 'Add Category to Promoted Category ' + catReference;
          }
          if (category?.publishAction) {
            let publishAction = category?.publishAction;
            if (publishAction?.includes('Remove from Promoted Category')) {
              action = category?.publishAction;
            }
          }
          return (
            <tr key={index}>
              <td>
                {category.action === 'NONE' ? (
                  <input type='checkbox' id={category?.id} disabled />
                ) : (
                  <input
                    type='checkbox'
                    id={category?.id}
                    checked={checkedCategories?.includes(category?.id)}
                    onChange={(e) =>
                      toggleCheckCategories(category?.id, e.target?.checked)
                    }
                  />
                )}
              </td>
              <td>{checkNull(category?.id)}</td>
              <td>{checkNull(category?.title)}</td>
              <td>{checkNull(category?.description)}</td>
              {category?.parent_category === 0 ? (
                <td>{t('table:tb_general')}</td>
              ) : (
                <td>{t('table:tb_promoted')}</td>
              )}
              <td>{checkNull(action)}</td>
            </tr>
          );
        } else return null;
      });
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('revchn_category_changes_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllCategories'
                    checked={
                      checkedCategories?.length === tempCategories?.length &&
                      tempCategories?.length > 0
                    }
                    disabled={tempCategories?.length === 0}
                    onChange={selectAllCategories}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_category_name')}</th>
                <th>{t('table:tb_category_description')}</th>
                <th>{t('table:tb_category_type')}</th>
                <th>{t('table:tb_publish_action')}</th>
              </tr>
            </thead>
            {loading && !tempCategories ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{renderCategoryChangeData()}</tbody>
            )}
          </Table>
          {tempCategories?.length > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setCancelAllCategoryChangeModal(true)}
                disabled={checkedCategories?.length === 0 || showCategoryLoader}
                style={{ width: 150 }}
              >
                {t('buttons:btn_cancel')}
                {checkedCategories?.length > 1 && ' ALL'}
                {showCategoryLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <CategoryChangesModal
        cancelAllCategoryChangeModal={cancelAllCategoryChangeModal}
        completeCategoryCancellation={completeCategoryCancellation}
        setCancelAllCategoryChangeModal={completeCategoryCancellation}
        checkedCategories={checkedCategories}
      />
    </Fragment>
  );
};

export default CategoryChanges;
