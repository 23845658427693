function publishNewCategory(
  parent_catagory_id,
  category_code,
  title,
  category_description
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/category-publish`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      parent_catagory_id: parent_catagory_id,
      category_code: category_code,
      title: title,
      category_description: category_description,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default publishNewCategory;
