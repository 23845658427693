import MobilePreview from 'components/MobilePreview';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const ShowPreviewModal = ({ showPreview, setShowPreview }) => {
  return (
    <Modal isOpen={showPreview} centered={true}>
      <ModalHeader
        style={{
          justifyContent: 'flex-end',
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Button color='danger' size='sm' onClick={() => setShowPreview(false)}>
          X
        </Button>
      </ModalHeader>
      <ModalBody>
        <MobilePreview />
      </ModalBody>
    </Modal>
  );
};

export default ShowPreviewModal;
