function updateImagePropsQueue(
  design_id,
  default_offset,
  default_zoom,
  brand_config
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/image-props-queue`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_id: `${design_id}`,
      default_offset: `${default_offset}`,
      default_zoom: `${default_zoom}`,
      brand_config: `${brand_config}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateImagePropsQueue;
