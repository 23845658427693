import React from 'react';
import { Card, CardTitle, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { categoryPieChart } from 'variables/yimbaChart';
import { Pie } from 'react-chartjs-2';

const CategoryUsage = (props) => {
  const [reportData] = [props.reportData];
  const [startDate] = [props.startDate];
  const [endDate] = [props.endDate];
  const chartColors = ['#e3e3e3', '#4acccd', '#fcc468', '#ef8157', '#51bcda'];

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  function createPieDataset() {
    const newData = [];
    const chartData = [];
    const chartValues = [];

    if (reportData.length > 0) {
      reportData.forEach((element) => {
        element.design_applied = new Date(element.design_applied);
      });
      const dateFiltered = reportData.filter(
        (item) =>
          item.design_applied >= startDate && item.design_applied <= endDate
      );

      dateFiltered.forEach((element) => {
        let newRow = {};
        newRow.design_category = element.design_title;
        newData.push(newRow);
      });

      const groupedbyDesign = groupBy(newData, (data) => data.design_category);
      groupedbyDesign.forEach((design) => {
        let newRow = {};
        newRow.design = design[0].design_category;
        newRow.value = design.length;
        chartData.push(newRow);
      });
      chartData.sort(function (b, a) {
        return a.value - b.value;
      });

      chartData.forEach((element, index) => {
        if (index < 5) {
          chartValues.push(element.value);
        }
      });
      categoryPieChart.data.datasets[0].data = chartValues;
    }
    return chartData;
  }

  const chartData = createPieDataset();
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5'>Design Category Usage</CardTitle>
        <p className='card-category'>Popularity of Categories</p>
      </CardHeader>
      <CardBody>
        <Pie
          data={categoryPieChart.data}
          options={categoryPieChart.options}
          height={150}
        />
      </CardBody>
      <CardFooter>
        <div className='legend'>
          {chartData.map((item, index) => {
            if (index < 5) {
              return (
                <p key={index}>
                  <i
                    className='fa fa-circle'
                    style={{ color: chartColors[index] }}
                  />{' '}
                  {item.design}{' '}
                </p>
              );
            } else {
              return null;
            }
          })}
        </div>
      </CardFooter>
    </Card>
  );
};

export default CategoryUsage;
