function getSecurityToken(email, password){
    return fetch('https://security.yimbatech.com/apitoken', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: `{"email":"${email}",
        "password":"${password}",
        "returnSecureToken":"true"}`,
    })
    .then((response)=>response.json())
    .then((responseJSON)=>{
        return responseJSON.idToken;
    })
}

export default getSecurityToken;