import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

const UploadImagesModal = ({
  uploadImagesModal,
  setUploadImagesModal,
  selectedDropDown,
  setSelectedDropDown,
  addedCategory,
  categoryData,
  tempCategories,
  beginUpload,
  addCategoryLoader,
  setAddCategoryModal,
}) => {
  const { t } = useTranslation('card_designs', 'button');
  const [usageRights, setUsageRights] = useState(true);

  useEffect(() => {
    if (uploadImagesModal) {
      setUsageRights(true);
      setSelectedDropDown('select');
    }
  }, [uploadImagesModal]);

  const renderAddToCarousel = () => {
    const newColumnData = [];

    if (categoryData) {
      categoryData?.forEach((record) => {
        newColumnData.push(record);
      });

      if (tempCategories) {
        tempCategories?.forEach((category) => {
          if (category.parent_category != null) {
            category.temp = true;
            newColumnData.push(category);
          }
        });
      }

      if (newColumnData?.length > 0) {
        return newColumnData?.map((category, index) => {
          return (
            <option value={category?.title} key={index}>
              {category?.title}
            </option>
          );
        });
      }
    }
  };

  return (
    <Modal isOpen={uploadImagesModal} centered={true}>
      <ModalHeader>{t('cdsgn_categorise_images_label')}</ModalHeader>
      <ModalBody>
        <Form style={{ textAlign: 'center' }}>
          <FormGroup>
            <Label for='carouselCategory'>
              {t(
                'cdsgn_select_a_carousel_category_below_to_add_the_images_to_label'
              )}
            </Label>
            <select
              name='carousel'
              id='carousel'
              onChange={(choice) => setSelectedDropDown(choice.target.value)}
              value={addedCategory}
            >
              <option key={'select'} value={'select'}>
                {t('cdsgn_select_category_label')}
              </option>
              {renderAddToCarousel()}
            </select>
          </FormGroup>
          <FormGroup>
            <Input
              type='checkbox'
              onChange={() =>
                usageRights ? setUsageRights(false) : setUsageRights(true)
              }
            />
            <Label check>
              <span>
                {t(
                  'cdsgn_confirm_that_you_have_the_rights_to_use_the_selected_images_label'
                )}
              </span>
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className='d-flex'>
          <Button
            disabled={usageRights || selectedDropDown === 'select'}
            color='success mr-1'
            onClick={() => beginUpload()}
          >
            {t('buttons:btn_upload_images')}
          </Button>
          <Button
            color='warning mr-1'
            onClick={() => setAddCategoryModal(true)}
            disabled={addCategoryLoader}
          >
            {t('buttons:btn_add_new_category')}
            {addCategoryLoader && (
              <Spinner size='sm' style={{ marginLeft: '8px' }} />
            )}
          </Button>
          <Button color='danger' onClick={() => setUploadImagesModal(false)}>
            {t('buttons:btn_cancel')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default UploadImagesModal;
