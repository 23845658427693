import React, { useState, useReducer } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, Input, FormGroup, Button} from "reactstrap";
import getCustomMessageQueue from 'utils/getCustomMessageQueue';
import fetchClientKey from 'utils/fetchClientKey';
import fetchProgrammeData from 'utils/fetchProgrammeData';
import Loading from '../components/Loading';
import { useAuth0 } from "../react-auth0-spa";
import setCustomMessage from 'utils/setCustomMessage';
import deleteTempCustomMessage from 'utils/deleteTempCustomMessage';

export default function CustomMessages() {
    const [showModal, setShowModal] = useState(false);
    const { user } = useAuth0();
    const [pageState, setPageState] = useReducer((pageState, newPageState) =>
        ({ ...pageState, ...newPageState }),
        {
            cardProgramme: null, userSessionKey: null, customMessages: {}, loading: true,
            messageTitle: null, messageContent: null, deleteCustomMessage: false, savingContent: false,
            selectedItem: null,
        }
    );

    function loadData() {
        fetchClientKey(user.email).then((keyinfo) => {
            fetchProgrammeData(keyinfo.client_access_key).then((results) => {
                getCustomMessageQueue(results.programme_identifier).then((CustomMessages) => {
                    setPageState({ cardProgramme: results.id, userSessionKey: results.programme_identifier, customMessages: CustomMessages, loading: false })
                })
            });
        });
    }

    function saveNewMessage() {
        setPageState({ savingContent: true });
        setCustomMessage(pageState.messageTitle, pageState.messageContent, pageState.userSessionKey).then((result) => {
            setShowModal(false);
            setPageState({ loading: true, savingContent:false });
        })
    }

    function deleteItem(itemID) {
        deleteTempCustomMessage(itemID).then((res) => {
            setPageState({ loading: true, deleteCustomMessage: false });
        })
    }

    function renderCustomMessageData() {
        if (pageState.customMessages.length > 0) {
            return pageState.customMessages.map((messages, index) => {
                let messageDate = new Date(messages.message_created).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                return (
                    <tr key={messages.id}>
                        <td>{messages.id}</td>
                        <td>{messages.message_title}</td>
                        <td>{messages.message_content}</td>
                        <td>{messageDate}</td>
                        <td className="text-success">{messages.message_status}</td>
                        <td style={{ textAlign: 'center' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#ffffff' }} onClick={() => setPageState({ selectedItem: messages.id, deleteCustomMessage: true })}><i className="fa fa-trash text-danger" /></button></td>
                    </tr>
                )
            })
        }
        else {
            return (null);
        }
    }

    if (pageState.loading) {
        loadData();
        return (
            <div className="content">
                <Loading />
            </div>)
    }
    else {
        return (
            <>
                <div className="content">
                    <Modal isOpen={showModal} centered={true} >
                        <ModalHeader>Create Custom Message</ModalHeader>
                        <ModalBody>
                            <Form style={{ textAlign: 'left' }}>
                                <FormGroup>
                                    <Label for="CategoryCode">Custom Message Title</Label>
                                    <Input placeholder="Card art update success" name="text" id="text" onChange={(e)=>setPageState({ messageTitle: e.target.value })}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode">Custom Message Text</Label>
                                    <Input placeholder="Thanks for choosing this awesome design. Why not checkout additional content below" name="text" id="text" onChange={(e)=>setPageState({ messageContent: e.target.value })} />
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {pageState.savingContent ? <Button color="secondary">Saving...</Button> : <Button color="success" onClick={() => saveNewMessage()}>Save</Button>}
                            <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={pageState.deleteCustomMessage} centered={true} >
                        <ModalHeader >Remove Custom Message</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label>Are you sure you wish to remove this Custom Message?</Label>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => deleteItem(pageState.selectedItem)}>Yes</Button>
                            <Button color="secondary" onClick={() => setPageState({ deleteCustomMessage: false })}>No</Button>
                        </ModalFooter>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    {/* <CardTitle tag="h4">Custom Messages</CardTitle> */}
                                    <button type="submit" className="btn btn-primary" onClick={() => setShowModal(true)}>{'New Custom Message'}</button>
                                </CardHeader>
                                <CardBody>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Message Title</th>
                                                <th>Message Text</th>
                                                <th>Created</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderCustomMessageData()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}