
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import AdminRoutes from "routes/AdminRoutes";
import AnalyticsRoutes from "routes/AnalyticsRoutes";
import ContentRoutes from "routes/ContentRoutes";
import ReviewRoutes from "routes/ReviewRoutes";
import getuserRoleData from "utils/getUserRoleData";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      userMetadata: null,
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  renderRoutes() {
    let userRoutes = null;
    const userMetadata = this.state.userMetadata;

    if (userMetadata) {
      if (userMetadata.role_description === 'Content Editor') {
        userRoutes = ContentRoutes;
      }
      if (userMetadata.role_description === 'Content Reviewer') {
        userRoutes = ReviewRoutes;
      }
      if (userMetadata.role_description === 'Analytics Access') {
        userRoutes = AnalyticsRoutes;
      }
      if (userMetadata.role_description === 'SuperAdmin') {
        userRoutes = AdminRoutes;
      }
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={userRoutes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref={this.mainPanel}>
            <DemoNavbar {...this.props} />
            <Switch>
              {userRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
            {/* <Footer fluid /> */}
          </div>
          {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
          /> */}
        </div>
      );
    }
    else {
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={AnalyticsRoutes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
          <div className="main-panel" ref={this.mainPanel}>
            <DemoNavbar {...this.props} />
            <Switch>
              {/* {ContentRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })} */}
            </Switch>
            {/* <Footer fluid /> */}
          </div>
          {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
          /> */}
        </div>
      );
    }
  }
  render() {
    if (!this.state.userMetadata) {
      if(this.props.userProfile){
        getuserRoleData(this.props.userProfile.email).then((metadata) => {
          let userMetadata = metadata[0];
          this.setState({ userMetadata: userMetadata });
        });
      }
    }

    return (
      this.renderRoutes()
    )
  }
}

export default Dashboard;
