function createCompositeImage(
  design_id,
  core_image,
  brand_image,
  network_image,
  category,
  order_id,
  card_programme_id,
  design_queue_mode
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/composite-images`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_id: `${design_id}`,
      core_image: `${core_image}`,
      brand_image: `${brand_image}`,
      network_image: `${network_image}`,
      category: `${category}`,
      order_id: `${order_id}`,
      card_programme_id: `${card_programme_id}`,
      design_queue_mode: `${design_queue_mode}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default createCompositeImage;
