export const checkNull = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === 'undefined' ||
    value === 'null' ||
    value?.length === 0
  ) {
    return '-';
  }
  return value;
};
