import { Fragment, useState } from 'react';
import Img from 'react-cool-img';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import deleteTempPromoContent from 'utils/deleteTempPromoContent';
import PromotedContentModal from './Modal/PromotedContentModal';
import Loading from 'components/Loading';
import { checkNull } from 'utils/validationUtils';
import { useTranslation } from 'react-i18next';

const PromotedContent = ({
  loading,
  tempPromoContent,
  callPromotedContentQueueAPI,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons', 'table']);
  const [promotedCancelModal, setPromotedCancelModal] = useState(false);
  const [checkedPromotedContent, setCheckedPromotedContent] = useState([]);
  const [showPromotedLoader, setShowPromotedLoader] = useState(false);

  // Promoted Content select start
  const selectAllPromoted = (e) => {
    if (e.target.checked) {
      const allPromoted = tempPromoContent?.map((category) => category.id);
      setCheckedPromotedContent(allPromoted);
    } else {
      setCheckedPromotedContent([]);
    }
  };

  const toggleCheckPromoted = (id, isCheck) => {
    if (isCheck) {
      if (!checkedPromotedContent?.includes(id)) {
        setCheckedPromotedContent([...checkedPromotedContent, id]);
      }
    } else {
      setCheckedPromotedContent(
        checkedPromotedContent?.filter((item) => item !== id)
      );
    }
  };

  const completePromotedCancellation = async () => {
    setPromotedCancelModal(false);
    setShowPromotedLoader(true);
    for (let i = 0; i < checkedPromotedContent?.length; i++) {
      await deleteTempPromoContent(checkedPromotedContent[i]);
    }
    await callPromotedContentQueueAPI();
    setCheckedPromotedContent([]);
    setShowPromotedLoader(false);
  };
  // Promoted Content select end

  const renderData = () => {
    if (tempPromoContent) {
      return tempPromoContent?.map((content, index) => {
        return (
          <tr key={content.id}>
            <td>
              <input
                type='checkbox'
                id={content.id}
                checked={checkedPromotedContent?.includes(content.id)}
                onChange={(e) =>
                  toggleCheckPromoted(content.id, e.target.checked)
                }
              />
            </td>
            <td>{checkNull(content.id)}</td>
            <td>
              <Img
                src={content.promo_image}
                cache={true}
                alt='Card Image'
                style={{ height: 70, width: 100 }}
              />
            </td>
            <td>{checkNull(content.promo_title)}</td>
            <td>{checkNull(content.display_type)}</td>
            <td className='text-success'>
              <strong>{checkNull(content.status)}</strong>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('revchn_promoted_content_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllPromoted'
                    checked={
                      checkedPromotedContent?.length ===
                        tempPromoContent?.length && tempPromoContent?.length > 0
                    }
                    onChange={selectAllPromoted}
                    disabled={tempPromoContent?.length === 0}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_preview')}</th>
                <th>{t('table:tb_content_name')}</th>
                <th>{t('table:tb_displayed_in')}</th>
                <th>{t('table:tb_status')}</th>
              </tr>
            </thead>
            {loading && !tempPromoContent ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{renderData()}</tbody>
            )}
          </Table>
          {tempPromoContent?.length > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <Button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setPromotedCancelModal(true)}
                disabled={
                  checkedPromotedContent?.length === 0 || showPromotedLoader
                }
                style={{ width: 150 }}
              >
                {t('buttons:btn_cancel')}
                {checkedPromotedContent?.length > 1 && ' ALL'}
                {showPromotedLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      <PromotedContentModal
        promotedCancelModal={promotedCancelModal}
        setPromotedCancelModal={setPromotedCancelModal}
        completePromotedCancellation={completePromotedCancellation}
        checkedPromotedContent={checkedPromotedContent}
      />
    </Fragment>
  );
};

export default PromotedContent;
