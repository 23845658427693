function publishPromoContent(
  content_identifier,
  promo_title,
  programme_id,
  promo_image,
  promo_content,
  cta_text,
  cta_destination,
  display_type
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/promoted-content-queue`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      content_identifier: `${content_identifier}`,
      promo_title: `${promo_title}`,
      card_programme: `${programme_id}`,
      promo_image: `${promo_image}`,
      promo_content: `${promo_content}`,
      cta_text: `${cta_text}`,
      cta_destination: `${cta_destination}`,
      display_type: `${display_type}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default publishPromoContent;
