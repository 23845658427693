import ImageTools from 'components/ImageTools';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

let imageObject = {
  url: 'https://yimba.com/wp-content/uploads/2020/03/social-group@2x-scaled.jpg',
};

const ApplyCardBrandingModal = ({
  saveGlobalBranding,
  setEditModal,
  editModal,
  cardProgramme,
  setSelectedBrandConfig,
  selectedBrandConfig,
  setSubmitRequest,
  imageTools,
}) => {
  const { t } = useTranslation([
    'card_designs',
    'img_tools',
    'buttons',
    'validation',
  ]);

  return (
    <Modal
      isOpen={editModal}
      centered={true}
      size='lg'
      style={{ maxWidth: '95%', width: '95%' }}
    >
      <ModalHeader>{t('cdsgn_edit_card_brand_and_overlays_label')}</ModalHeader>
      <ModalBody style={{ backgroundColor: '#f4f3ef' }}>
        <ImageTools
          t={t}
          item={imageObject}
          globalMode={true}
          downloadMode={false}
          programme_id={cardProgramme}
          setSubmitRequest={setSubmitRequest}
          handleSubmit={saveGlobalBranding}
          setModal={setEditModal}
          imageTools={imageTools}
          selectedConfig={setSelectedBrandConfig}
          selectedBrandConfig={selectedBrandConfig}
        />
      </ModalBody>
    </Modal>
  );
};
export default ApplyCardBrandingModal;
