const designsApplied = {
  data: {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        data: [0, 1900, 1500, 2000, 3000],
        fill: false,
        borderColor: '#6bd098',
        backgroundColor: 'transparent',
        pointBorderColor: '#00aa00',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8,
      },
    ],
  },
  options: {
    legend: {
      display: false,
      position: 'top',
    },
  },
};

const categoryPieChart = {
  data: {
    labels: [1, 2, 3],
    datasets: [
      {
        label: 'Emails',
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: [
          '#e3e3e3',
          '#4acccd',
          '#fcc468',
          '#ef8157',
          '#51bcda',
        ],
        borderWidth: 0,
        data: [342, 480, 530, 120, 222],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },

    pieceLabel: {
      render: 'percentage',
      fontColor: ['white'],
      precision: 2,
    },

    tooltips: {
      enabled: false,
    },

    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: 'transparent',
            color: 'rgba(255,255,255,0.05)',
          },
        },
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(255,255,255,0.1)',
            zeroLineColor: 'transparent',
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

module.exports = {
  designsApplied,
  categoryPieChart,
};
