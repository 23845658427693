function deleteTempCustomMessage(itemID) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/custom-messages`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      message_id: `${itemID}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default deleteTempCustomMessage;
