function fetchDesignDeleteQueue(session_key) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/design-actions`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      session_key: `${session_key}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default fetchDesignDeleteQueue;
