function addImageAnalysisRule(rule_name, session_key) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/image-analysis`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      rule_name: `${rule_name}`,
      session_key: `${session_key}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addImageAnalysisRule;
