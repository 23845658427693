import React, { useState, useReducer } from "react";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, Input, FormGroup, Button } from "reactstrap";
import fetchClientKey from 'utils/fetchClientKey';
import fetchProgrammeData from 'utils/fetchProgrammeData';
import Loading from '../components/Loading';
import { useAuth0 } from "../react-auth0-spa";
import FileBase64 from 'react-file-base64'
import getBrandConfigurations from "utils/getBrandConfigurations";
import getBrandConfigQueue from "utils/getBrandConfigQueue";
import addBrandConfiguration from "utils/addBrandConfiguration";
import uploadImage from "utils/uploadImage";
import deleteBrandConfigQueue from "utils/deleteBrandConfigQueue";
import deleteBrandConfig from "utils/deleteBrandConfig";
import { checkNull } from "utils/validationUtils";

export default function BrandApplication() {
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLiveModal, setDeleteLiveModal] = useState(false);
    const [configID, setConfigID] = useState(null);
    const { user } = useAuth0();
    const [pageState, setPageState] = useReducer((pageState, newPageState) =>
        ({ ...pageState, ...newPageState }),
        {
            cardProgramme: null, userSessionKey: null, customMessages: {}, loading: true,
            messageTitle: null, messageContent: null, deleteCustomMessage: false, savingContent: false,
            selectedItem: null, imageTags: null, cardBrand: null, logoPosition: 'left', subbrandLogoPosition: 'left', brandConfigurations: null,
            brandLogo: null, networkLogo: null, subbrandLogo: null, brandName: null, brandPosition: null, brandNetwork: null
        }
    );

    const loadData = () => {
        fetchClientKey(user.email).then((keyinfo) => {
            fetchProgrammeData(keyinfo.client_access_key).then((results) => {
                getBrandConfigurations(results.id).then((brandConfigs) => {
                    getBrandConfigQueue(results.programme_identifier).then((brandConfigQueue) => {
                        let configs = [brandConfigs];
                        let configsQueue = [brandConfigQueue];
                        setPageState({ cardProgramme: results.id, userSessionKey: results.programme_identifier, loading: false, brandConfigurations: [...configs, ...configsQueue] })
                    })
                })
            });
        });
    }

    const _deleteBrandConfig = (itemID, itemType) => {
        if (itemType === 'Queue') {
            deleteBrandConfigQueue(itemID).then((result) => {
                setPageState({ loading: true })
                setDeleteModal(false);
            })
        }
        else {
            deleteBrandConfig(itemID).then((result) => {
                setPageState({ loading: true })
                setDeleteLiveModal(false);
            })
        }
    }

    const process_Brand_Data = () => {
        if (pageState.brandConfigurations.length > 0) {
            let flatData = pageState.brandConfigurations.flat();
            return flatData.map((config, index) => {
                if (!config?.error) {
                    return (
                        <tr key={index}>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.id}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.brand_configuration_name}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}><img src={config?.brand_logo} style={{ height: 50 }} alt="brand logo" /></td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.brand_logo_position}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.brand_sub_logo && <img src={config?.brand_sub_logo} style={{ height: 50 }} alt="brand logo" />}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{checkNull(config?.brand_sub_logo_position)}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}><img src={config?.brand_network_logo} style={{ height: 50 }} alt="network logo" /></td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.brand_network}</td>
                            <td style={{ backgroundColor: '#f4f3ef' }}>{config?.status}</td>
                            {config?.status === 'LIVE' ? <td style={{ textAlign: 'center', backgroundColor: '#f4f3ef' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#f4f3ef' }} onClick={() => { setDeleteLiveModal(true); setConfigID(config?.id) }}><i className="fa fa-trash text-danger" /></button></td> : <td style={{ textAlign: 'center', backgroundColor: '#f4f3ef' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#f4f3ef' }} onClick={() => { setDeleteModal(true); setConfigID(config?.id) }}><i className="fa fa-trash text-danger" /></button></td>}
                        </tr>
                    )
                }
                else {
                    return null;
                }
            })
        }
    }

    const getFiles = (type, files) => {
        if (type === 'brand') {
            setPageState({ brandLogo: files.base64 })
        } else if (type === 'subbrand') {
            setPageState({ subbrandLogo: files.base64 })
        } else {
            setPageState({ networkLogo: files.base64 })
        }
    }

    const createNewBrandConfig = async () => {
        let brandFileData = pageState.brandLogo.split(',');
        let networkFileData = pageState.networkLogo.split(',');

        let brandUpload = await beginUpload(brandFileData[1], brandFileData[0]);
        let networkUpload = await beginUpload(networkFileData[1], networkFileData[0]);

        let sublogoLocation = '';
        let sublogoPosition = '';

        if (pageState.subbrandLogo) {
            let subbrandFileData = pageState.subbrandLogo.split(',');
            let subbrandUpload = await beginUpload(subbrandFileData[1], subbrandFileData[0]);
            sublogoLocation = subbrandUpload.Location;
            sublogoPosition = pageState.subbrandLogoPosition;
        }

        addBrandConfiguration(pageState.cardProgramme, pageState.brandName, brandUpload.Location, pageState.logoPosition, networkUpload.Location, pageState.brandNetwork, pageState.userSessionKey, sublogoLocation, sublogoPosition).then((res) => {
            setShowModal(false);
            setPageState({ loading: true });
        });
    }

    const uuid = () => {
        const url = URL.createObjectURL(new Blob())
        const [id] = url.toString().split('/').reverse()
        URL.revokeObjectURL(url)
        return id
    }

    const beginUpload = (base64Data, fileType) => {
        let newUUID = uuid();
        if (fileType === 'data:image/jpeg;base64') {
            return uploadImage(base64Data, newUUID + '.jpg')
        }
        if (fileType === 'data:image/png;base64') {
            return uploadImage(base64Data, newUUID + '.png')
        }
    }

    if (pageState.loading) {
        loadData();
        return (
            <div className="content">
                <Loading />
            </div>)
    }
    else {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Brand Configuration Tools</CardTitle>
                                    <p className="card-category">
                                        This section allows you to manage automated network branding application to your card artwork assets. The tool will enable you to create branding configurations such as issuer logos, network logos and brand logo placement. Images can then be downloaded and used in tools such as VCMM and MDES.
                                    </p>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Brand Configurations</CardTitle>
                                    <button type="submit" className="btn btn-primary" onClick={() => setShowModal(true)}>{'New Brand Configuration'}</button>
                                </CardHeader>
                                <CardBody>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Brand Configuration Name</th>
                                                <th>Brand Logo</th>
                                                <th>Logo Position</th>
                                                <th>Sub-brand Logo</th>
                                                <th>Sub-brand Position</th>
                                                <th>Network Logo</th>
                                                <th>Network</th>
                                                <th>Status</th>
                                                <th style={{ textAlign: 'center' }}>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {process_Brand_Data()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={showModal} centered={true} >
                        <ModalHeader >Add New Brand Configuration</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Brand Configuration Name</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input name="brandName" id="brandName" onChange={(e) => setPageState({ brandName: e.target.value })} />
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Brand Logo</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <FileBase64
                                            multiple={false}
                                            onDone={getFiles.bind(this, 'brand')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <img src={pageState.brandLogo} style={{ height: 40 }} alt="brand logo" />
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label style={{ fontWeight: 'bold' }}>Brand Logo Position</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={(e) => setPageState({ logoPosition: 'left' })} />{'Left'}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={(e) => setPageState({ logoPosition: 'right' })} />{'Right'}
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Sub Brand Logo</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <FileBase64
                                            multiple={false}
                                            onDone={getFiles.bind(this, 'subbrand')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <img src={pageState.subbrandLogo} style={{ height: 40 }} alt="Subbrand logo" />
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label style={{ fontWeight: 'bold' }}>Sub Brand Logo</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio2" onChange={(e) => setPageState({ subbrandLogoPosition: 'left' })} />{'Left'}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio2" onChange={(e) => setPageState({ subbrandLogoPosition: 'right' })} />{'Right'}
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Network Logo</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <FileBase64
                                            multiple={false}
                                            onDone={getFiles.bind(this, 'network')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <img src={pageState.networkLogo} style={{ height: 40 }} alt="brand logo" />
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                            <Form>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label style={{ fontWeight: 'bold' }}>Network</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={(e) => setPageState({ brandNetwork: 'Mastercard' })} />{'Mastercard'}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={(e) => setPageState({ brandNetwork: 'Visa' })} />{'Visa'}
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => createNewBrandConfig()}>Save</Button>
                            <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={deleteLiveModal} centered={true} >
                        <ModalHeader >Remove Item</ModalHeader>
                        <ModalBody>
                            <Form>
                                <Label for="CategoryCode">Are you sure you wish to remove this Brand Configuration Profile?</Label>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => _deleteBrandConfig(configID, 'Live')}>Delete</Button>
                            <Button color="secondary" onClick={() => setDeleteLiveModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={deleteModal} centered={true} >
                        <ModalHeader >Remove Item</ModalHeader>
                        <ModalBody>
                            <Form>
                                <Label for="CategoryCode">Are you sure you wish to remove this Brand Configuration Profile?</Label>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => _deleteBrandConfig(configID, 'Queue')}>Delete</Button>
                            <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}