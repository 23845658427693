function updateLiveDesignStatus(design_status, design_identifier) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/design-changes`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_status: `${design_status}`,
      design_identifier: `${design_identifier}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateLiveDesignStatus;
