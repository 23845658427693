import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import { designsApplied } from 'variables/yimbaChart';
import { useAuth0 } from '../react-auth0-spa';
import fetchClientKey from '../utils/fetchClientKey';
import getSecurityToken from '../utils/getSecurityToken';
import getPersonalisationHistory from '../utils/getPersonalisationHistory';
import getProgrammeCards from '../utils/getProgrammeCards';
import getCardDesignInfo from '../utils/getCardDesignInfo';
import Loading from '../components/Loading';
import TopDesigns from '../components/DataAnalyticsTools/TopDesignTabs';
import BottomDesigns from '../components/DataAnalyticsTools/BottomDesignTabs';
import CategoryUsage from '../components/DataAnalyticsTools/CategoryUsage';
import TopCardHolders from '../components/DataAnalyticsTools/TopCardHolders';
import getAvailableBins from '../utils/getAvailableBins';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Dashboard() {
  const { user } = useAuth0();
  const [reportData, setReportData] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clientBins, setClientBins] = useState(null);
  const [programmeCards, setProgrammeCards] = useState(null);
  const [cardDesigns, setCardDesigns] = useState(null);
  const [startDate, setStartDate] = useState(new Date('01/01/2020'));
  const [endDate, setEndDate] = useState(new Date());
  const month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  function setTimeSlicer(value) {
    if (value === '7 Days') {
      let newStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(newStartDate);
    }
    if (value === '14 Days') {
      let newStartDate = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
      setStartDate(newStartDate);
    }
    if (value === '28 Days') {
      let newStartDate = new Date(Date.now() - 28 * 24 * 60 * 60 * 1000);
      setStartDate(newStartDate);
    }
    if (value === '90 Days') {
      let newStartDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
      setStartDate(newStartDate);
    }
    if (value === 'All') {
      let newStartDate = new Date('01/01/2020');
      setStartDate(newStartDate);
    }
  }

  function filterBinData(selectedBin) {
    let fullCardData = null;
    let fullCardDesigns = null;
    let fullHistoryData = null;

    fetchClientKey(user.email).then((res) => {
      getProgrammeCards(res.client_access_key, token).then((cards) => {
        getCardDesignInfo(res.client_access_key, token).then((designs) => {
          getPersonalisationHistory(res.client_access_key, token).then(
            (data) => {
              fullCardDesigns = designs;
              fullCardData = cards;
              fullHistoryData = data;
              if (selectedBin === 'All Bins') {
                setProgrammeCards(fullCardData);
                setCardDesigns(designs);
                setReportData(data);
              } else {
                const binFilter = parseInt(selectedBin);
                const newCardData = fullCardData.filter(
                  (item) => item.bin_number === binFilter
                );
                setProgrammeCards(newCardData);

                let associatedCardProgramme = clientBins.filter(
                  (item) => item.bin_number === binFilter
                );
                const newDesignData = fullCardDesigns.categories.filter(
                  (item) =>
                    item.programme_id ===
                    associatedCardProgramme[0].programme_id
                );
                setCardDesigns(newDesignData);

                const newHistoryData = fullHistoryData.filter(
                  (item) =>
                    item.programme_id ===
                    associatedCardProgramme[0].programme_id
                );
                setReportData(newHistoryData);
              }
            }
          );
        });
      });
    });
  }

  function createChartDataset() {
    const newData = [];
    const chartData = [];

    if (reportData.length > 0) {
      reportData.forEach((element) => {
        element.design_applied = new Date(element.design_applied);
      });
      const dateFiltered = reportData.filter(
        (item) =>
          item.design_applied >= startDate && item.design_applied <= endDate
      );

      dateFiltered.forEach((element) => {
        let newRow = {};
        newRow.design_applied = element.design_applied;
        var newDate = new Date(element.design_applied);
        newRow.month_applied = month[newDate.getMonth()];
        newData.push(newRow);
      });

      const groupedbyDate = groupBy(newData, (data) => data.month_applied);
      month.forEach((record) => {
        var thisValue = groupedbyDate.get(record);
        if (thisValue) {
          chartData.push(thisValue.length);
        } else {
          chartData.push(0);
        }
      });

      designsApplied.data.datasets[0].data = chartData;
    }
  }

  function getTotalDesigns() {
    let count = 0;
    if (cardDesigns?.categories?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      cardDesigns?.categories?.forEach((design) => {
        if (design?.parent_catagory) {
          count += design?.designs?.length;
        }
      });
    } else {
      if (cardDesigns?.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        cardDesigns?.forEach((design) => {
          if (design?.parent_catagory) {
            count += design?.designs?.length;
          }
        });
      }
    }
    return count;
  }

  function getToken(email, password) {
    if (!token) {
      getSecurityToken(email, password).then((accessToken) => {
        fetchClientKey(user.email).then((res) => {
          getProgrammeCards(res.client_access_key, accessToken).then(
            (cards) => {
              getPersonalisationHistory(
                res.client_access_key,
                accessToken
              ).then((data) => {
                getCardDesignInfo(res.client_access_key, accessToken).then(
                  (designs) => {
                    getAvailableBins(res.client_access_key).then((bins) => {
                      setToken(accessToken);
                      setCardDesigns(designs);
                      setProgrammeCards(cards);
                      setReportData(data);
                      setClientBins(bins);
                      setLoading(false);
                    });
                  }
                );
              });
            }
          );
        });
      });
    } else {
      fetchClientKey(user.email).then((res) => {
        getProgrammeCards(res.client_access_key, token).then((cards) => {
          getPersonalisationHistory(res.client_access_key, token).then(
            (data) => {
              getCardDesignInfo(res.client_access_key, token).then(
                (designs) => {
                  setCardDesigns(designs);
                  setProgrammeCards(cards);
                  setReportData(data);
                  setLoading(false);
                }
              );
            }
          );
        });
      });
    }
  }

  if (!token) {
    getToken('analytics-admin@yimba.com', 'Sp3ctrum15Gr33n');
  }

  if (!loading) {
    createChartDataset();
    return (
      <>
        <div className='content'>
          <Row>
            <Col md='3'>
              <Card style={{ height: 125 }}>
                <CardHeader>
                  <CardTitle tag='h6'>Select Bin Number</CardTitle>
                </CardHeader>
                <CardBody>
                  <select
                    onChange={(choice) => filterBinData(choice.target.value)}
                    className='form-control'
                  >
                    <option>All Bins</option>
                    {clientBins.map((bin, index) => (
                      <option key={index} value={bin.bin_number}>
                        {bin.bin_number}
                      </option>
                    ))}
                  </select>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg='3' md='6' sm='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col md='4' xs='5'>
                      <div className='icon-big text-center icon-warning'>
                        <i className='nc-icon nc-credit-card text-primary' />
                      </div>
                    </Col>
                    <Col md='8' xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Cardholders</p>
                        <CardTitle tag='p'>{programmeCards.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg='3' md='6' sm='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col md='4' xs='5'>
                      <div className='icon-big text-center icon-warning'>
                        <i className='nc-icon nc-palette text-warning' />
                      </div>
                    </Col>
                    <Col md='8' xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Total Designs</p>
                        <CardTitle tag='p'>{getTotalDesigns()}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg='3' md='6' sm='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col md='4' xs='5'>
                      <div className='icon-big text-center icon-warning'>
                        <i className='nc-icon nc-money-coins text-success' />
                      </div>
                    </Col>
                    <Col md='8' xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>Revenue</p>
                        <CardTitle tag='p'>£0.00</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg='3' md='6' sm='6'>
              <Card className='card-stats'>
                <CardBody>
                  <Row>
                    <Col md='4' xs='5'>
                      <div className='icon-big text-center icon-warning'>
                        <i className='nc-icon nc-vector text-danger' />
                      </div>
                    </Col>
                    <Col md='8' xs='7'>
                      <div className='numbers'>
                        <p className='card-category'>API Calls</p>
                        <CardTitle tag='p'>N/A</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='4'>
              <Card style={{ height: 125 }}>
                <CardHeader>
                  <CardTitle tag='h6'>Start Date</CardTitle>
                </CardHeader>
                <CardBody>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='dd/MM/yyyy'
                    className='form-control'
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ height: 125 }}>
                <CardHeader>
                  <CardTitle tag='h6'>End Date</CardTitle>
                </CardHeader>
                <CardBody>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat='dd/MM/yyyy'
                    className='form-control'
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ height: 125 }}>
                <CardHeader>
                  <CardTitle tag='h6'>Preset Range</CardTitle>
                </CardHeader>
                <CardBody>
                  <select
                    onChange={(choice) => setTimeSlicer(choice.target.value)}
                    className='form-control'
                  >
                    <option>Select...</option>
                    <option>7 Days</option>
                    <option>14 Days</option>
                    <option>28 Days</option>
                    <option>90 Days</option>
                    <option>All</option>
                  </select>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='8'>
              <Card className='card-chart'>
                <CardHeader>
                  <CardTitle tag='h5'>
                    User Engagement - Current Calendar Year
                  </CardTitle>
                  <p className='card-category'>
                    Number of Designs Applied Monthly
                  </p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={designsApplied.data}
                    options={designsApplied.options}
                    width={400}
                    height={185}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <CategoryUsage
                reportData={reportData}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h4'>Popular Designs</CardTitle>
                </CardHeader>
                <CardBody>
                  <TopDesigns
                    reportData={reportData}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h4'>Unpopular Designs</CardTitle>
                </CardHeader>
                <CardBody>
                  <BottomDesigns
                    reportData={reportData}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h4'>Top Card Holders</CardTitle>
                </CardHeader>
                <CardBody>
                  <TopCardHolders
                    reportData={reportData}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  } else {
    return (
      <div className='content'>
        <Loading />
      </div>
    );
  }
}
