import React from 'react';
import { makeStyles, ThemeProvider, createTheme} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat'
        ].join(','),
        fontSize: 12
    },
});


const useStyles = makeStyles({
    root: {
        border: "1px solid rgb(212, 212, 212)",
        borderRadius: 3,
        color: '#66615b',
        flexGrow: 1,
    },
});

export default function ContentModerator(props) {
    const classes = useStyles();

    function setNodeData(value){
        props.selectedTags(value);
    }

    return (
        <ThemeProvider theme={theme}>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                defaultSelected={'All'}
                onNodeSelect={(event, value) => setNodeData(value)}
            >
                <TreeItem nodeId="All" label="All">

                </TreeItem>
                <TreeItem nodeId="Mastercard Rules" label="Mastercard Rules">

                </TreeItem>
                <TreeItem nodeId="Visa Rules" label="Visa Rules">

                </TreeItem>
                <TreeItem nodeId="Explicit Nudity" label="Explicit Nudity">
                    <TreeItem nodeId="Nudity" label="Nudity" />
                    <TreeItem nodeId="Graphic Male Nudity" label="Graphic Male Nudity" />
                    <TreeItem nodeId="Graphic Female Nudity" label="Graphic Female Nudity" />
                    <TreeItem nodeId="Sexual Activity" label="Sexual Activity" />
                    <TreeItem nodeId="Illustrated Explicit Nudity" label="Illustrated Explicit Nudity" />
                    <TreeItem nodeId="Adult Toys" label="Adult Toys" />
                </TreeItem>
                <TreeItem nodeId="Suggestive" label="Suggestive">
                    <TreeItem nodeId="Female Swimwear Or Underwear" label="Female Swimwear Or Underwear" />
                    <TreeItem nodeId="Male Swimwear Or Underwear" label="Male Swimwear Or Underwear" />
                    <TreeItem nodeId="Partial Nudity" label="Partial Nudity" />
                    <TreeItem nodeId="Barechested Male" label="Barechested Male" />
                    <TreeItem nodeId="Revealing Clothes" label="Revealing Clothes" />
                    <TreeItem nodeId="Sexual Situations" label="Sexual Situations" />
                </TreeItem>
                <TreeItem nodeId="Violence" label="Violence">
                    <TreeItem nodeId="Graphic Violence Or Gore" label="Graphic Violence Or Gore" />
                    <TreeItem nodeId="Physical Violence" label="Physical Violence" />
                    <TreeItem nodeId="Weapon Violence" label="Weapon Violence" />
                    <TreeItem nodeId="Weapons" label="Weapons" />
                    <TreeItem nodeId="Self Injury" label="Self Injury" />
                </TreeItem>
                <TreeItem nodeId="Visually Disturbing" label="Visually Disturbing">
                    <TreeItem nodeId="Emaciated Bodies" label="Emaciated Bodies" />
                    <TreeItem nodeId="Corpses" label="Corpses" />
                    <TreeItem nodeId="Hanging" label="Hanging" />
                    <TreeItem nodeId="Air Crash" label="Air Crash" />
                    <TreeItem nodeId="Explosions And Blasts" label="Explosions And Blasts" />
                </TreeItem>
                <TreeItem nodeId="Rude Gestures" label="Rude Gestures">
                    <TreeItem nodeId="Middle Finger" label="Middle Finger" />
                </TreeItem>
                <TreeItem nodeId="Drugs" label="Drugs">
                    <TreeItem nodeId="Drug Products" label="Drug Products" />
                    <TreeItem nodeId="Drug Use" label="Drug Use" />
                    <TreeItem nodeId="Pills" label="Pills" />
                    <TreeItem nodeId="Drug Paraphernalia" label="Drug Paraphernalia" />
                </TreeItem>
                <TreeItem nodeId="Tobacco" label="Tobacco">
                    <TreeItem nodeId="Tobacco Products" label="Tobacco Products" />
                    <TreeItem nodeId="Smoking" label="Smoking" />
                </TreeItem>
                <TreeItem nodeId="Alcohol" label="Alcohol">
                    <TreeItem nodeId="Drinking" label="Drinking" />
                    <TreeItem nodeId="Alcoholic Beverages" label="Alcoholic Beverages" />
                </TreeItem>
                <TreeItem nodeId="Gambling" label="Gambling">
                    <TreeItem nodeId="Gambling" label="Gambling Content" />
                </TreeItem>
                <TreeItem nodeId="Hate Symbols" label="Hate Symbols">
                    <TreeItem nodeId="Nazi Party" label="Nazi Party" />
                    <TreeItem nodeId="White Supremecy" label="White Supremecy" />
                    <TreeItem nodeId="Extremist" label="Extremist" />
                </TreeItem>
            </TreeView>
        </ThemeProvider>
    );
}
