import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

const CardDesignPublishModal = ({
  publishLoader,
  publishDesignChanges,
  cardDesignPublishModal,
  setCardDesignPublishModal,
}) => {
  const { t } = useTranslation('card_designs', 'buttons');

  return (
    <Modal isOpen={cardDesignPublishModal} centered={true}>
      <ModalHeader>{t('cdsgn_publish_design_label')}</ModalHeader>
      <ModalBody>
        <Label>{t('cdsgn_design_pulish_desc')}</Label>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={() => publishDesignChanges()}
          disabled={publishLoader}
        >
          {t('buttons:btn_submit_publish')}
          {publishLoader && <Spinner size='sm' style={{ marginLeft: '8px' }} />}
        </Button>
        <Button
          color='secondary'
          onClick={() => setCardDesignPublishModal(false)}
          disabled={publishLoader}
        >
          {t('buttons:btn_cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CardDesignPublishModal;
