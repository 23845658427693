import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, Row, Col, CardHeader, CardBody, Table } from 'reactstrap';
import classnames from 'classnames';
import Loading from '../../components/Loading';

const TopCardHolders = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [reportData] = [props.reportData];
    const [startDate] = [props.startDate];
    const [endDate] = [props.endDate];

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    function renderCardTopDesigns(topIndex) {
        let newData = [];

        if (reportData.length > 0) {
            reportData.forEach(element => {
                element.design_applied = new Date(element.design_applied);
            });
            const dateFiltered = reportData.filter(item => item.design_applied >= startDate && item.design_applied <= endDate);

            const grouped = groupBy(dateFiltered, data => data.card_identifier);
            grouped.forEach(element => {
                let newRow = {};
                newRow.card_id = element[0].card_id;
                newRow.card_identifier = element[0].card_identifier;
                newRow.count = element.length;
                newData.push(newRow);
            });
        }

        newData.sort(function (b, a) { return a.count - b.count });
        if (newData.length > 0) {
            return newData.map((design, index) => {
                if (index < topIndex) {
                    return (
                        <tr key={design.card_identifier}>
                            <td>{design.card_id}</td>
                            <td>{design.card_identifier}</td>
                            <td style={{ textAlign: 'center' }}>{design.count}</td>
                        </tr>
                    )
                }
                else{
                    return null;
                }
            })
        }
        else {
            return (<Loading />)
        }
    }

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Top 5
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        Top 10
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        Top 25
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col md="12">
                            {/* <Card> */}
                            <CardHeader>
                                <CardTitle tag="h4">Top 5 Cardholders</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Cardholder Identity</th>
                                            <th style={{ textAlign: 'center' }}>Designs Applied</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderCardTopDesigns(5)}
                                    </tbody>
                                </Table>
                            </CardBody>
                            {/* </Card> */}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col md="12">
                            {/* <Card> */}
                            <CardHeader>
                                <CardTitle tag="h4">Top 10 Cardholders</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Cardholder Identity</th>
                                            <th style={{ textAlign: 'center' }}>Designs Applied</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderCardTopDesigns(10)}
                                    </tbody>
                                </Table>
                            </CardBody>
                            {/* </Card> */}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col md="12">
                            {/* <Card> */}
                            <CardHeader>
                                <CardTitle tag="h4">Top 25 Cardholders</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Cardholder Identity</th>
                                            <th style={{ textAlign: 'center' }}>Designs Applied</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderCardTopDesigns(25)}
                                    </tbody>
                                </Table>
                            </CardBody>
                            {/* </Card> */}
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default TopCardHolders;