import { Fragment, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import cancelDesignChange from 'utils/cancelDesignChange';
import deleteCategoryChange from 'utils/deleteCategoryChange';
import ReviewCategoryChangesModal from './Modal/ReviewCategoryChangesModal';
import { useTranslation } from 'react-i18next';
import { checkNull } from 'utils/validationUtils';

const ReviewCategoryChanges = ({
  tempCategories,
  tempDesigns,
  queuedDeletions,
  cardDesigns,
  callDesignCategoriesAPI,
  callTempCardDesignsAPI,
  callDesignDeleteQueueAPI,
  callCardDesignsAPI,
}) => {
  const { t } = useTranslation([
    'review_changes',
    'buttons',
    'table',
    'buttons',
  ]);

  const [checkedCategories, setCheckedCategories] = useState([]);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [openPopupModal, setOpenPopupModal] = useState(false);
  const [renderCategoryMerge, setRenderCategoryMerge] = useState(false);

  const selectAllCategories = (e) => {
    if (e.target.checked) {
      const allCategories = tempCategories
        ?.filter((category) => !category?.submitted)
        ?.map((category) => category?.id);
      setCheckedCategories(allCategories);
    } else {
      setCheckedCategories([]);
    }
  };

  const toggleCheckCategories = (id, isCheck) => {
    if (isCheck) {
      if (!checkedCategories?.includes(id)) {
        setCheckedCategories([...checkedCategories, id]);
      }
    } else {
      setCheckedCategories(checkedCategories?.filter((item) => item !== id));
    }
  };

  const cancelCategoryChangeHandler = async () => {
    setOpenPopupModal(false);
    setShowCategoryLoader(true);
    for (let i = 0; i < checkedCategories?.length; i++) {
      const categoryId = checkedCategories[i];
      await deleteCategoryChange(categoryId);
      await removeDependentDesigns(categoryId);
    }
    await callDesignCategoriesAPI();
    await callTempCardDesignsAPI();
    await callDesignDeleteQueueAPI();
    await callCardDesignsAPI();
    setCheckedCategories([]);
    setShowCategoryLoader(false);
  };

  const checkCategoryStyling = (unapprovedCategory) => {
    if (!unapprovedCategory) {
      return { backgroundColor: '#f7d577' };
    }
  };

  const removeDependentDesigns = async (categoryID) => {
    if (tempDesigns) {
      for (let i = 0; i < tempDesigns?.length; i++) {
        const item = tempDesigns[i];
        if (item?.category_id === categoryID) {
          await cancelDesignChange(item?.id);
        }
      }
    }
  };

  const renderCategoryChangeData = () => {
    let action = null;
    if (tempCategories) {
      if (queuedDeletions) {
        if (!renderCategoryMerge) {
          queuedDeletions?.forEach((category) => {
            let newDeletion = cardDesigns[0].filter(
              (item) =>
                item?.design_category_id === category?.design_category_id
            );
            if (newDeletion?.length > 0) {
              newDeletion[0].id = category?.id;
              tempCategories.push(newDeletion[0]);
            }
          });
          setRenderCategoryMerge(true);
        }
      }
      return tempCategories?.map((category, index) => {
        let submissionType = 'Submit for Approval';
        if (!category.submitted) {
          let designsLength = category?.designs?.length ?? 0;
          if (category?.action) {
            if (category?.action === 'UPDATE') {
              action = 'Update Category';
            }
            if (category?.action === 'DELETE') {
              action = 'Remove Category';
              submissionType = 'Publish to Live';
              designsLength = '-';
            }
          } else {
            action = 'Remove Category';
          }
          return (
            <tr
              key={index}
              style={checkCategoryStyling(
                category?.designs?.length > 0 ||
                  submissionType !== 'Submit for Approval'
              )}
              title={!category?.designs && t('revchn_publishing_row_title')}
            >
              <td>
                <input
                  type='checkbox'
                  id={category?.id}
                  checked={checkedCategories?.includes(category?.id)}
                  onChange={(e) =>
                    toggleCheckCategories(category?.id, e.target.checked)
                  }
                />
              </td>
              <td>{category?.id}</td>
              <td>{checkNull(category?.title)}</td>
              {category.parent_category === 0 ? (
                <td> {t('table:tb_general')}</td>
              ) : (
                <td>{t('table:tb_promoted')}</td>
              )}
              <td>{checkNull(action)}</td>
              <td>{designsLength}</td>
              <td>{checkNull(submissionType)}</td>
            </tr>
          );
        } else {
          return null;
        }
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('revchn_category_changes_label')}</CardTitle>
          <p>{t('revchn_publishing_row_title')}</p>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllCategories'
                    checked={
                      checkedCategories?.length === tempCategories?.length &&
                      tempCategories?.length > 0
                    }
                    disabled={tempCategories?.length === 0}
                    onChange={selectAllCategories}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_category_name')}</th>
                <th>{t('table:tb_category_type')}</th>
                <th>{t('table:tb_change_type')}</th>
                <th>{t('table:tb_no_of_designs')}</th>
                <th>{t('table:tb_submission_action')}</th>
              </tr>
            </thead>
            <tbody>{renderCategoryChangeData()}</tbody>
          </Table>
          {tempCategories?.length > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setOpenPopupModal(true)}
                disabled={checkedCategories?.length === 0 || showCategoryLoader}
                style={{ width: 150 }}
              >
                {t('buttons:btn_cancel')}
                {checkedCategories?.length > 1 && ' ALL'}
                {showCategoryLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <ReviewCategoryChangesModal
        openPopupModal={openPopupModal}
        checkedCategories={checkedCategories}
        cancelCategoryChangeHandler={cancelCategoryChangeHandler}
        setOpenPopupModal={setOpenPopupModal}
      />
    </Fragment>
  );
};

export default ReviewCategoryChanges;
