function getPromotedContent(programmeID) {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/published-promo-content`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        programme_id: `${programmeID}`,
      },
    }
  )
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default getPromotedContent;
