import Loading from 'components/Loading';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import { checkNull } from 'utils/validationUtils';
import RemoveCategoryModal from './Modal/RemoveCategoryModal';
import addDesignCategory from 'utils/addDesignCategory';
import AddCategoryModal from './Modal/AddCategoryModal';
import { successToast } from 'components/Toast/SuccessToast';

const CarouselCategories = ({
  loader,
  cardDesigns,
  tempCategories,
  sessionKey,
  callCardDesignsAPI,
  callDesignCategoriesAPI,
}) => {
  const { t } = useTranslation(['card_designs', 'table', 'buttons']);
  const [addCategoryModal, seAddCategoryModal] = useState(false);
  const [checkedCarousel, setCheckedCarousel] = useState([]);
  const [newColumnData, setNewColumnData] = useState(null);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [addCategoryLoader, setAddCategoryLoader] = useState(false);
  const [openPopupModal, setOpenPopupModal] = useState(false);
  const activeCategories = newColumnData?.filter(
    (category) => category?.action !== 'NONE'
  );

  useEffect(() => {
    const newColumnData = [];

    cardDesigns?.[0]?.forEach((record) => {
      if (record?.parent_catagory) {
        newColumnData?.push(record);
      }
    });

    if (tempCategories) {
      tempCategories?.forEach((category) => {
        if (category?.parent_category != null) {
          if (category?.action === 'NONE') {
            category.temp = true;
            newColumnData?.push(category);
          }
          if (category?.action === 'DELETE') {
            if (category?.description === 'CHILD') {
              let idx = newColumnData?.findIndex(
                (x) => x?.code === category?.code
              );
              newColumnData.splice(idx, 1);
            }
          }
        }
      });
    }
    setNewColumnData(newColumnData);
  }, [cardDesigns, tempCategories]);

  // Carousel Categories select start
  const selectAllCarousel = (e) => {
    if (e?.target?.checked) {
      const allCarousel = activeCategories?.map(
        (category) => category?.design_category_id
      );
      setCheckedCarousel(allCarousel);
    } else {
      setCheckedCarousel([]);
    }
  };

  const toggleCheckCarousel = (id, isCheck) => {
    if (isCheck) {
      if (!checkedCarousel?.includes(id)) {
        setCheckedCarousel([...checkedCarousel, id]);
      }
    } else {
      setCheckedCarousel(checkedCarousel?.filter((item) => item !== id));
    }
  };

  const deleteCategoriesHandler = async () => {
    setOpenPopupModal(false);
    setShowCategoryLoader(true);

    const dataTobeRemoved = newColumnData?.filter((item) =>
      checkedCarousel?.includes(item?.design_category_id)
    );

    for (let i = 0; i < dataTobeRemoved?.length; i++) {
      const category = dataTobeRemoved[i];
      if (category?.parent_catagory) {
        await addDesignCategory(
          sessionKey,
          category?.code,
          category?.title,
          'CHILD',
          category?.parent_catagory,
          'DELETE'
        );
      } else {
        await addDesignCategory(
          sessionKey,
          category?.code,
          category?.title,
          'PARENT',
          category?.parent_catagory,
          'DELETE'
        );
      }
    }
    await callCardDesignsAPI();
    await callDesignCategoriesAPI();
    setCheckedCarousel([]);
    setShowCategoryLoader(false);
  };
  // Carousel Categories select end

  const checkCategoryStyling = (unapprovedCategory) => {
    if (unapprovedCategory) {
      return { backgroundColor: '#f7d577' };
    }
  };

  // Add Category operation
  const addCategory = async (categoryName, categoryDescription) => {
    seAddCategoryModal(false);
    setAddCategoryLoader(true);
    let newCatCode = categoryName?.replace(' ', '');
    newCatCode = newCatCode?.toLowerCase();
    await addDesignCategory(
      sessionKey,
      newCatCode,
      categoryName,
      categoryDescription,
      0,
      'NONE'
    );
    await callCardDesignsAPI();
    await callDesignCategoriesAPI();
    setAddCategoryLoader(false);
    successToast(t('cdsgn_category_has_been_added_label'));
  };

  const renderSubCategoryData = () => {
    if (newColumnData?.length > 0) {
      return newColumnData?.map((category, index) => {
        const id = category?.design_category_id
          ? category?.design_category_id
          : category?.id;
        return (
          <tr key={index} style={checkCategoryStyling(category?.temp)}>
            <td>
              <input
                type='checkbox'
                id={id}
                checked={checkedCarousel?.includes(id)}
                onChange={(e) => toggleCheckCarousel(id, e?.target?.checked)}
                disabled={category?.temp}
              />
            </td>
            <td>{checkNull(id)}</td>
            <td>{checkNull(category?.code)}</td>
            <td>{checkNull(category?.title)}</td>
            <td>{checkNull(category?.description)}</td>
            {category?.temp ? (
              <td>{t('table:tb_pending')}</td>
            ) : (
              <td>{t('table:tb_approved')}</td>
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('cdsgn_carousel_categories_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Button
            className='btn-square btn btn-success'
            onClick={() => seAddCategoryModal(true)}
            disabled={addCategoryLoader}
          >
            {t('buttons:btn_add_new_category')}
            {addCategoryLoader && (
              <Spinner size='sm' style={{ marginLeft: '8px' }} />
            )}
          </Button>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllCarousel'
                    checked={
                      checkedCarousel?.length === activeCategories?.length &&
                      activeCategories?.length > 0
                    }
                    onChange={selectAllCarousel}
                    disabled={activeCategories?.length === 0}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_category_code')}</th>
                <th>{t('table:tb_category_name')}</th>
                <th>{t('table:tb_category_description')}</th>
                <th>{t('table:tb_status')}</th>
              </tr>
            </thead>
            {loader && !activeCategories ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{renderSubCategoryData()}</tbody>
            )}
          </Table>
          {activeCategories?.length > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setOpenPopupModal(true)}
                disabled={checkedCarousel?.length === 0 || showCategoryLoader}
                style={{ width: 160 }}
              >
                {t('buttons:btn_remove')}
                {checkedCarousel?.length > 1 && ' ALL'}
                {showCategoryLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <RemoveCategoryModal
        openPopupModal={openPopupModal}
        checkedCarousel={checkedCarousel}
        deleteCategoriesHandler={deleteCategoriesHandler}
        setOpenPopupModal={setOpenPopupModal}
      />
      <AddCategoryModal
        modalShow={addCategoryModal}
        setModalShow={seAddCategoryModal}
        addCategory={addCategory}
      />
    </Fragment>
  );
};

export default CarouselCategories;
