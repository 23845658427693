import React, { useState, useReducer } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, Label, Input, FormGroup, Button } from "reactstrap";
import Img from 'react-cool-img';
import Loading from '../components/Loading';
import FileBase64 from 'react-file-base64';
import setPromotedContent from 'utils/setPromotedContent';
import fetchClientKey from 'utils/fetchClientKey';
import fetchProgrammeData from 'utils/fetchProgrammeData';
import { useAuth0 } from "../react-auth0-spa";
import uploadImage from 'utils/uploadImage';
import getPromotedContentQueue from 'utils/getPromotedContentQueue';
import deleteTempPromoContent from 'utils/deleteTempPromoContent';
import getPromotedContent from "utils/getPromotedContent";

export default function PromotedContent() {
    const { user } = useAuth0();
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const [previewTitle, setPreviewTitle] = useState();
    const [displayType, setDisplayType] = useState('Top');
    const [previewText, setPreviewText] = useState();
    const [editMode, setEditMode] = useState(false);
    const [ctaText, setCTAText] = useState('');
    const [ctaDestination, setCTADestination] = useState();
    const [pageState, setPageState] = useReducer((pageState, newPageState) =>
        ({ ...pageState, ...newPageState }),
        { cardProgramme: null, userSessionKey: null, promotedContent: {}, loading: true, selectedItem: null, deletePromoContent: false, savingContent: false }
    );

    function getFiles(files) {
        setPreviewImage(files.base64);
    }

    function uuid() {
        const url = URL.createObjectURL(new Blob())
        const [id] = url.toString().split('/').reverse()
        URL.revokeObjectURL(url)
        return id
    }

    function renderPreview() {
        if (displayType === 'Top') {
            return (
                <>
                    <div style={{ backgroundImage: "url(" + previewImage + ")", height: 450 * 0.55, width: 450, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPositionX: 'center' }}>
                        <div style={{ background: previewText ? 'rgba(0, 0, 0, 0.5)' : null }}>
                            <Label for="PreviewHeader" style={{ fontWeight: 'bold', color: '#ffffff', marginTop: 20, marginLeft: 10, fontSize: 18 }}>{previewText}</Label>
                        </div>
                    </div>
                    <div>
                        {ctaText.length > 0 ? <Button style={{ marginTop: -75, marginLeft: 10, borderRadius: 20 }} color="info" onClick={() => window.open(`${ctaDestination}`)}>{ctaText}</Button> : null}
                    </div>
                </>
            )
        }
        else {
            return (
                <div style={{ backgroundColor: '#dddddd', textAlign: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPositionX: 'center' }}>
                    <Label for="PreviewHeader" style={{ fontWeight: 'bold', color: '#000000', fontSize: 18, marginBotton: 20, marginTop: 10 }}>{previewTitle}</Label>
                    <br></br>
                    <Img
                        src={previewImage}
                        cache={true}
                        alt="Card Image"
                        style={{ height: 450 * 0.55, width: 450 }}
                    />
                    <br></br>
                    <Label for="PreviewHeader" style={{ color: '#000000', fontSize: 14, marginTop: 10 }}>{previewText}</Label>
                </div>
            )
        }
    }

    function editItem(itemID) {
        let flatData = pageState.promotedContent.flat();
        let itemToEdit = flatData.filter(item => item.id === itemID)[0];
        setPreviewTitle(itemToEdit.promo_title);
        setPreviewImage(itemToEdit.promo_image);
        setPreviewText(itemToEdit.promo_content);
        setCTAText(itemToEdit.cta_text);
        setCTADestination(itemToEdit.cta_destination);
        if (itemToEdit.display_type === 'Top') {
            setDisplayType('Top');
        }
        else {
            setDisplayType('Bottom');
        }
        setEditMode(true);
        setShowModal(true)
    }

    function deleteItem(itemID) {
        deleteTempPromoContent(itemID).then((res) => {
            setPageState({ loading: true, deletePromoContent: false });
        })
    }

    function renderPromotedContentData() {
        if (pageState.promotedContent.length > 0) {
            let flatData = pageState.promotedContent.flat();
            return flatData.map((content, index) => {
                if (!content.error) {
                    return (
                        <tr key={content.id}>
                            <td>{content.id}</td>
                            <td><Img
                                src={content.promo_image}
                                cache={true}
                                alt="Card Image"
                                style={{ height: 75, width: 125 }}
                            /></td>
                            <td><Button color="link" onClick={() => editItem(content.id)}>{content.promo_title}</Button></td>
                            <td>{content.display_type}</td>
                            <td className="text-success"><strong>{content.status}</strong></td>
                            <td style={{ textAlign: 'center' }}><button type="submit" className="btn btn-block" style={{ backgroundColor: '#ffffff' }} onClick={() => setPageState({ selectedItem: content.id, deletePromoContent: true })}><i className="fa fa-trash text-danger" /></button></td>
                        </tr>
                    )
                }
                else {
                    return null;
                }
            })
        }
        else {
            return (null)
        }
    }

    function savePromotedContent() {
        // Need to handle the edit of an existing item
        if (!editMode) {
            setPageState({ savingContent: true });
            let newUUID = uuid();
            let newFileData = previewImage.split(',');
            uploadImage(newFileData[1], newUUID + '.jpg').then((image) => {
                setPromotedContent(newUUID, previewTitle, pageState.cardProgramme, image.Location, previewText, ctaText, ctaDestination, displayType, pageState.userSessionKey).then((res) => {
                    setShowModal(false);
                    setPageState({ loading: true });
                })
            })
        }
        else {
            console.log('Update item');
        }
    }

    function loadData() {
        fetchClientKey(user.email).then((keyinfo) => {
            fetchProgrammeData(keyinfo.client_access_key).then((results) => {
                getPromotedContent(results.programme_identifier).then((pc) => {
                    getPromotedContentQueue(results.programme_identifier).then((cq) => {
                        let publishedContent = [pc];
                        let contentQueue = [cq];
                        setPageState({ cardProgramme: results.id, userSessionKey: results.programme_identifier, promotedContent: [...contentQueue, ...publishedContent], loading: false })
                    })
                })
            });
        });
    }

    function resetPageState() {
        setPreviewTitle();
        setPreviewImage();
        setPreviewText();
        setCTAText('');
        setCTADestination();
        setDisplayType('Top');
        setShowModal(true);
    }

    if (pageState.loading) {
        loadData();
        return (
            <div className="content">
                <Loading />
            </div>)
    }
    else {
        return (
            <>
                <div className="content">
                    <Modal isOpen={showModal} centered={true} size="lg">
                        <ModalHeader>{'New Promoted Content Item'}</ModalHeader>
                        <ModalBody>
                            <Form style={{ textAlign: 'left' }}>
                                <FormGroup>
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Promoted Content Preview</Label>
                                    <Row>
                                        <Col xs="6" sm="2"></Col>
                                        <Col xs="6" sm="8">
                                            {renderPreview()}
                                        </Col>
                                        <Col xs="6" sm="2"></Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Image" style={{ fontWeight: 'bold' }}>Promoted Content Background Image</Label>
                                    <FileBase64
                                        multiple={false}
                                        onDone={getFiles.bind(this)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Promoted Content Title</Label>
                                    <Input placeholder="" name="title" id="title" onChange={e => setPreviewTitle(e.target.value)} value={previewTitle} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Promoted Content Text</Label>
                                    <Input type="textarea" name="title" id="title" onChange={e => setPreviewText(e.target.value)} value={previewText} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>CTA Button Text</Label>
                                    <Input placeholder="e.g Find Out More" name="title" id="title" onChange={e => setCTAText(e.target.value)} value={ctaText} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>CTA Button Destination</Label>
                                    <Input placeholder="e.g https://www.google.com" name="title" id="title" onChange={e => setCTADestination(e.target.value)} value={ctaDestination} />
                                </FormGroup>
                                <FormGroup tag="fieldset">
                                    <Label for="CategoryCode" style={{ fontWeight: 'bold' }}>Display Preference:-</Label>
                                    <FormGroup check>
                                        <Label check>
                                            {displayType === 'Top' ? <Input type="radio" name="radio1" defaultChecked onChange={e => setDisplayType('Top')} /> : <Input type="radio" name="radio1" onChange={e => setDisplayType('Top')} />}
                                            Top Section
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            {displayType === 'Bottom' ? <Input type="radio" name="radio1" defaultChecked onChange={e => setDisplayType('Bottom')} /> : <Input type="radio" name="radio1" onChange={e => setDisplayType('Bottom')} />}
                                            Bottom Section
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {pageState.savingContent ? <Button color="secondary">Saving...</Button> : <Button color="success" onClick={() => savePromotedContent()}>Save</Button>}
                            <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={pageState.deletePromoContent} centered={true} >
                        <ModalHeader >Remove Promoted Content</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label>Are you sure you wish to remove this Promoted Content?</Label>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => deleteItem(pageState.selectedItem)}>Yes</Button>
                            <Button color="secondary" onClick={() => setPageState({ deletePromoContent: false })}>No</Button>
                        </ModalFooter>
                    </Modal>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    {/* <CardTitle tag="h4">Promoted Content</CardTitle> */}
                                    <button type="submit" className="btn btn-primary" onClick={() => resetPageState()}>{'New Promoted Content'}</button>
                                </CardHeader>
                                <CardBody>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Preview</th>
                                                <th>Content Name</th>
                                                <th>Displayed In</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderPromotedContentData()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}