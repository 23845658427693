import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Button,
} from 'reactstrap';

const ReviewCardDesignModal = ({
  checkedDesigns,
  designCancelModal,
  completeDesignCancellation,
  setDesignCancelModal,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons']);

  return (
    <Modal isOpen={designCancelModal} centered={true}>
      <ModalHeader>{t('revchn_cancel_card_designs_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              {checkedDesigns?.length > 1
                ? t('revchn_cancel_all_changes')
                : t('revchn_cancel_changes')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => completeDesignCancellation()}>
          {t('buttons:btn_yes')}
        </Button>
        <Button color='success' onClick={() => setDesignCancelModal(false)}>
          {t('buttons:btn_no')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReviewCardDesignModal;
