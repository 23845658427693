import { Fragment, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import cancelDesignChange from 'utils/cancelDesignChange';
import rejectDesign from 'utils/rejectDesign';
import RejectCardDesignModal from './Modal/RejectCardDesignModal';
import Loading from 'components/Loading';
import Img from 'react-cool-img';
import { useTranslation } from 'react-i18next';
import { checkNull } from 'utils/validationUtils';
import ReviewCardDesignModal from './Modal/ReviewCardDesignModal';

const CardDesigns = ({
  loading,
  tempDesigns,
  callTempCardDesignsAPI,
  cardDesigns,
  tempCategories,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons', 'table']);
  const [checkedDesigns, setCheckedDesigns] = useState([]);
  const [designRejectModal, setDesignRejectModal] = useState(false);
  const [designCancelModal, setDesignCancelModal] = useState(false);
  const [showDesignsRejectLoader, setShowDesignsRejectLoader] = useState(false);
  const [showDesignsLoader, setShowDesignsLoader] = useState(false);
  const submittedDesigns =
    tempDesigns?.filter((design) => design?.submitted)?.length || 0;

  // Card Designs select start
  const selectAllDesigns = (e) => {
    if (e.target.checked) {
      const allTempDesigns =
        tempDesigns?.length > 0
          ? tempDesigns
              ?.filter((design) => design?.submitted)
              ?.map((category) => category.id)
          : [];
      setCheckedDesigns(allTempDesigns);
    } else {
      setCheckedDesigns([]);
    }
  };

  const toggleCheckDesigns = (id, isCheck) => {
    if (isCheck) {
      if (!checkedDesigns?.includes(id)) {
        setCheckedDesigns([...checkedDesigns, id]);
      }
    } else {
      setCheckedDesigns(checkedDesigns?.filter((item) => item !== id));
    }
  };

  const completeDesignRejection = async () => {
    setDesignRejectModal(false);
    setShowDesignsRejectLoader(true);
    for (let i = 0; i < checkedDesigns?.length; i++) {
      await rejectDesign(checkedDesigns[i]);
    }
    setCheckedDesigns([]);
    await callTempCardDesignsAPI();
    setShowDesignsRejectLoader(false);
  };

  const completeDesignCancellation = async () => {
    setDesignCancelModal(false);
    setShowDesignsLoader(true);
    for (let i = 0; i < checkedDesigns?.length; i++) {
      await cancelDesignChange(checkedDesigns[i]);
    }
    await callTempCardDesignsAPI();
    setCheckedDesigns([]);
    setShowDesignsLoader(false);
  };
  // Card Designs select end

  const renderDesignChanges = () => {
    if (tempDesigns) {
      return tempDesigns?.map((design, index) => {
        if (design.submitted) {
          let designCategory = cardDesigns?.[0]?.filter(
            (item) => item.design_category_id === design.category_id
          );
          if (designCategory?.length === 0) {
            designCategory = tempCategories?.filter(
              (item) => item.id === design.category_id
            );
            designCategory = designCategory?.[0]?.title;
          } else {
            designCategory = designCategory?.[0]?.title;
          }
          let ActionType = '';
          if (design.action === 'ADD') {
            ActionType = 'New Image Added';
          }
          return (
            <tr key={index}>
              <td>
                <input
                  type='checkbox'
                  id={design.id}
                  checked={checkedDesigns?.includes(design.id)}
                  onChange={(e) =>
                    toggleCheckDesigns(design.id, e.target.checked)
                  }
                />
              </td>
              <td>{checkNull(design.id)}</td>
              <td>
                <Img
                  src={design?.url}
                  cache={true}
                  alt='Card Image'
                  style={{ height: 70, width: 100 }}
                />
              </td>
              <td>{checkNull(designCategory)}</td>
              <td>{checkNull(ActionType)}</td>
              <td>{checkNull(design.PublishtoLive)}</td>
            </tr>
          );
        } else return null;
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('revchn_card_designs_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllDesigns'
                    checked={
                      checkedDesigns?.length === submittedDesigns &&
                      submittedDesigns > 0
                    }
                    onChange={selectAllDesigns}
                    disabled={submittedDesigns === 0}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_image')}</th>
                <th>{t('table:tb_design_category')}</th>
                <th>{t('table:tb_change_type')}</th>
                <th>{t('table:tb_publish_action')}</th>
              </tr>
            </thead>
            {loading && !tempDesigns ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{renderDesignChanges()}</tbody>
            )}
          </Table>
          {tempDesigns?.length > 0 && tempDesigns?.[0].submitted && (
            <div className='row d-flex flex-row-reverse mr-1'>
              <button
                type='submit'
                className='btn btn-warning btn-block'
                style={{ width: 150 }}
                disabled={
                  checkedDesigns?.length === 0 || showDesignsRejectLoader
                }
                onClick={() => setDesignRejectModal(true)}
              >
                {t('buttons:btn_reject')}
                {checkedDesigns?.length > 1 && ' ALL'}
                {showDesignsRejectLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </button>
              <div>
                <button
                  type='submit'
                  className='btn btn-danger btn-block'
                  style={{ width: 150 }}
                  onClick={() => setDesignCancelModal(true)}
                  disabled={checkedDesigns?.length === 0 || showDesignsLoader}
                >
                  {t('buttons:btn_cancel')}
                  {checkedDesigns?.length > 1 && ' ALL'}
                  {showDesignsLoader && (
                    <Spinner size='sm' style={{ marginLeft: '8px' }} />
                  )}
                </button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      <RejectCardDesignModal
        completeDesignRejection={completeDesignRejection}
        designRejectModal={designRejectModal}
        setDesignRejectModal={setDesignRejectModal}
        checkedDesigns={checkedDesigns}
      />
      <ReviewCardDesignModal
        checkedDesigns={checkedDesigns}
        completeDesignCancellation={completeDesignCancellation}
        designCancelModal={designCancelModal}
        setDesignCancelModal={setDesignCancelModal}
      />
    </Fragment>
  );
};

export default CardDesigns;
