import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const ReviewCategoryChangesModal = ({
  openPopupModal,
  checkedCategories,
  cancelCategoryChangeHandler,
  setOpenPopupModal,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons']);
  return (
    <Modal isOpen={openPopupModal} centered={true}>
      <ModalHeader>{t('revchn_category_changes_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              {checkedCategories?.length > 1
                ? t('revchn_cancel_all_changes')
                : t('revchn_cancel_changes')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => cancelCategoryChangeHandler()}>
          {t('buttons:btn_yes')}
        </Button>
        <Button color='secondary' onClick={() => setOpenPopupModal(false)}>
          {t('buttons:btn_no')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReviewCategoryChangesModal;
