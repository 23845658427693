// Rekit uses a new approach to organizing actions and reducers. That is
// putting related actions and reducers in one file. See more at:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da

import {
  YIMBA_SET_USER_DATA,
} from './constants';

export function setUserData(someValue) {
  return {
    type: YIMBA_SET_USER_DATA,
    data: someValue,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case YIMBA_SET_USER_DATA:
      return {
        ...state,
        someData: action.data,
      };

    default:
      return state;
  }
}
