function addBrandConfiguration(
  programme_id,
  brand_name,
  brand_logo,
  logo_position,
  network_logo,
  network,
  session_key,
  sub_brand_logo,
  sub_brand_logo_position
) {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/brand-configuration-queue`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        programme_id: `${programme_id}`,
        brand_name: `${brand_name}`,
        brand_logo: `${brand_logo}`,
        logo_position: `${logo_position}`,
        network_logo: `${network_logo}`,
        network: `${network}`,
        session_key: `${session_key}`,
        brand_sub_logo: `${sub_brand_logo}`,
        sub_logo_position: `${sub_brand_logo_position}`,
      },
    }
  )
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addBrandConfiguration;
