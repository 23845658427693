function saveCardDesign(input, bearerToken) {
  return fetch('https://design.yimbatech.com/metadata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + bearerToken,
      design_identifier: input.design_identifier,
      associated_sound: input.associatedSoundURL,
      content_expiry: input.contentExpiry,
      has_haptic: input.hasHapticResponse,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default saveCardDesign;
