import React, { useState, useEffect, Fragment } from 'react';
import Img from 'react-cool-img';
import getAIDAResults from 'utils/getAIDAResults';
import Loading from './Loading';
import {
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';

const AIDAImage = (imageProps) => {
  const [AIDAData, setAIDAData] = useState(null);
  const [modalResults, setModalResults] = useState(false);

  useEffect(() => {
    getAIDAResults(
      imageProps?.url?.replace(
        'https://s3.eu-west-2.amazonaws.com/yimba.images/',
        ''
      )
    ).then((AIDAResults) => {
      setAIDAData(AIDAResults);
    });
  }, [imageProps?.url]);

  const getResultStatus = () => {
    let imageResults = null;
    // let generalContent = AIDAData[0].Labels;
    let celebrityContent = AIDAData?.[1]?.CelebrityFaces;
    let moderationContent = AIDAData?.[2]?.ModerationLabels;

    if (moderationContent?.length > 0) {
      // imageResults = <td style={{ width: 250 }}><button type="submit" className="btn btn-danger btn-block" onClick={() => setModalResults(true)}>Image Verification Failed!</button></td>;
      imageResults = (
        <td>
          {celebrityInfo()}
          {moderationInfo()}
        </td>
      );
    } else {
      if (celebrityContent?.length > 0) {
        // imageResults = <td style={{ width: 250 }}><button type="submit" className="btn btn-danger btn-block" onClick={() => setModalResults(true)}>Image Verification Failed!</button></td>;
        imageResults = (
          <td>
            {celebrityInfo()}
            {moderationInfo()}
          </td>
        );
      } else {
        imageResults = <td>Image Verification Success</td>;
      }
    }

    return imageResults;
  };

  const celebrityInfo = () => {
    let celebrityContent = AIDAData[1]?.CelebrityFaces;
    if (celebrityContent?.length > 0) {
      return celebrityContent.map((celebrity, idx) => (
        <Fragment key={idx}>
          <FormGroup>
            <Alert color='primary'>Celebrity Detected</Alert>
            <Label>
              Name: <strong>{celebrity.Name}</strong>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>
              Confidence Rating:{' '}
              <strong>{celebrity.MatchConfidence + '%'}</strong>
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>
              Source:{' '}
              {
                <a
                  href={'https://' + celebrity.Urls[0]}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <strong>{celebrity.Urls[0]}</strong>
                </a>
              }
            </Label>
          </FormGroup>
          <Alert color='warning'>
            Ensure that you have the correct usage rights for this image!
          </Alert>
        </Fragment>
      ));
    }
  };

  const moderationInfo = () => {
    let moderationContent = AIDAData[2]?.ModerationLabels;
    if (moderationContent?.length > 0) {
      return (
        <div>
          <Alert color='danger'>Content Moderation Issue</Alert>
          {moderationContent.map((moderationIssue, idx) => (
            <Fragment key={idx}>
              <FormGroup>
                <Label>
                  Parent Issue: <strong>{moderationIssue.ParentName}</strong>
                </Label>
              </FormGroup>
              <FormGroup>
                <Label style={{ marginLeft: 20, color: '#777777' }}>
                  Issue:{' '}
                  <strong>
                    <i>{moderationIssue.Name}</i>
                  </strong>
                </Label>
              </FormGroup>
              <FormGroup>
                <Label style={{ marginLeft: 20, color: '#777777' }}>
                  Confidence Rating:{' '}
                  <strong>
                    {Math.round(moderationIssue.Confidence * 100) / 100 + '%'}
                  </strong>
                </Label>
              </FormGroup>
            </Fragment>
          ))}
          <Alert color='danger'>
            These Issues breach one or more Image Analysis Rules!
          </Alert>
        </div>
      );
    }
  };

  const showResults = () => {
    return (
      <Modal isOpen={modalResults} centered={true}>
        <ModalHeader>Image Verfication Analysis</ModalHeader>
        <ModalBody>
          <Form>
            {celebrityInfo()}
            {moderationInfo()}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => setModalResults(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  if (AIDAData) {
    return (
      <>
        {showResults()}
        <td>
          <Img
            src={imageProps?.url}
            cache={true}
            alt='Card Image'
            style={{ height: 50, width: 85 }}
          />
        </td>
        {getResultStatus()}
      </>
    );
  } else {
    return (
      <>
        <td>
          <Loading type='AIDA' />
        </td>
        <td>Analysing Image...</td>
      </>
    );
  }
};

export default AIDAImage;
