import { Fragment, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import cancelDesignChange from 'utils/cancelDesignChange';
import AIDAImage from 'components/AIDAImage';
import ReviewCardDesignsModal from './Modal/ReviewCardDesignsModal';
import { checkNull } from 'utils/validationUtils';
import { useTranslation } from 'react-i18next';

const ReviewCardDesigns = ({
  tempDesigns,
  cardDesigns,
  tempCategories,
  queuedSubDeletions,
  designSwitchQueue,
  callTempCardDesignsAPI,
  callCardDesignsAPI,
  callDesignCategoriesAPI,
  callSubDesignDeleteQueueAPI,
  callDesignSwitchQueueAPI,
}) => {
  const { t } = useTranslation(['card_designs', 'buttons', 'table', 'buttons']);
  const [checkedDesigns, setCheckedDesigns] = useState([]);
  const [openPopupModal, setOpenPopupModal] = useState(false);
  const [showDesignsLoader, setShowDesignsLoader] = useState(false);
  const [setAIDAResults] = useState('Analysing Image...');
  let designsCount =
    ~~tempDesigns?.length +
    ~~queuedSubDeletions?.length +
    ~~designSwitchQueue?.length;

  function checkImageResults(imageResults) {
    setAIDAResults(imageResults);
  }

  // Card Designs select start
  const selectAllDesigns = (e) => {
    if (e.target.checked) {
      const allTempDesigns =
        tempDesigns?.length > 0
          ? tempDesigns
              ?.filter((design) => !design?.submitted)
              ?.map((design) => design?.id)
          : [];
      const allQueuedSubDeletions =
        queuedSubDeletions?.length > 0
          ? queuedSubDeletions
              ?.filter((design) => !design?.submitted)
              ?.map((design) => design?.id)
          : [];
      const allSwitch =
        designSwitchQueue?.length > 0
          ? designSwitchQueue
              ?.filter((design) => !design?.submitted)
              ?.map((design) => design?.id)
          : [];
      const allDesigns = allTempDesigns
        ?.concat(allQueuedSubDeletions)
        ?.concat(allSwitch);
      setCheckedDesigns(allDesigns);
    } else {
      setCheckedDesigns([]);
    }
  };

  const toggleCheckDesigns = (id, isCheck) => {
    if (isCheck) {
      if (!checkedDesigns?.includes(id)) {
        setCheckedDesigns([...checkedDesigns, id]);
      }
    } else {
      setCheckedDesigns(checkedDesigns?.filter((item) => item !== id));
    }
  };

  const cancelDesignChangeHandler = async () => {
    setOpenPopupModal(false);
    setShowDesignsLoader(true);
    for (let i = 0; i < checkedDesigns?.length; i++) {
      await cancelDesignChange(checkedDesigns[i]);
    }
    await callTempCardDesignsAPI();
    await callCardDesignsAPI();
    await callDesignCategoriesAPI();
    await callSubDesignDeleteQueueAPI();
    await callDesignSwitchQueueAPI();
    setShowDesignsLoader(false);
  };
  // Card Designs select end

  const renderDesignChanges = () => {
    if (tempDesigns) {
      return tempDesigns?.map((design, index) => {
        let designCategory = cardDesigns?.[0]?.filter(
          (item) => item?.design_category_id === design.category_id
        );
        if (designCategory?.length === 0) {
          designCategory = tempCategories?.filter(
            (item) => item?.design_category_id === design.category_id
          );
        }
        designCategory = designCategory?.[0]?.title;
        let ActionType = '';
        if (design?.action === 'ADD') {
          ActionType = 'New Image Added';
        }
        let submissionAction = 'Submit for Approval';
        if (design.submitted) {
          submissionAction = 'Awaiting Approval';
        }
        if (submissionAction !== 'Awaiting Approval') {
          return (
            <tr key={index}>
              <td>
                <input
                  type='checkbox'
                  id={design?.id}
                  checked={checkedDesigns?.includes(design?.id)}
                  onChange={(e) =>
                    toggleCheckDesigns(design?.id, e.target.checked)
                  }
                />
              </td>
              <td>{design?.id}</td>
              <AIDAImage
                url={design?.url}
                imageCheck={(e) => checkImageResults(e)}
              />
              <td>{checkNull(designCategory)}</td>
              <td>{checkNull(ActionType)}</td>
              <td>{checkNull(submissionAction)}</td>
            </tr>
          );
        } else {
          return null;
        }
      });
    }
  };

  const renderDesignDeletesChanges = () => {
    if (queuedSubDeletions) {
      return queuedSubDeletions?.map((design, index) => {
        let ActionType = '';
        if (design.queued_action === 'REMOVE') {
          ActionType = 'Remove Image';
        }
        return (
          <tr key={index}>
            <td>
              <input
                type='checkbox'
                id={design?.id}
                checked={checkedDesigns?.includes(design?.id)}
                onChange={(e) =>
                  toggleCheckDesigns(design?.id, e.target.checked)
                }
              />
            </td>
            <td>{design?.id}</td>
            <AIDAImage
              url={design?.url}
              imageCheck={(e) => checkImageResults(e)}
            />
            <td>{checkNull(design.design_category)}</td>
            <td>{checkNull(ActionType)}</td>
            <td>{t('table:tb_publish_change_to_live')}</td>
          </tr>
        );
      });
    }
  };

  const renderDesignSwitchChanges = () => {
    if (designSwitchQueue) {
      return designSwitchQueue?.map((design, index) => {
        let ActionType = '';
        if (design?.action === 'SWITCH') {
          ActionType = 'Change Image Category';
        }
        let designCategory = cardDesigns?.[0]?.filter(
          (item) => item?.design_category_id === design.category_id
        );
        if (designCategory?.length === 0) {
          designCategory = tempCategories?.filter(
            (item) => item?.design_category_id === design.category_id
          );
        }
        designCategory = designCategory?.[0]?.title;
        return (
          <tr key={index}>
            <td>
              <input
                type='checkbox'
                id={design?.id}
                checked={checkedDesigns?.includes(design?.id)}
                onChange={(e) =>
                  toggleCheckDesigns(design?.id, e.target.checked)
                }
              />
            </td>
            <td>{design?.id}</td>
            <AIDAImage
              url={design?.url}
              imageCheck={(e) => checkImageResults(e)}
            />
            <td>{checkNull(designCategory)}</td>
            <td>{checkNull(ActionType)}</td>
            <td>{t('table:tb_publish_change_to_live')}</td>
          </tr>
        );
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>{t('cdsgn_card_designs_label')}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllDesigns'
                    checked={
                      checkedDesigns?.length === designsCount &&
                      designsCount > 0
                    }
                    onChange={selectAllDesigns}
                    disabled={designsCount === 0}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_image')}</th>
                <th>{t('table:tb_aida_results')}</th>
                <th>{t('table:tb_design_category')}</th>
                <th>{t('table:tb_change_type')}</th>
                <th>{t('table:tb_submission_action')}</th>
              </tr>
            </thead>
            <tbody>
              {renderDesignChanges()}
              {renderDesignDeletesChanges()}
              {renderDesignSwitchChanges()}
            </tbody>
          </Table>
          {designsCount > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setOpenPopupModal(true)}
                disabled={checkedDesigns?.length === 0 || showDesignsLoader}
                style={{ width: 150 }}
              >
                {t('buttons:btn_cancel')}
                {checkedDesigns?.length > 1 && ' ALL'}
                {showDesignsLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </button>
            </div>
          )}
        </CardBody>
      </Card>
      <ReviewCardDesignsModal
        openPopupModal={openPopupModal}
        checkedDesigns={checkedDesigns}
        cancelDesignChangeHandler={cancelDesignChangeHandler}
        setOpenPopupModal={setOpenPopupModal}
      />
    </Fragment>
  );
};

export default ReviewCardDesigns;
