import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const RemoveCategoryModal = ({
  openPopupModal,
  checkedCarousel,
  deleteCategoriesHandler,
  setOpenPopupModal,
}) => {
  const { t } = useTranslation(['card_designs', 'buttons']);

  return (
    <Modal isOpen={openPopupModal} centered={true}>
      <ModalHeader>{t('cdsgn_carousel_categories_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              {checkedCarousel?.length > 1
                ? t('cdsgn_remove_all_changes')
                : t('cdsgn_remove_changes')}
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => deleteCategoriesHandler()}>
          {t('buttons:btn_yes')}
        </Button>
        <Button color='secondary' onClick={() => setOpenPopupModal(false)}>
          {t('buttons:btn_no')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveCategoryModal;
