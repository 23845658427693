import { Fragment, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table,
} from 'reactstrap';
import deleteBrandConfigQueue from 'utils/deleteBrandConfigQueue';
import CardBrandConfigurationsModal from './Modal/CardBrandConfigurationsModal';
import Loading from 'components/Loading';
import Img from 'react-cool-img';
import { checkNull } from 'utils/validationUtils';
import { useTranslation } from 'react-i18next';

const CardBrandConfigurations = ({
  loading,
  brandConfigurations,
  callBrandConfigQueueAPI,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons', 'table']);
  const [brandCancelModal, setBrandCancelModal] = useState(false);
  const [checkedCardBrand, setCheckedCardBrand] = useState([]);
  const [showBrandLoader, setShowBrandLoader] = useState(false);

  // Brand config select start
  const selectAllBrand = (e) => {
    if (e.target.checked) {
      const allBrand = brandConfigurations?.map((category) => category.id);
      setCheckedCardBrand(allBrand);
    } else {
      setCheckedCardBrand([]);
    }
  };

  const toggleCheckBrand = (id, isCheck) => {
    if (isCheck) {
      if (!checkedCardBrand?.includes(id)) {
        setCheckedCardBrand([...checkedCardBrand, id]);
      }
    } else {
      setCheckedCardBrand(checkedCardBrand?.filter((item) => item !== id));
    }
  };

  const completeBrandCancellation = async () => {
    setBrandCancelModal(false);
    setShowBrandLoader(true);
    for (let i = 0; i < checkedCardBrand?.length; i++) {
      await deleteBrandConfigQueue(checkedCardBrand[i]);
    }
    await callBrandConfigQueueAPI();
    setCheckedCardBrand([]);
    setShowBrandLoader(false);
  };
  // Brand config select end

  const processBrandData = () => {
    if (brandConfigurations) {
      return brandConfigurations?.map((config, index) => {
        if (!config.error) {
          return (
            <tr key={index}>
              <td>
                <input
                  type='checkbox'
                  id={config.id}
                  checked={checkedCardBrand?.includes(config.id)}
                  onChange={(e) =>
                    toggleCheckBrand(config.id, e.target.checked)
                  }
                />
              </td>
              <td>{checkNull(config.id)}</td>
              <td>{checkNull(config.brand_configuration_name)}</td>
              <td>
                <Img
                  src={config?.brand_logo}
                  style={{ height: 70, width: 100 }}
                  alt='Brand logo'
                />
              </td>
              <td>{checkNull(config?.brand_logo_position)}</td>
              <td>
                <img
                  src={config?.brand_network_logo}
                  style={{ height: 70, width: 100 }}
                  alt='Brand network logo'
                />
              </td>
              <td>{checkNull(config?.brand_network)}</td>
              <td>{checkNull(config?.status)}</td>
            </tr>
          );
        } else return null;
      });
    }
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>
            {t('revchn_card_brand_configurations_label')}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <thead className='text-primary'>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    id='selectAllBrand'
                    checked={
                      checkedCardBrand?.length ===
                        brandConfigurations?.length &&
                      brandConfigurations?.length > 0
                    }
                    onChange={selectAllBrand}
                    disabled={brandConfigurations?.length === 0}
                  />
                </th>
                <th>{t('table:tb_id')}</th>
                <th>{t('table:tb_brand_configuration_name')}</th>
                <th>{t('table:tb_brand_logo')}</th>
                <th>{t('table:tb_logo_position')}</th>
                <th>{t('table:tb_network_logo')}</th>
                <th>{t('table:tb_network')}</th>
                <th>{t('table:tb_status')}</th>
              </tr>
            </thead>
            {loading && !brandConfigurations ? (
              <div className='content'>
                <Loading />
              </div>
            ) : (
              <tbody>{processBrandData()}</tbody>
            )}
          </Table>
          {brandConfigurations?.length > 0 && (
            <div className='d-flex flex-row-reverse mr-1'>
              <Button
                type='submit'
                className='btn btn-danger btn-block d-flex justify-content-center'
                onClick={() => setBrandCancelModal(true)}
                disabled={checkedCardBrand?.length === 0 || showBrandLoader}
                style={{ width: 150 }}
              >
                {t('buttons:btn_cancel')}
                {checkedCardBrand?.length > 1 && ' ALL'}
                {showBrandLoader && (
                  <Spinner size='sm' style={{ marginLeft: '8px' }} />
                )}
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      <CardBrandConfigurationsModal
        brandCancelModal={brandCancelModal}
        setBrandCancelModal={setBrandCancelModal}
        completeBrandCancellation={completeBrandCancellation}
        checkedCardBrand={checkedCardBrand}
      />
    </Fragment>
  );
};

export default CardBrandConfigurations;
