function addDesign(url, userSessionKey) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/designs`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      url: url,
      session_key: userSessionKey,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addDesign;
