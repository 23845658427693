import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardTitle } from 'reactstrap';

const ReviewSummary = () => {
  const { t } = useTranslation('review_changes');

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h4'>{t('revchn_review_summary_label')}</CardTitle>
      </CardHeader>
      <p style={{ padding: 15 }}>{t('revchn_review_summary_description')}</p>
    </Card>
  );
};

export default ReviewSummary;
