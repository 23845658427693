import { toast } from 'react-toastify';

export const successToast = (msg) => {
  toast.success(msg, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const pendingToast = (msg) => {
  return toast.loading('Please wait...', {
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
  });
};

export const updateToast = (id, msg) => {
  toast.update(id, {
    render: msg,
    type: 'success',
    isLoading: false,
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
  });
};
