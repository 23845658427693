import React, { useState, useReducer, useRef, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Alert,
  Spinner,
  Button,
  ModalFooter,
} from 'reactstrap';
import getBrandConfigurations from 'utils/getBrandConfigurations';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import FileBase64 from 'react-file-base64';
import uploadImage from 'utils/uploadImage';
import { uuid } from 'utils/getUUID';
import moment from 'moment';
import Img from 'react-cool-img';

const ImageTools = (props) => {
  const {
    t,
    handleSubmit,
    setModal,
    item,
    downloadMode,
    programme_id,
    setSubmitRequest,
    selectedConfig,
    imageDefaults,
    imageProps,
  } = props;

  const componentRef = useRef();
  const [brandConfigurations, setBrandConfigurations] = useState(null);
  const [cardImage] = useState(item?.url);
  const [previewImage, setPreviewImage] = useState();
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [choice, setChoice] = useState(null);
  const [isError, setError] = useState({
    selectedBrandConfig: '',
    contentExpiry: '',
  });
  const [pageState, setPageState] = useReducer(
    (pageState, newPageState) => ({ ...pageState, ...newPageState }),
    {
      loading: false,
      selectedItem: null,
      cardBrand: null,
      logoPosition: 'left',
      brandLogo: null,
      networkLogo: null,
    }
  );
  const [zoom] = useState(null);
  const dynamicHeight = (((window.innerWidth * 0.95 - 90) * 1) / 2) * 0.63;
  const dynamicWidth = (window.innerWidth - 170) / 2;

  useEffect(() => {
    if (downloadMode) {
      downloadImage(item?.design_data);
    }
    getBrandConfigs();
  }, []);

  useEffect(() => {
    if (hasSubmitted) {
      formValidate();
    }
  }, [choice, hasSubmitted]);

  const getBrandConfigs = () => {
    if (!brandConfigurations) {
      getBrandConfigurations(programme_id).then((brandConfigs) => {
        if (!brandConfigs.error) {
          setBrandConfigurations(brandConfigs);
          applyBrandConfig(item?.brand_configuration, brandConfigs);
        }
      });
    }
  };

  const formValidate = () => {
    let flag = true;
    if (choice === null) {
      isError.selectedBrandConfig = t('img_tools:img_tools_select_error');
      flag = false;
    } else {
      isError.selectedBrandConfig = '';
    }
    setError({ ...isError });
    return flag;
  };

  const handleClick = () => {
    setHasSubmitted(true);
    const flag = formValidate();
    if (flag) {
      setModal(false);
      handleSubmit();
    }
  };

  const prepareMetadata = (key, value) => {
    if (setSubmitRequest) {
      setSubmitRequest(key, value);
    }
  };

  const applyBrandConfig = (value, configs) => {
    let selectedBrand = configs?.filter((item) => item?.id === value);
    if (selectedBrand.length > 0) {
      setPageState({
        brandLogo: selectedBrand?.[0]?.brand_logo,
        networkLogo: selectedBrand?.[0]?.brand_network_logo,
      });
      if (selectedBrand?.[0]?.brand_network === 'Mastercard') {
        setPageState({ cardBrand: 'Mastercard' });
      }
      if (selectedBrand?.[0]?.brand_network === 'Visa') {
        setPageState({ cardBrand: 'Visa' });
      }
      if (selectedBrand?.[0]?.brand_logo_position === 'left') {
        setPageState({ logoPosition: 'left' });
      }
      if (selectedBrand?.[0]?.brand_logo_position === 'right') {
        setPageState({ logoPosition: 'right' });
      }
      selectedConfig(selectedBrand[0]);
    }
  };

  const downloadImage = (imageURL) => {
    const fileName = item?.catagory + item?.order_id + '.jpg';
    let a = document.createElement('a');
    a.href = imageURL;
    a.download = fileName;
    a.click();
  };

  const getFiles = async (files) => {
    setShowLoader(true);
    const file = files?.base64;
    const fileExt = '.' + files?.name?.split('.').pop();
    if (fileExt !== '.mp3') {
      setShowError(true);
      setShowLoader(false);
      return null;
    }
    setShowError(false);
    const newUUID = uuid();
    const newFileData = file?.split(',');
    const newImage = await uploadImage(newFileData?.[1], newUUID + fileExt);
    prepareMetadata('associatedSoundURL', newImage?.Location);
    setShowLoader(false);
    setPreviewImage(file);
  };

  const ComponentToRender = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ textAlign: 'center' }} id={'downloadImage'}>
      <TransformWrapper
        initialScale={zoom ? zoom : imageDefaults?.default_zoom}
        initialPositionX={
          imageDefaults?.default_offsetX
            ? imageDefaults?.default_offsetX
            : imageDefaults?.positionX
        }
        initialPositionY={
          imageDefaults?.default_offsetY
            ? imageDefaults?.default_offsetY
            : imageDefaults?.positionY
        }
        onPanningStop={(rzppRef) => {
          if (imageProps) {
            imageProps(rzppRef);
          }
        }}
      >
        <TransformComponent>
          <div
            style={{
              backgroundImage: `url(${cardImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: dynamicWidth,
              height: dynamicHeight,
            }}
            crossOrigin='anonymous'
          />
        </TransformComponent>
        <div
          style={{
            backgroundImage: `url(${pageState?.networkLogo}`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '26.88%',
            height: '26.20%',
            position: 'absolute',
            right: '6.25%',
            bottom: '2.9%',
            objectFit: 'contain',
            backgroundPosition: 'right 0px bottom 0px',
          }}
        />
        <div
          style={{
            backgroundImage: `url(${pageState?.brandLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '29.132%',
            width: '43.25%',
            position: 'absolute',
            left: '6.5%',
            top: '5.882%',
            objectFit: 'contain',
            objectPosition: 'top left',
          }}
        />
      </TransformWrapper>
    </div>
  ));

  return (
    <>
      <div className='content'>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <ComponentToRender
              ref={componentRef}
              imageProps={imageProps}
              imageDefaults={imageDefaults ? imageDefaults : item}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <Card>
              <CardHeader>
                {t('img_tools:img_tools_card_brand_configuration')}
                <span className='text-danger'>*</span>
              </CardHeader>
              <CardBody>
                {brandConfigurations && (
                  <select
                    onChange={(choice) => {
                      if (choice.target.value) {
                        applyBrandConfig(
                          parseInt(choice.target.value),
                          brandConfigurations
                        );
                        setChoice(parseInt(choice.target.value));
                      } else {
                        setChoice(null);
                        selectedConfig(null);
                      }
                    }}
                    className='form-control'
                  >
                    <option value={''}>
                      {t('img_tools:img_tools_please_select')}
                    </option>
                    {brandConfigurations.map((brand, index) => (
                      <option value={brand.id} key={index}>
                        {brand.brand_configuration_name}
                      </option>
                    ))}
                  </select>
                )}
                <p className='text-danger mt-1'>
                  {isError?.selectedBrandConfig}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <Card>
              <CardHeader>Content Expiry</CardHeader>
              <CardBody>
                <Input
                  name='date'
                  placeholder='Content Expiry'
                  type='date'
                  onChange={(event) =>
                    prepareMetadata(
                      'contentExpiry',
                      moment(event.target.value).format('DD/MM/YYYY')
                    )
                  }
                />
                <p className='text-danger mt-1'>{isError?.contentExpiry}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <Card>
              <CardHeader>Has Haptic Response</CardHeader>
              <CardBody>
                <div className='custom-control custom-switch'>
                  <input
                    type='checkbox'
                    className='custom-control-input'
                    id='customSwitches'
                    onChange={(event) =>
                      prepareMetadata(
                        'hasHapticResponse',
                        event.target.value === 'on' ? true : false
                      )
                    }
                  />
                  <label
                    className='custom-control-label'
                    htmlFor='customSwitches'
                  ></label>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <Card>
              <CardHeader>Associated Sound</CardHeader>
              <CardBody>
                {showError && (
                  <Alert color='danger'>Only .mp3 files are allowed.</Alert>
                )}
                <FileBase64
                  multiple={false}
                  accept='.mp3'
                  onDone={getFiles.bind(this)}
                />
                {showLoader && (
                  <Spinner color='primary' size='sm'>
                    Loading...
                  </Spinner>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalFooter className='bg-white'>
          <Button color='success' onClick={() => handleClick()}>
            {t('buttons:btn_save_changes')}
          </Button>
          <Button color='secondary' onClick={() => setModal(false)}>
            {t('buttons:btn_cancel')}
          </Button>
        </ModalFooter>
      </div>
    </>
  );
};

export default ImageTools;
