import Dashboard from "views/Dashboard.jsx"
import Logout from "views/Logout"

var AnalyticsRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  // {
  //   path: "/user-management",
  //   name: "User Management",
  //   icon: "nc-icon nc-single-02",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-button-power",
    component: Logout,
    layout: "/admin"
  },
  // {
  //   pro: true,
  //   path: "/support",
  //   name: "I Need Help!",
  //   icon: "nc-icon nc-support-17",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
];
export default AnalyticsRoutes;