function updateCardProgrammeStatus(programme_id, status_id) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/cardprogrammes`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      programme_id: `${programme_id}`,
      status_id: `${status_id}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateCardProgrammeStatus;
