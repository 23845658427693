function addContentData(
  content_id,
  content_image,
  content_title,
  content_destination,
  item_order
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/content-data`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      content_id: content_id,
      content_image: content_image,
      content_title: content_title,
      content_destination: content_destination,
      item_order: item_order,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addContentData;
