function publishDesignSwitch(category_id, design_identifier) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/design-switch`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      category_id: category_id,
      design_identifier: design_identifier,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default publishDesignSwitch;
