function updateDesignOrder(design_id, order_id) {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/cardprogrammes`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        design_id: `${design_id}`,
        order_id: `${order_id}`,
      },
    }
  )
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default updateDesignOrder;
