// src/App.js

import React, { Fragment, Suspense } from 'react';

// New - import the React Router components, and the Profile page component
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import AdminLayout from 'layouts/Admin.jsx';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';

function App() {
  const { loading, user } = useAuth0();

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Fragment>
        <ToastContainer />
        <BrowserRouter history={history}>
          <Suspense fallback={<Spinner size='sm' />}>
            <Switch>
              <PrivateRoute
                path='/admin'
                render={(props) => (
                  <AdminLayout {...props} userProfile={user} />
                )}
              />
              <Redirect to='/admin/home' />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;
