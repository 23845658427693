function addDesignCategory(
  userSessionKey,
  design_category_code,
  design_category_name,
  design_category_description,
  parent_category,
  action
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/design-categories`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      design_category_code: design_category_code,
      design_category_name: design_category_name,
      design_category_description: design_category_description,
      session_key: userSessionKey,
      parent_category: parent_category,
      action: action,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default addDesignCategory;
