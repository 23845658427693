function getCardDesignInfo(client_access_key, bearerToken) {
    return fetch('https://data.yimbatech.com/card-designs', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearerToken,
            'x-client-access-token': `${client_access_key}`,
        },
    })
        .then((response) => response.json())
        .then((responseJSON) => {
            return responseJSON;
        })
}

export default getCardDesignInfo;