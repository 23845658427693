function fetchClientKey(user_name) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      user_name: `${user_name}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default fetchClientKey;
