function getAvailableBins(client_access_key) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/available-bins`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'client_access_key': client_access_key,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default getAvailableBins;
