function publishDesign(
  category_id,
  design_identifier,
  url,
  default_offset,
  default_zoom,
  brand_config,
  design_data
) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/design-changes`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      category_id: `${category_id}`,
      design_identifier: `${design_identifier}`,
      url: `${url}`,
      default_offset: `${default_offset}`,
      default_zoom: `${default_zoom}`,
      brand_config: `${brand_config}`,
      design_data: `${design_data}`,
    },
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      return responseJSON;
    });
}

export default publishDesign;
