import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

const PublishingCompleteModal = ({ publishingComplete, publishComplete }) => {
  const { t } = useTranslation(['review_changes', 'buttons']);

  return (
    <Modal isOpen={publishingComplete} centered={true}>
      <ModalHeader>{t('revchn_publishing_complete_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{t('revchn_publishing_all_chages_label')}</Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='success' onClick={() => publishComplete()}>
          {t('buttons:btn_ok')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PublishingCompleteModal;
