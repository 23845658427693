import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const AddCategoryModal = ({ modalShow, setModalShow, addCategory }) => {
  const { t } = useTranslation(['card_designs', 'buttons', 'validation']);

  const [categoryName, setCategoryName] = useState(null);
  const [categoryDescription, setCategoryDescription] = useState(null);

  const handleSubmit = () => {
    addCategory(categoryName, categoryDescription);
  };

  return (
    <Modal isOpen={modalShow} centered={true}>
      <AvForm onValidSubmit={handleSubmit}>
        <ModalHeader>{t('cdsgn_add_carousel_category_label')}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='CategoryName'>
              {t('cdsgn_category_name_label')}
              <span className='text-danger'>*</span>
            </Label>
            <AvField
              type='text'
              name='categoryName'
              id='categoryName'
              validate={{
                required: {
                  value: true,
                  errorMessage: t('validation:vld_please_enter_a_value_field'),
                },
              }}
              required
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for='CategoryDescription'>
              {t('cdsgn_category_description_label')}
              <span className='text-danger'>*</span>
            </Label>
            <AvField
              type='text'
              name='categoryDescription'
              id='categoryDescription'
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: t('validation:vld_please_enter_a_value_field'),
                },
              }}
              onChange={(e) => setCategoryDescription(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type='submit' color='primary'>
            {t('buttons:btn_add_category')}
          </Button>
          <Button color='secondary' onClick={() => setModalShow(false)}>
            {t('buttons:btn_cancel')}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default AddCategoryModal;
