import Loading from 'components/Loading';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';

const PublishCategoryChangesModal = ({
  publishCategoryChangesModal,
  publishingInProgress,
  publishDataHandler,
  setPublishCategoryChangesModal,
}) => {
  const { t } = useTranslation(['review_changes', 'buttons']);

  return (
    <Modal isOpen={publishCategoryChangesModal} centered={true}>
      <ModalHeader>{t('revchn_publish_changes_label')}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{t('revchn_publish_changes_description')}</Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='danger'
          onClick={() => publishDataHandler()}
          disabled={publishingInProgress}
        >
          {t('buttons:btn_yes')}
          {publishingInProgress && (
            <Spinner size='sm' style={{ marginLeft: '8px' }} />
          )}
        </Button>
        <Button
          color='success'
          onClick={() => setPublishCategoryChangesModal(false)}
          disabled={publishingInProgress}
        >
          {t('buttons:btn_no')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PublishCategoryChangesModal;
