import React, { Fragment, useState, memo } from 'react';
import { Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import addDesignCategory from '../../utils/addDesignCategory';
import addSubDesignAction from '../../utils/addSubDesignAction';
import updateDesignOrder from '../../utils/updateDesignOrder';
import designSwitch from '../../utils/designSwitch';
import Img from 'react-cool-img';
import updateCategoryOrder from '../../utils/updateCategoryOrder';
import updateImagePropsQueue from 'utils/updateImagePropsQueue';
import updateLiveBrandConfig from 'utils/updateLiveBrandConfig';
import deleteTempDesign from 'utils/deleteTempDesign';
import createCompositeImage from 'utils/createCompositeImage';
import createZipFile from 'utils/getZippedImages';
import saveCardDesign from 'utils/saveCardDesign';
import getSecurityToken from 'utils/getSecurityToken';
import RemoveItemModal from './Modal/RemoveItemModal';
import PromoteModal from './Modal/PromoteModal';
import SwitchCategoryModal from './Modal/SwitchCategoryModal';
import SetDesignPriceModal from './Modal/SetDesignPriceModal';
import CardBrandingModal from './Modal/CardBrandingModal';
import { useTranslation } from 'react-i18next';
import { pendingToast } from 'components/Toast/SuccessToast';
import { updateToast } from 'components/Toast/SuccessToast';

// a little function to help us with reordering the result
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 0.5,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getCategoryListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  overflow: 'auto',
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const NewDND = ({
  parentData,
  sessionKey,
  designQueue,
  newMainData,
  cardProgramme,
  tempCategories,
  designSwitchQueue,
  loadData,
}) => {
  const { t } = useTranslation('new_dnd', 'buttons');
  const data = newMainData;
  // Modals
  const [removeItemModal, setRemoveItemModal] = useState(false);
  const [promoteModal, setPromoteModal] = useState(false);
  const [switchModal, setSwitchModal] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [seed, setSeed] = useState(1);

  // Loaders
  const [showRemoveItemLoader, setShowRemoveItemLoader] = useState(false);

  const [item, setItem] = useState(null);
  const [promotedCategory, setPromotedCategory] = useState('');
  const [promotedSelected, setPromotedSelected] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [cardBrandingModal, setCardBrandingModal] = useState(false);
  const [globalBrandMode, setGlobalBrandMode] = useState(false);
  const [selectedBrandConfig, setSelectedBrandConfig] = useState(null);
  const [downloadMode, setDownloadMode] = useState(false);
  const [imageProps, setImageProps] = useState(null);
  const [categoryBrandMode, setCategoryBrandMode] = useState(false);
  const [imageTools, setImageTools] = useState({
    contentExpiry: null,
    hasHapticResponse: false,
    associatedSoundURL: null,
  });

  // Grid records start
  const checkQueuedSubDeletions = () => {
    if (designQueue) {
      designQueue?.forEach((action) => {
        data?.forEach((item, index) => {
          item.designs?.forEach((design, idx) => {
            if (design.design_identifier === action.design_identifier) {
              data[index].designs[idx].hideMe = true;
            }
          });
        });
      });
    }
  };

  const checkQueuedDesignSwitches = () => {
    if (designSwitchQueue) {
      designSwitchQueue?.forEach((action) => {
        data?.forEach((item, index) => {
          item.designs?.forEach((design, idx) => {
            if (design.design_identifier === action.design_identifier) {
              // delete data[index].designs[idx];
              data[index].designs[idx].hideMe = true;
            }
          });
        });
      });
    }
  };
  // Grid records end

  // Download Images
  const downloadCarousel = (carouselDesigns) => {
    const urls = [];
    const fileNames = [];
    carouselDesigns?.forEach((design, idx) => {
      if (design.design_data) {
        urls?.push(design.design_data);
        fileNames?.push(design.catagory + design.order_id + '.jpg');
      } else {
        urls?.push(design.url);
        fileNames?.push(design.catagory + design.order_id + '.jpg');
      }
    });
    const bodyRequest = {
      body: {
        urls: urls,
        fileNames: fileNames,
      },
    };
    createZipFile(bodyRequest).then((res) => {
      const fileStream = JSON.parse(res);
      let a = document.createElement('a');
      a.href = fileStream.fileStream;
      a.download = 'images.zip';
      a.click();
    });
  };

  // Remove item
  const removeItem = async () => {
    setShowRemoveItemLoader(true);
    for (let index = 0; index < data?.length; index++) {
      const record = data[index];
      for (let idx = 0; idx < record?.designs?.length; idx++) {
        const design = record?.designs?.[idx];
        if (design?.design_identifier === item) {
          delete data[index].designs[idx];
          setSeed(Math.random());
          if (!design.mergedDesign) {
            await addSubDesignAction(
              item,
              design.url,
              sessionKey,
              record.title
            );
          } else {
            await deleteTempDesign(design.id);
          }
        }
      }
    }
    setShowRemoveItemLoader(false);
    setRemoveItemModal(false);
    setTimeout(() => {
      loadData(false);
    }, [800]);
  };

  // On Drag end
  const onDragEnd = (result) => {
    let updatedCategory = null;
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    data?.forEach((parent) => {
      parent.designs?.forEach((design) => {
        if (design.design_identifier === result.draggableId) {
          updatedCategory = design.catagory;
          if (design.display_order > result.destination.index) {
            design.display_order = result.destination.index - 1;
          } else {
            design.display_order = result.destination.index + 1;
          }
        }
      });
      parent.designs?.sort((a, b) => a.display_order - b.display_order);
      parent.designs?.map(async (design, index) => {
        design.display_order = index;
        if (design.catagory === updatedCategory) {
          updateDesignOrder(design.design_identifier, index).then((res) => {});
        }
        return null;
      });
    });
  };

  const onCategoryDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    newMainData?.forEach((cat) => {
      if (cat?.code === result?.draggableId) {
        if (cat.display_order > result.destination.index) {
          cat.display_order = result.destination.index - 0.05;
        } else {
          cat.display_order = result.destination.index + 0.05;
        }
      }
    });
    newMainData?.sort((a, b) => a?.display_order - b?.display_order);
    newMainData
      ?.filter((it) => !it?.isTemp)
      ?.forEach(async (cat, index) => {
        cat.display_order = index;
        await updateCategoryOrder(
          cat?.design_category_id,
          index,
          cat?.programme_id
        );
      });
  };

  // Open delete modal
  const showRemoveDialog = (item) => {
    setRemoveItemModal(true);
    setItem(item);
  };

  const promoteCarousel = async () => {
    let parentCategoryID = parentData?.filter(
      (fItem) => fItem.title === promotedCategory
    );
    await addDesignCategory(
      sessionKey,
      promotedSelected.code,
      promotedSelected.title,
      promotedSelected.description,
      parentCategoryID[0].design_category_id,
      'UPDATE'
    );
    setPromoteModal(false);
    loadData(false);
  };

  // Switch card design category
  const switchDesignCategory = async () => {
    setSwitchModal(false);
    const id = pendingToast();
    let parentCategoryID = data?.filter(
      (fItem) => fItem.code === promotedCategory
    );
    await designSwitch(
      promotedSelected.design_identifier,
      promotedSelected.url,
      sessionKey,
      parentCategoryID[0].design_category_id
    );
    updateToast(id, t('newdnd_submitted_for_approval'));
    loadData(false);
  };

  // Move category up or down
  const changeCategoryDisplayOrder = async (item, direction) => {
    const id = pendingToast();
    data?.sort(function (a, b) {
      return a.display_order - b.display_order;
    });
    data?.forEach((category, index) => {
      category.display_order = index;
    });

    for (let index = 0; index < data?.length; index++) {
      const category = data[index];
      if (category?.code === item.code) {
        if (direction === 'up') {
          await updateCategoryOrder(
            category?.design_category_id,
            category?.display_order - 1,
            category?.programme_id
          );
          let nextItem = data?.filter(
            (item) => item.display_order === category?.display_order - 1
          );
          if (nextItem.length > 0) {
            await updateCategoryOrder(
              nextItem[0].design_category_id,
              nextItem[0].display_order + 1,
              nextItem[0].programme_id
            );
            loadData(false);
          } else {
            loadData(false);
          }
        } else {
          await updateCategoryOrder(
            category?.design_category_id,
            category?.display_order + 1,
            category?.programme_id
          );
          let nextItem = data?.filter(
            (item) => item.display_order === category?.display_order + 1
          );
          if (nextItem.length > 0) {
            await updateCategoryOrder(
              nextItem[0].design_category_id,
              nextItem[0].display_order - 1,
              nextItem[0].programme_id
            );
            loadData(false);
          } else {
            loadData(false);
          }
        }
      }
    }
    updateToast(id, t('newdnd_operation_completed'));
  };

  // Edit Brand config
  const createImageComposite = async (item) => {
    const brandConfig = selectedBrandConfig;
    let categoryName = item.catagory;
    if (!categoryName) {
      const category = tempCategories?.filter(
        (it) => it?.id === item?.category_id
      )?.[0];
      if (category) {
        categoryName = category?.code;
      }
    }
    await createCompositeImage(
      item.id,
      item.url,
      brandConfig.brand_logo,
      brandConfig.brand_network_logo,
      categoryName,
      item.order_id,
      cardProgramme,
      item.design_queue
    );
  };

  const saveImageEdits = async () => {
    const id = pendingToast();
    if (imageTools) {
      const token = await getSecurityToken(
        'analytics-admin@yimba.com',
        'Sp3ctrum15Gr33n'
      );
      await saveCardDesign(
        {
          ...imageTools,
          design_identifier: selectedDesign.design_identifier,
        },
        token
      );
    }
    const promiseAll = [];
    if (selectedDesign.mergedDesign) {
      //Design Queue Item
      if (categoryBrandMode) {
        var thisItem = tempCategories?.filter(
          (data) => data.id === selectedDesign.category_id
        )?.[0];
        if (thisItem) {
          for (let idx = 0; idx < thisItem?.designs?.length; idx++) {
            const design = thisItem?.designs?.[idx];
            const promise1 = updateImagePropsQueue(
              design.id,
              imageProps
                ? imageProps.positionX + ',' + imageProps.positionY
                : '0,0',
              imageProps ? imageProps.scale : '1',
              selectedBrandConfig.id
            ).then((res) => ({ res: res, promise: 'promise1' }));
            promiseAll.push(promise1);

            design.design_queue = true;
            const promise2 = createImageComposite(design).then((res) => ({
              res: res,
              promise: 'promise2',
            }));
            promiseAll.push(promise2);
          }
        }
      } else {
        if (imageProps) {
          const promise1 = updateImagePropsQueue(
            selectedDesign.id,
            imageProps.positionX + ',' + imageProps.positionY,
            imageProps.scale,
            selectedBrandConfig.id
          ).then((res) => ({ res: res, promise: 'promise1' }));
          promiseAll.push(promise1);

          selectedDesign.design_queue = true;
          const promise2 = createImageComposite(selectedDesign).then((res) => ({
            res: res,
            promise: 'promise2',
          }));
          promiseAll.push(promise2);
        } else {
          const promise1 = updateImagePropsQueue(
            selectedDesign.id,
            '0,0',
            '1',
            selectedBrandConfig.id
          ).then((res) => ({ res: res, promise: 'promise1' }));
          promiseAll.push(promise1);

          selectedDesign.design_queue = true;
          const promise2 = createImageComposite(selectedDesign).then((res) => ({
            res: res,
            promise: 'promise2',
          }));
          promiseAll.push(promise2);
        }
      }
      setCardBrandingModal(false);
      setCategoryBrandMode(false);
      setGlobalBrandMode(false);
    } else {
      if (globalBrandMode || categoryBrandMode) {
        if (categoryBrandMode) {
          let thisItem = data?.filter(
            (data) => data.code === selectedDesign.catagory
          );
          for (let idx = 0; idx < thisItem[0].designs?.length; idx++) {
            const design = thisItem[0].designs?.[idx];
            if (selectedBrandConfig) {
              design.brand_configuration = selectedBrandConfig.id;
              const promise1 = updateLiveBrandConfig(
                design.design_identifier,
                selectedBrandConfig.id
              ).then((res) => ({ res: res, promise: 'promise1' }));
              promiseAll.push(promise1);

              selectedDesign.design_queue = false;
              const promise2 = createImageComposite(design).then((res) => ({
                res: res,
                promise: 'promise2',
              }));
              promiseAll.push(promise2);
            }
          }
          setCardBrandingModal(false);
          setCategoryBrandMode(false);
          setGlobalBrandMode(false);
        }
      } else {
        var currentItem = data?.filter(
          (data) => data.code === selectedDesign.catagory
        );
        currentItem = currentItem[0].designs;
        currentItem = currentItem?.filter(
          (data) => data.design_identifier === selectedDesign.design_identifier
        );
        currentItem[0].brand_configuration = selectedBrandConfig.id;
        if (selectedBrandConfig) {
          const promise1 = updateLiveBrandConfig(
            selectedDesign.design_identifier,
            selectedBrandConfig.id
          ).then((res) => ({ res: res, promise: 'promise1' }));
          promiseAll.push(promise1);

          selectedDesign.design_queue = false;
          const promise2 = createImageComposite(selectedDesign).then((res) => ({
            res: res,
            promise: 'promise2',
          }));
          promiseAll.push(promise2);
        }
        setCardBrandingModal(false);
        setCategoryBrandMode(false);
        setGlobalBrandMode(false);
      }
    }
    await Promise.all(promiseAll);
    updateToast(id, t('newdnd_operation_completed'));
    loadData(false);
  };

  const handleImageProps = (imageProps) => {
    let transformProps = imageProps?.instance.transformState;
    setImageProps(transformProps);
  };

  const setBrandConfig = (brandConfig) => {
    setSelectedBrandConfig(brandConfig);
  };

  const setSubmitRequest = (key, value) => {
    setImageTools({ ...imageTools, [key]: value });
  };

  // Filtered Data
  const newParentData = parentData?.filter(
    (item) => item.programme_id === cardProgramme
  );

  newParentData?.sort(function (a, b) {
    return a.display_order - b.display_order;
  });

  const CategoryButtons = ({ category, idx }) => {
    const nontempCategories = newMainData?.filter((it) => !it?.isTemp) ?? 0;
    return (
      <div>
        <strong>
          {category?.title}{' '}
          {category?.isTemp && `(${t('newdnd_not_submitted')})`}
        </strong>
        <div className='d-flex flex-row'>
          {/* <div className='mr-3'>
                              <Button
                                type='submit'
                                className='btn btn-primary'
                                onClick={() => {
                                  setPromoteModal(true);
                                  setPromotedSelected(category);
                                }}
                              >
                                {t('buttons:btn_promote_this_content')}
                              </Button>
                            </div> */}
          {idx !== 0 && !category?.isTemp && (
            <div className='mr-3'>
              <Button
                type='submit'
                className='btn btn-primary btn-block'
                onClick={() => changeCategoryDisplayOrder(category, 'up')}
              >
                {t('buttons:btn_promote_move_up')}
              </Button>
            </div>
          )}
          {idx !== nontempCategories?.length - 1 && !category?.isTemp && (
            <div className='mr-3'>
              <Button
                type='submit'
                className='btn btn-primary btn-block'
                onClick={() => changeCategoryDisplayOrder(category, 'down')}
              >
                {t('buttons:btn_promote_move_down')}
              </Button>
            </div>
          )}
          <div className='mr-3'>
            <Button
              type='submit'
              className='btn btn-primary btn-block'
              onClick={() => {
                setGlobalBrandMode(false);
                setCategoryBrandMode(true);
                setSelectedDesign(category?.designs[0]);
                setCardBrandingModal(true);
                setDownloadMode(false);
                setImageTools({
                  contentExpiry: null,
                  hasHapticResponse: false,
                  associatedSoundURL: null,
                });
                setSelectedBrandConfig(null);
              }}
            >
              {t('buttons:btn_card_branding')}
            </Button>
          </div>
          <div className='mr-3'>
            <Button
              type='submit'
              className='btn btn-primary btn-block'
              onClick={() => downloadCarousel(category?.designs)}
            >
              {t('buttons:btn_download_images')}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div>
        <RemoveItemModal
          removeItemModal={removeItemModal}
          setRemoveItemModal={setRemoveItemModal}
          removeItem={removeItem}
          showRemoveItemLoader={showRemoveItemLoader}
        />

        <PromoteModal
          promoteModal={promoteModal}
          setPromoteModal={setPromoteModal}
          newParentData={newParentData}
          setPromotedCategory={setPromotedCategory}
          promoteCarousel={promoteCarousel}
        />

        <SwitchCategoryModal
          switchModal={switchModal}
          setSwitchModal={setSwitchModal}
          newMainData={newMainData}
          promotedCategory={promotedCategory}
          setPromotedCategory={setPromotedCategory}
          promotedSelected={promotedSelected}
          switchDesignCategory={switchDesignCategory}
          tempCategories={tempCategories}
        />

        <SetDesignPriceModal
          priceModal={priceModal}
          setPriceModal={setPriceModal}
          selectedDesign={selectedDesign}
        />

        <CardBrandingModal
          cardBrandingModal={cardBrandingModal}
          setCardBrandingModal={setCardBrandingModal}
          handleSubmit={saveImageEdits}
          selectedDesign={selectedDesign}
          globalBrandMode={globalBrandMode}
          setBrandConfig={setBrandConfig}
          downloadMode={downloadMode}
          selectedProgramme={cardProgramme}
          handleImageProps={handleImageProps}
          imageProps={imageProps}
          setSubmitRequest={setSubmitRequest}
          imageTools={imageTools}
          selectedBrandConfig={selectedBrandConfig}
        />
      </div>
      {checkQueuedSubDeletions()}
      {checkQueuedDesignSwitches()}

      <DragDropContext onDragEnd={onCategoryDragEnd}>
        <Droppable droppableId='catDroppable' direction='vertical' key={1}>
          {(catProvided, snapshot) => (
            <div
              ref={catProvided.innerRef}
              style={getCategoryListStyle(snapshot.isDraggingOver)}
              {...catProvided.droppableProps}
            >
              {newMainData?.map((category, idx) => {
                if (category?.designs && !category?.isTemp) {
                  category?.designs?.sort(function (a, b) {
                    return a.display_order - b.display_order;
                  });
                  return (
                    <Draggable
                      key={category?.code}
                      draggableId={category?.code}
                      index={idx}
                    >
                      {(catProvided, snapshot) => (
                        <div
                          ref={catProvided.innerRef}
                          {...catProvided.draggableProps}
                          {...catProvided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            catProvided.draggableProps.style
                          )}
                          className='mt-2'
                          key={category?.code}
                        >
                          <CategoryButtons category={category} idx={idx} />
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                              droppableId='droppable'
                              direction='horizontal'
                              key={category?.id}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                  {...provided.droppableProps}
                                >
                                  {category?.designs?.map((item, index) => {
                                    if (!item.hideMe) {
                                      return (
                                        <Draggable
                                          key={item.design_identifier}
                                          draggableId={item.design_identifier}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              <div className='d-flex flex-column'>
                                                <Img
                                                  src={`${
                                                    item.design_data
                                                      ? item.design_data
                                                      : item.url
                                                  }?t=${Date.now()}`}
                                                  cache={true}
                                                  style={{
                                                    height: 95,
                                                    width: 150,
                                                  }}
                                                />
                                                <div className='d-flex flex-column'>
                                                  <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                        borderColor: '#ffffff',
                                                      }}
                                                      onClick={() => {
                                                        setCardBrandingModal(
                                                          true
                                                        );
                                                        setSelectedDesign(item);
                                                        setDownloadMode(true);
                                                      }}
                                                      title={t(
                                                        'newdnd_download_image'
                                                      )}
                                                    >
                                                      <i className='fa fa-download text-info' />
                                                    </Button>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                      }}
                                                      onClick={() => {
                                                        setCategoryBrandMode(
                                                          false
                                                        );
                                                        setCardBrandingModal(
                                                          true
                                                        );
                                                        setSelectedDesign(item);
                                                        setDownloadMode(false);
                                                        setImageTools({
                                                          contentExpiry: null,
                                                          hasHapticResponse: false,
                                                          associatedSoundURL:
                                                            null,
                                                        });
                                                        setSelectedBrandConfig(
                                                          null
                                                        );
                                                      }}
                                                      title={t(
                                                        'newdnd_change_card_branding_overlays'
                                                      )}
                                                    >
                                                      <i className='fa fa-edit text-warning' />
                                                    </Button>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                      }}
                                                      onClick={() => {
                                                        setPriceModal(true);
                                                        setSelectedDesign(item);
                                                      }}
                                                      title={t(
                                                        'newdnd_set_image_price'
                                                      )}
                                                    >
                                                      <i className='fa fa-pound-sign text-primary' />
                                                    </Button>
                                                  </div>
                                                  <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                      }}
                                                      onClick={() => {
                                                        setSwitchModal(true);
                                                        setPromotedSelected(
                                                          item
                                                        );
                                                      }}
                                                      title={t(
                                                        'newdnd_switch_image_category'
                                                      )}
                                                    >
                                                      <i className='fa fa-tags text-success' />
                                                    </Button>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                      }}
                                                      disabled
                                                    >
                                                      <i className='fa fa-trash' />
                                                    </Button>
                                                    <Button
                                                      type='submit'
                                                      className='btn btn-block btn-sm'
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffff',
                                                      }}
                                                      onClick={() =>
                                                        showRemoveDialog(
                                                          item.design_identifier
                                                        )
                                                      }
                                                      title={t(
                                                        'newdnd_delete_image'
                                                      )}
                                                    >
                                                      <i className='fa fa-trash text-danger' />
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    </Draggable>
                  );
                } else {
                  return null;
                }
              })}
              {catProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {newMainData?.map((category, idx) => {
        if (category?.designs && category?.isTemp) {
          category?.designs?.sort(function (a, b) {
            return a.display_order - b.display_order;
          });
          return (
            <div className='mt-2' key={category?.code}>
              <CategoryButtons category={category} idx={idx} />
              <div className='d-flex flex-row'>
                {category?.designs?.map((item, index) => {
                  if (!item.hideMe) {
                    return (
                      <div className='d-flex flex-row pr-2'>
                        <div className='p-2 shadow'>
                          <div className='d-flex flex-column'>
                            <Img
                              src={`${
                                item.design_data ? item.design_data : item.url
                              }?t=${Date.now()}`}
                              cache={true}
                              style={{
                                height: 95,
                                width: 150,
                              }}
                            />
                            <div className='d-flex flex-column'>
                              <div className='d-flex flex-row justify-content-between align-items-center'>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                    borderColor: '#ffffff',
                                  }}
                                  onClick={() => {
                                    setCardBrandingModal(true);
                                    setSelectedDesign(item);
                                    setDownloadMode(true);
                                  }}
                                  title={t('newdnd_download_image')}
                                >
                                  <i className='fa fa-download text-info' />
                                </Button>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                  }}
                                  onClick={() => {
                                    setCategoryBrandMode(false);
                                    setCardBrandingModal(true);
                                    setSelectedDesign(item);
                                    setDownloadMode(false);
                                    setImageTools({
                                      contentExpiry: null,
                                      hasHapticResponse: false,
                                      associatedSoundURL: null,
                                    });
                                    setSelectedBrandConfig(null);
                                  }}
                                  title={t(
                                    'newdnd_change_card_branding_overlays'
                                  )}
                                >
                                  <i className='fa fa-edit text-warning' />
                                </Button>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                  }}
                                  onClick={() => {
                                    setPriceModal(true);
                                    setSelectedDesign(item);
                                  }}
                                  title={t('newdnd_set_image_price')}
                                >
                                  <i className='fa fa-pound-sign text-primary' />
                                </Button>
                              </div>
                              <div className='d-flex flex-row justify-content-between align-items-center'>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                  }}
                                  onClick={() => {
                                    setSwitchModal(true);
                                    setPromotedSelected(item);
                                  }}
                                  title={t('newdnd_switch_image_category')}
                                >
                                  <i className='fa fa-tags text-success' />
                                </Button>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                  }}
                                  disabled
                                >
                                  <i className='fa fa-trash' />
                                </Button>
                                <Button
                                  type='submit'
                                  className='btn btn-block btn-sm'
                                  style={{
                                    backgroundColor: '#ffffff',
                                  }}
                                  onClick={() =>
                                    showRemoveDialog(item.design_identifier)
                                  }
                                  title={t('newdnd_delete_image')}
                                >
                                  <i className='fa fa-trash text-danger' />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </Fragment>
  );
};

export default memo(NewDND);
